import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import CustomMenu from './Menu';
import { theme } from './themes';

const CustomSidebar = (props) => {
  return (
    <div
      style={{
        height: '100vh',
        paddingTop: 48,
        position: 'relative',
      }}
    >
      <Sidebar
        {...props}
        sx={{
          '& .RaSidebar-fixed': { position: 'unset', overflowX: 'unset' },
          '& .MuiDrawer-paper': { overflow: 'unset' },
        }}
      />
    </div>
  );
};

const AppLayout = (props) => {
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={CustomMenu}
      theme={theme}
      sx={{
        '& .RaLayout-appFrame': { marginTop: 0 },
        '& .RaLayout-content': {
          paddingTop: '1rem',
          borderLeft: '1px solid #E5E5E5',
        },
        '& .RaSidebar-appBarCollapsed': {
          mt: 0,
        },
      }}
    />
  );
};

export default AppLayout;
