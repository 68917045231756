// library modules
import { useTranslate, useRecordContext } from 'react-admin';

const CategoryField = ({ field }) => {
  const record = useRecordContext();
  const translate = useTranslate();

  const copy = [...record?.categoryList];
  const hiddenCategories = copy.slice(1);

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div
          style={{
            fontSize: '0.813rem',
            backgroundColor: '#F8F9F8',
            color: '#22997B',
            display: 'inline-block',
            padding: '4px 16px',
            borderRadius: '20px',
            margin: '0 8px 1px 0',
          }}
        >
          {record.categoryList[0]}
        </div>
        {hiddenCategories.length > 0 && (
          <div style={{ position: 'relative' }}>
            <div
              className='categories-bundled'
              style={{
                backgroundColor: '#F8F9F8',
                color: '#22997B',
                display: 'inline-block',
                padding: '4px 16px',
                borderRadius: '20px',
                cursor: 'pointer',
                fontSize: '0.813rem',
              }}
            >
              {translate(`common.table.see`)} {record.categoryList?.length - 1}{' '}
              {translate(`common.table.more`)}
            </div>
            <div
              className='category-list-hidden'
              style={{
                fontSize: '0.813rem',
                position: 'absolute',
                top: '30px',
                zIndex: 9999,
                right: 0,
                backgroundColor: '#fff',
                color: '#22997B',
                padding: '10px 20px 5px 20px',
                width: 'max-content',
                boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)`,
              }}
            >
              {hiddenCategories?.length > 0 &&
                hiddenCategories.map((cat, index) => {
                  return (
                    <div style={{ marginBottom: 5 }} key={index + cat}>
                      {cat}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoryField;
