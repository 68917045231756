import { createTheme } from '@mui/material/styles';

const typography = {
  // Use the system font instead of the default Roboto font.
  fontFamily: [
    'Roboto',
    '-apple-system',
    '"Segoe UI"',
    'Arial',
    'sans-serif',
  ].join(','),
};

const palette = {
  background1: {
    main: '#FAFAFA',
  },
  background2: {
    main: '#FFFFFF',
  },
  primary: {
    main: '#2A9D8F',
    light: '#E7FFF8',
  },
  secondary: {
    main: '#E9C46A',
  },
  error: {
    main: '#E74C3C',
  },
  tetriary: {
    main: '#264653',
  },
  success: {
    main: '#06D6A0',
  },
  neutral: {
    main: '#FFBB12',
    mediumLight: '#00000099',
    light: '#828282',
  },
  menu: {
    main: '#757575',
  },
};

export const theme = createTheme({
  typography,
  palette: palette,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '6rem',
            lineHeight: '7.25rem',
            letterSpacing: '0.094rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '3.75rem',
            lineHeight: '4.563rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '3rem',
            lineHeight: '3.625rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '2.125rem',
            lineHeight: '2.5635rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1.5rem !important',
            lineHeight: '1.813rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'h7' },
          style: {
            fontStyle: 'bold',
            fontWeight: 600,
            fontSize: '1rem',
            lineHeight: '1.25rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'section-label' },
          style: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: palette.tetriary.main,
          },
        },
        {
          props: { variant: 'tab-router-label' },
          style: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            color: palette.neutral.mediumLight,
          },
        },
        {
          props: { variant: 'sm' },
          style: { fontSize: '0.875rem' },
        },
      ],
      defaultProps: {
        variantMapping: {
          body1: 'span',
          body2: 'span',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: 'transparent',
            color: palette.primary.main,
            borderRadius: 21,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            border: `1px solid ${palette.primary.main}`,
            minWidth: 163,
            height: 40,
            '&:hover': {
              backgroundColor: 'transparent',
              opacity: 0.8,
            },
          },
        },
        {
          props: { variant: 'primary-float-right' },
          style: {
            backgroundColor: '#fff',
            color: palette.primary.main,
            borderRadius: 21,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            border: `1px solid ${palette.primary.main}`,
            minWidth: 163,
            height: 40,
            position: 'absolute',
            top: 0,
            right: 0,
            '&:hover': {
              backgroundColor: '#fff',
            },
          },
        },
        {
          props: { variant: 'inside-input' },
          style: {
            backgroundColor: '#fff',
            color: palette.primary.main,
            borderRadius: 21,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            border: `1px solid ${palette.primary.main}`,
            minWidth: 163,
            height: 40,
            position: 'absolute',
            top: 7,
            right: 16,
            '&:hover': {
              backgroundColor: '#fff',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            backgroundColor: palette.primary.main,
            color: '#fff',
            borderRadius: 21,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            border: `1px solid ${palette.primary.main}`,
            minWidth: 200,
            height: 40,
            '&:hover': {
              backgroundColor: palette.primary.main,
              opacity: 0.8,
            },
          },
        },
        {
          props: { variant: 'secondary-light' },
          style: {
            backgroundColor: palette.primary.light,
            color: palette.primary.main,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            border: `none`,
            borderRadius: 21,
            minWidth: 163,
            height: 40,
            '&:hover': {
              backgroundColor: palette.primary.main,
              color: palette.primary.light,
              opacity: 0.8,
            },
          },
        },
        {
          props: { variant: 'error' },
          style: {
            backgroundColor: palette.error.main,
            color: '#fff',
            borderRadius: 21,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            border: `1px solid ${palette.error.main}`,
            minWidth: 163,
            height: 40,
            '&:hover': {
              backgroundColor: palette.error.main,
              opacity: 0.8,
            },
          },
        },
        {
          props: { variant: 'ghost' },
          style: {
            backgroundColor: 'transparent',
            color: palette.primary.main,
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'none',
            textDecoration: 'underline',
            border: `none`,
            minWidth: 163,
            height: 40,
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              opacity: 0.8,
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            backgroundColor: 'transparent',
            color: palette.tetriary.main,
            fontSize: '1rem',
            fontWeight: 400,
            textTransform: 'none',
            border: `none`,
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              opacity: 0.8,
            },
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          padding: '0 !important',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxSizing: 'border-box',
          // width: '720px',
          padding: '2.5rem 3.5rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
        rounded: {
          borderRadius: 'unset',
        },
      },
    },
    RaSaveButton: {
      styleOverrides: {
        root: {
          borderRadius: 21,
          fontSize: '0.875rem',
          fontWeight: 500,
          textTransform: 'none',
          width: 163,
          height: 40,
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderRadius: '5px !important',
          marginLeft: 10,
          marginRight: 10,
          fontSize: 14,
          fontWeight: 500,
          '&:hover': {
            opacity: 0.7,
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          paddingRight: 0,
          '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: '#1976D20A',
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '.RaLayout-appFrame': { marginTop: '1.25rem' },
          '.RaLayout-content': { padding: 0 },
        },
      },
    },
    RaLocalesMenuButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          width: '100%',
          padding: '6px 14px',
          margin: '0 10px',
          display: 'flex',
          '& .MuiButtonBase-root': {
            color: palette.tetriary.main,
            backgroundColor: 'transparent',
            textTransform: 'capitalize',
            // fontSize: '1rem',
            fontWeight: '500',
            ' :hover': {
              opacity: 0.7,
              backgroundColor: 'transparent',
            },
          },
          '& .MuiButton-startIcon': {
            marginRight: '16px',
            color: palette.menu.main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '3.75rem',
        },
      },
    },
  },
});
