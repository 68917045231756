// library modules
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { localStorageStore, useTranslate } from 'react-admin';
import axios from '../../plugins/axios';

//components
import { Typography, Box, Tabs, Tab, Breadcrumbs } from '@mui/material';
import CustomButton from '../shared/CustomButton';
import MediaItemEdit from './MediaItemEdit';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function BasicTabs() {
  const [value, setValue] = useState(0);
  const [tabValues, setTabValues] = useState();

  const store = localStorageStore();

  const id = store.getItem('mediaItemId');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function fetchTranslationData() {
      const response = await axios(`/media-item/${id}/translatable-ids`, {
        method: 'GET',
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      });
      return response;
    }

    if (!tabValues) {
      fetchTranslationData().then((response) => {
        setTabValues(response.data);
      });
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {tabValues &&
            tabValues.map((item, index) => {
              return (
                <Tab
                  key={item.mediaItemId}
                  label={item.languageName}
                  sx={{ paddingLeft: 0 }}
                />
              );
            })}
        </Tabs>
      </Box>
      {tabValues &&
        tabValues.map((item, index) => {
          return (
            <TabPanel
              value={value}
              index={index}
              key={`${item.mediaItemId} + ${index}}`}
              className='edit-media-item-panel'
              sx={{ padding: 0 }}
            >
              <MediaItemEdit langId={item.languageId} id={item.mediaItemId} />
            </TabPanel>
          );
        })}
    </Box>
  );
}

const EditContent = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
          <CustomButton
            variant='link'
            title={translate('resources.content.content')}
            handleClick={() => navigate('/content/media-catalog')}
          />
          <CustomButton
            variant='link'
            title={translate('resources.content.edit_media_item')}
          />
        </Breadcrumbs>
        <Typography variant='h5' sx={{ paddingLeft: 3, mb: 3 }}>
          {translate('resources.content.edit_media_item')}
        </Typography>
      </div>
      <BasicTabs />
    </>
  );
};

export default EditContent;
