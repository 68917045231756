// library modules
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextInput,
  useCreate,
  useTranslate,
  useRefresh,
  AutocompleteArrayInput,
  required,
  Create,
  SimpleForm,
} from 'react-admin';
import axios from '../../plugins/axios';
import { ToastContext } from '../../context/toastContext';
import { InitialDataContext } from '../../context/initialDataContext';

// components
import { Box, Typography, Breadcrumbs } from '@mui/material';
import CustomButton from '../shared/CustomButton';
import CustomToolbar from '../shared/CustomToolbar';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function RawCreate() {
  // hooks
  const [create] = useCreate();
  const translate = useTranslate();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const initialData = useContext(InitialDataContext);

  // state
  const [selectOptions, setSelectOptions] = useState([]);

  async function fetchCategoryData() {
    const response = await axios('/category/get-filtered-list', {
      method: 'POST',
      data: {
        maxResultCount: 1000,
        filterRule: {
          field: 'LanguageId',
          operator: 'equals',
          value: initialData.defaultLanguage,
        },
      },
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  useEffect(() => {
    fetchCategoryData().then((response) => {
      setSelectOptions(response.data.items);
    });
  }, []);

  const handleSubmit = async (data) => {
    try {
      create('media-item-raw', { data }, { onError, onSuccess });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);
      console.log(err);
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    toast.success(
      translate('notifications.success'),
      `${translate('resources.content.raw_footage')} ${data.name} ${translate(
        'notifications.created'
      )}`
    );

    navigate('/content/raw');
    refresh();
  };

  return (
    <Box sx={{ p: '1.5rem' }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.content.content')}
          handleClick={() => navigate('/content/raw')}
        />
        <CustomButton
          variant='link'
          title={translate('resources.content.create_raw_footage')}
        />
      </Breadcrumbs>
      <Typography variant='h4' sx={{ mb: '1.25rem' }}>
        {translate('resources.content.create_raw_footage')}
      </Typography>
      <Create
        resource='media-item,'
        className='raw-create'
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'unset',
          },
        }}
      >
        <SimpleForm
          toolbar={
            <CustomToolbar handleClick={() => navigate('/content/raw')} />
          }
          onSubmit={handleSubmit}
          sx={{ px: 0 }}
        >
          <TextInput
            label={translate('common.table.name')}
            source='name'
            validate={required()}
            sx={{ width: '60%' }}
          />
          <TextInput
            label={translate('common.table.action')}
            source='alternativeName'
            validate={required()}
            sx={{ width: '60%' }}
          />
          <TextInput
            label={translate('resources.content.description')}
            source='description'
            multiline
            maxRows={8}
            validate={required()}
            sx={{ width: '60%' }}
          />
          <TextInput
            label={translate('resources.content.url')}
            source='url'
            multiline
            maxRows={8}
            validate={required()}
            sx={{ width: '60%' }}
          />
          <AutocompleteArrayInput
            source='categoryIds'
            label={translate('resources.content.add_categories')}
            icon={<KeyboardArrowDownIcon />}
            sx={{ width: '60%', height: '40px', mb: 5 }}
            choices={selectOptions}
            validate={required()}
            optionText='name'
            optionValue='id'
            size='small'
          />
        </SimpleForm>
      </Create>
    </Box>
  );
}
