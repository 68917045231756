import {
  UserManager,
  WebStorageStateStore,
  Log as OidcLog,
} from 'oidc-client-ts';

const AUTH_CLIENT_ID =
  process.env.REACT_APP_AUTH_CLIENT_ID || 'WildImmersion_API_App';
const AUTH_URL =
  process.env.REACT_APP_AUTH_URL || process.env.REACT_APP_API_ENDPOINT;
const DEBUG = process.env.REACT_APP_DEBUG;
const url = window.location.origin;

export const userManager = new UserManager({
  authority: AUTH_URL,
  client_id: AUTH_CLIENT_ID,
  redirect_uri: `${url}/auth-callback`,
  post_logout_redirect_uri: `${url}`,
  response_type: 'code',
  scope:
    'openid profile email address phone roles WildImmersion_API offline_access',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
});

userManager.events.addSilentRenewError(async () => {
  await userManager.removeUser();
  await userManager.signinRedirect();
});

if (DEBUG === '1') {
  // Enable Oidc client log
  OidcLog.setLogger(console);
}

const authProvider = {
  login: (params) => {
    return Promise.resolve(/* ... */);
  },
  async checkError({ status }) {
    if (status === 401) {
      throw new Error('Unauthorized');
    }
  },
  checkAuth: async (params) => {
    try {
      const user = await userManager.getUser();
      if (user && user.expired) {
        const newUser = await userManager
          .signinSilent()
          .then(() => {
            return Promise.resolve();
          })
          .catch(async (e) => {
            await userManager.removeUser();
            await userManager.signinRedirect();
            return Promise.reject();
          });
        return newUser;
      }

      if (user && !user?.expired) {
        return Promise.resolve();
      }

      await userManager.signinRedirect();
    } catch (e) {
      console.log(e);
    }
  },
  logout: async () => {
    debugger;
    await userManager.removeUser();
    await userManager.signoutRedirect();
  },
  async getIdentity() {
    const user = await userManager.getUser();
    if (user) {
      return {
        id: user.profile?.email || user.profile?.sub,
        username: user.profile?.preferred_username || 'User',
        role: user.profile?.role || '',
        sub: user.profile.sub || '',
      };
    }
    throw new Error('Failed to get identity.');
  },
  async handleCallback() {
    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      try {
        const user = await userManager.signinCallback();
        return Promise.resolve(/* ... */);
      } catch (error) {
        console.log('error', error);
        throw error;
      }
    }
    throw new Error('Failed to handle login callback.');
  },
  async getPermissions() {
    const user = await userManager.getUser();
    if (!user) {
      return;
    }
    return 'admin';
  },
};

export default authProvider;
