// library modules
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  TextField,
  useTranslate,
  ResourceContextProvider,
} from 'react-admin';

// components
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function TestemonialsList() {
  const translate = useTranslate();

  return (
    <Box sx={{ p: '1.5rem' }}>
      <Typography variant='h5' sx={{ mb: '3.25rem' }}>
        {translate('resources.testimonials.list_of_testimonials')}
      </Typography>
      <ResourceContextProvider resource='selected-file-item'>
        <List
          resource='selected-file-item-testimonials'
          filters={
            <Filter variant='outlined'>
              <SearchInput
                source='testimonials-list-search'
                variant='outlined'
                alwaysOn
                resettable={true}
                sx={{ mb: '2.75rem', width: 350 }}
              />
            </Filter>
          }
          actions={null}
          empty={false}
          filter={{ sectionId: '2', languageId: '1', customerId: null }}
          sx={{
            '& .MuiToolbar-root': {},
            '& .MuiTablePagination-root': {
              mr: '1rem',
            },
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            expand={(data) => {
              return (
                <Stack sx={{ py: '0.5rem', ml: '3rem', gap: '0.25rem' }}>
                  {data.record.fileItems.map((item, i) => (
                    <Box key={i}>{item.name}</Box>
                  ))}
                </Stack>
              );
            }}
          >
            <TextField source='name' label={translate('common.table.name')} />
            <DateField
              source='dateAdded'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <TextField
              source='publicOrPrivate'
              label={translate('common.table.private_public')}
            />
            <TextField
              source='mediaItemType'
              label={translate('common.table.type')}
            />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </Box>
  );
}
