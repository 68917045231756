// library modules
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {
  List,
  Datagrid,
  SearchInput,
  FilterButton,
  TextField,
  DateField,
  EditButton,
  useTranslate,
  useRecordContext,
  AutocompleteInput,
  FilterForm,
} from 'react-admin';
import { UserContext } from '../../context/userContext';

// components
import { Typography, Stack } from '@mui/material';
import ActionDropdown from '../shared/ActionDropdown';
import CustomButton from '../shared/CustomButton';
import UsernameWithImage from '../shared/UsernameWithImage';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';

// icons
import AddIcon from '@mui/icons-material/Add';

const DropdownMenu = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const { user } = useContext(UserContext);

  if (
    user.roleName !== 'SuperAdmin' &&
    user.roleName !== 'Admin' &&
    user.email !== record.email
  )
    return;

  const options = [
    <EditButton
      record={record}
      resource='admin-user'
      key={'edit'}
      sx={{ mb: 1 }}
    />,
    <DeleteConfirmationDialog
      message={`${translate('general_settings.user')} ${
        record.name
      } ${translate('notifications.deleted')}`}
      title={`${translate('general_settings.delete_user')} ${record.name}?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const ListToolbar = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const postFilters = [
    <SearchInput
      source='adminUserSearch'
      variant='outlined'
      alwaysOn
      resettable={true}
      sx={{ width: 350 }}
    />,

    <AutocompleteInput
      source='roleName'
      optionValue='id'
      variant='outlined'
      label={translate('general_settings.role')}
      resettable='true'
      choices={[
        { id: 'ContentAdmin', name: 'ContentAdmin' },
        { id: 'CustomersAdmin', name: 'CustomersAdmin' },
        { id: 'SuperAdmin', name: 'SuperAdmin' },
      ]}
      sx={{ width: 250 }}
    />,
  ];

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      width={'100%'}
    >
      <FilterForm
        filters={postFilters}
        sx={{ '& .RaFilterFormInput-hideButton': { mb: 0 } }}
      />
      <FilterButton filters={postFilters} sx={{ width: 180 }} />
      {(user?.roleName === 'SuperAdmin' || user?.roleName === 'admin') && (
        <CustomButton
          variant='secondary'
          title={translate('general_settings.add_user')}
          handleClick={() => navigate('/admin-user/create')}
          startIcon={<AddIcon />}
        />
      )}
    </Stack>
  );
};

const SettingsList = () => {
  const translate = useTranslate();
  const { user } = useContext(UserContext);

  return (
    <div style={{ padding: '24px' }}>
      <Typography variant='h5' sx={{ mb: '1rem' }}>
        {translate('general_settings.list_of_users')}
      </Typography>
      <List
        actions={null}
        filters={<ListToolbar />}
        resource='admin-user'
        empty={false}
        sx={{
          '& .MuiToolbar-root': { mb: '2rem !important' },
          '& .MuiTablePagination-root': {
            mr: '1rem',
          },
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <UsernameWithImage
            imageSrc='profileImagePath'
            label={translate('common.table.user_name')}
          />
          <DateField
            source='dateCreated'
            locales='de-DE'
            label={translate('common.table.date_added')}
          />
          <TextField source='roleName' label={translate('common.table.role')} />
          <TextField source='email' label={translate('common.table.email')} />
          <DropdownMenu
            label={translate('common.table.actions')}
            textAlign='center'
          />
        </Datagrid>
      </List>
    </div>
  );
};

export default SettingsList;
