import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';

function useRouteMatch(patterns) {
  let { pathname } = useLocation();

  if (
    pathname.includes('customer-space') &&
    patterns.find((p) => p.endsWith('/customer-space'))
  ) {
    pathname = pathname.slice(0, pathname.lastIndexOf('/'));
  }

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default function TabRouter({ basePath, routes }) {
  const { slug, id } = useParams();

  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch(routes.map((route) => basePath + route.url));
  const currentTab = routeMatch?.pattern?.path
    ? routeMatch?.pattern?.path
    : basePath;

  return (
    <Tabs value={currentTab} className='border-b' variant='scrollable'>
      {routes.map((route) => (
        <Tab
          key={route.name}
          label={route.name}
          value={`${basePath}` + route.url}
          to={`${basePath}` + route.url}
          component={Link}
        />
      ))}
    </Tabs>
  );
}
