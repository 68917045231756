import { useContext } from 'react';
import {
  useTranslate,
  useDeleteMany,
  useListContext,
  useUnselectAll,
} from 'react-admin';
import CustomButton from '../shared/CustomButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContext } from '../../context/toastContext';

export default function BatchDeleteButton({ resource, sx }) {
  const { selectedIds } = useListContext();
  const translate = useTranslate();
  const { toast } = useContext(ToastContext);
  const unselectAll = useUnselectAll(resource);

  const [deleteMany] = useDeleteMany(
    resource,
    {
      ids: selectedIds,
    },
    {
      onSuccess: (data) => {
        toast.success(
          translate('notifications.success'),
          translate('notifications.items_deleted')
        );
        unselectAll();
      },
      onError: (data) => {
        toast.error(translate('notifications.error'), data.message);
      },
    }
  );

  return (
    <>
      <CustomButton
        variant='link'
        title={translate('buttons.delete').toUpperCase()}
        handleClick={() => deleteMany()}
        startIcon={<DeleteIcon />}
        sx={{ ...sx, color: 'error.main', fontSize: '0.8rem', fontWeight: 500 }}
      />
    </>
  );
}
