// library modules
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  SearchInput,
  Filter,
  TextField,
  useTranslate,
  useRecordContext,
  ResourceContextProvider,
} from 'react-admin';
import { UserContext } from '../../context/userContext';

// components
import { Typography } from '@mui/material';
import CustomButton from '../shared/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ActionDropdown from '../shared/ActionDropdown';
import BatchDeleteButton from '../shared/BatchDeleteButton';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';

const DropdownMenu = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const translate = useTranslate();

  const options = [
    <CustomButton
      variant='link'
      title={translate('ra.action.edit').toLocaleUpperCase()}
      handleClick={() => navigate(`/template/${record.id}/our-products`)}
      startIcon={<EditIcon />}
      sx={{ color: 'primary.main', fontSize: '0.83rem', p: 0.3 }}
    />,

    <DeleteConfirmationDialog
      message={translate('resources.template.template_deleted')}
      title={`${translate('resources.template.delete_template')} ${
        record.name
      }?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const TemplateFilter = (props) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { user } = useContext(UserContext);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Filter {...props} variant='outlined'>
        <SearchInput
          source='templateSearch'
          variant='outlined'
          alwaysOn
          resettable={true}
          sx={{ mt: '5px !important', width: 350 }}
        />
      </Filter>
      {user?.roleName !== 'ContentAdmin' && (
        <CustomButton
          variant='secondary'
          title={translate('resources.template.add_template')}
          handleClick={() => navigate('/template/create/our-products')}
          startIcon={<AddIcon />}
        />
      )}
    </div>
  );
};

const TemplateList = () => {
  const translate = useTranslate();
  const { user } = useContext(UserContext);

  return (
    <>
      <Typography variant='h5' sx={{ mb: '2.2rem' }}>
        {translate('resources.template.list_of_templates')}
      </Typography>
      <ResourceContextProvider resource='template'>
        <List
          actions={null}
          filters={<TemplateFilter />}
          resource='template'
          empty={false}
          sx={{
            '& .MuiToolbar-root': { mb: '2rem !important' },
          }}
        >
          <Datagrid
            bulkActionButtons={
              user?.roleName !== 'ContentAdmin' && (
                <BatchDeleteButton resource='template' sx={{ mt: 4 }} />
              )
            }
            sx={{
              '& th:lastChild': {
                color: 'red',
              },
            }}
          >
            <TextField
              source='name'
              label={translate('common.table.name')}
              sx={{ width: '100%' }}
            />
            {user?.roleName !== 'ContentAdmin' && (
              <DropdownMenu
                label={translate('common.table.actions')}
                textAlign='right'
              />
            )}
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </>
  );
};

export default TemplateList;
