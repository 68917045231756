// library modules
import { Outlet } from 'react-router-dom';
import { useTranslate } from 'react-admin';

// components
import Box from '@mui/material/Box';
import TabRouter from '../shared/TabRouter';

export default function BasicTabs() {
  const translate = useTranslate();

  const routes = [
    {
      name: translate('resources.customer.customers'),
      url: '/list',
    },
    {
      name: translate('resources.template.template'),
      url: '/template',
    },
  ];

  return (
    <Box sx={{ width: '100%', px: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <TabRouter basePath='/customers' routes={routes} />
      </Box>
      <Outlet />
    </Box>
  );
}
