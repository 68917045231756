// library modules
import { useNavigate } from 'react-router-dom';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SearchInput,
  TextField,
  useRecordContext,
  EditButton,
  useTranslate,
} from 'react-admin';

// components
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomButton from '../shared/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import ActionDropdown from '../shared/ActionDropdown';
import CategoryField from '../shared/CategoryField';
import UrlField from '../shared/UrlField';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';

const DropdownMenu = () => {
  const translate = useTranslate();
  const record = useRecordContext();

  const options = [
    <EditButton record={record} resource='media-item-raw' key={'edit'} />,
    <DeleteConfirmationDialog
      message={`${translate('resources.content.raw_footage')} ${
        record.name
      } ${translate('notifications.deleted')}`}
      title={`${translate('resources.content.delete_raw_footage')} ${
        record.name
      }?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const CustomFilter = (props) => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const handleClick = () => {
    navigate('/raw-create');
  };

  return (
    <>
      <Filter variant='outlined' sx={{ '& .RaList-root': { minHeight: 0 } }}>
        <SearchInput
          source='rawSearch'
          variant='outlined'
          alwaysOn
          resettable={true}
          sx={{ width: 350 }}
        />
      </Filter>
      <CustomButton
        variant='secondary'
        title={translate('common.add_raw')}
        handleClick={handleClick}
        startIcon={<AddIcon />}
      />
    </>
  );
};

export default function RawFootageList() {
  const translate = useTranslate();

  return (
    <Box sx={{ p: '0' }}>
      <Typography variant='h5' sx={{ mb: '2.2rem' }}>
        {translate('resources.content.list_of_rawfootage')}
      </Typography>
      <List
        filters={<CustomFilter />}
        resource='media-item-raw'
        actions={null}
        empty={false}
        sx={{
          '& .MuiToolbar-root': { marginBottom: '30px' },
          '& .MuiTablePagination-root': {
            position: 'absolute',
            bottom: '-100px',
            right: 0,
            mr: '1rem',
          },
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source='name' label={translate('common.table.name')} />
          <DateField
            source='dateAdded'
            locales='de-DE'
            label={translate('common.table.date_added')}
          />
          <CategoryField label={translate('common.table.category')} />
          <UrlField label='Url' />
          <TextField
            source='mediaItemType'
            label={translate('common.table.media_item_type')}
          />
          <DropdownMenu
            textAlign='center'
            label={translate('common.table.actions')}
          />
        </Datagrid>
      </List>
    </Box>
  );
}
