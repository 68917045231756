// library modules
import { useRecordContext, ChipField } from 'react-admin';

const StatusChip = () => {
  const record = useRecordContext();

  const getColor = () => {
    switch (record.status) {
      case 'Active':
        return 'primary.main';
      case 'Inactive':
        return 'neutral.main';

      default:
        return '';
    }
  };

  return (
    <ChipField
      source='status'
      sx={{
        backgroundColor: getColor(),
        color: record.status === 'Active' ? '#fff' : 'tertiary.main',
      }}
    />
  );
};

export default StatusChip;
