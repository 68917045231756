// library modules
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  SearchInput,
  FilterForm,
  TextField,
  DateField,
  ResourceContextProvider,
  useTranslate,
  useListContext,
  useCreate,
  useUnselectAll,
  localStorageStore,
  AutocompleteInput,
} from 'react-admin';
import { ToastContext } from '../../../context/toastContext';
import { CustomerContext } from '../../../context/customerContext';
import { InitialDataContext } from '../../../context/initialDataContext';

// components
import CustomButton from '../../shared/CustomButton';
import CategoryField from '../../shared/CategoryField';
import UsernameWithImage from '../../shared/UsernameWithImage';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Breadcrumbs } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ExpandedItem from './ExpandedItem';

const RESOURCE = 'media-item';

const canExpand = (list) => {
  return list.length > 0 ? true : false;
};

const OurProductsFilter = ({ createUrl, section, sectionName }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { selectedIds } = useListContext();
  const [create] = useCreate();
  const unselectAll = useUnselectAll(RESOURCE);
  const { toast } = useContext(ToastContext);
  const store = localStorageStore();
  const { customer } = useContext(CustomerContext);
  const initialData = useContext(InitialDataContext);

  const handleClick = () => {
    create(
      createUrl,
      {
        data: selectedIds.map((mediaItemId) => ({
          mediaItemId,
          customerId: customer.id,
          section,
        })),
      },
      {
        onSuccess: () => {
          toast.success(
            translate('notifications.success'),
            `${selectedIds.length} ${translate(
              'resources.customer.items_added_to'
            )} ${sectionName}`
          );
          unselectAll();
          navigate(store.getItem('redirectUrl'));
          store.removeItem('redirectUrl');
        },
        onError: (data) => {
          toast.error(translate('notifications.error'), data.message);

          unselectAll();
          navigate(-1);
        },
      }
    );
  };

  const postFilters = [
    <SearchInput
      source='customerOurProductsSearch'
      variant='outlined'
      alwaysOn
      resettable={true}
    />,
    <AutocompleteInput
      source='languageId'
      optionValue='id'
      variant='outlined'
      label={translate('common.language')}
      resettable='true'
      choices={initialData.languageList}
      sx={{ width: 250 }}
      alwaysOn
    />,
  ];

  return (
    <Stack
      direction='row'
      sx={{ mb: '1rem', alignItems: 'center', width: '100%' }}
    >
      <FilterForm filters={postFilters} variant='outlined' />
      <CustomButton
        disabled={selectedIds.length <= 0}
        variant='secondary'
        title={translate('buttons.add_to_customer_space')}
        handleClick={handleClick}
        startIcon={<AddIcon />}
        sx={{ width: '16rem', whiteSpace: 'nowrap' }}
      />
    </Stack>
  );
};

const AddProducts = ({ name, createUrl, section }) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { customer } = useContext(CustomerContext);

  let defaultFilter = {
    languageId: '1',
  };

  if (section !== 1) {
    defaultFilter = {
      languageId: '1',
      isCaseStudy: 'true',
    };
  }

  if (section === 5) {
    defaultFilter = {
      languageId: '1',
      mediaItemTypeId: '3',
    };
  }

  return (
    <Box sx={{ p: '1.5rem' }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
        <CustomButton
          variant='link'
          title={translate('resources.customer.customers')}
          handleClick={() => navigate(-1)}
        />
        <CustomButton
          variant='link'
          title={`${translate('common.add_item_to')} ${translate(
            'common.' + name
          )}`}
        />
      </Breadcrumbs>
      <Typography variant='h5'>
        {translate('common.add_item_to')} {translate('common.' + name)}
      </Typography>
      <Box
        variant='section-label'
        sx={{ mb: '3.25rem', color: 'neutral.light' }}
      >
        {translate('common.choose_items_to_add')}
      </Box>
      <ResourceContextProvider resource={RESOURCE}>
        <List
          actions={null}
          disableSyncWithLocation={true}
          filters={
            <OurProductsFilter
              createUrl={createUrl}
              section={section}
              customerId={customer?.id}
              sectionName={name}
            />
          }
          filterDefaultValues={defaultFilter}
          resource={RESOURCE}
          empty={false}
          sx={{
            '& .MuiToolbar-root': { mb: '2rem !important' },
          }}
          canExpand={canExpand}
        >
          <Datagrid expand={<ExpandedItem />} bulkActionButtons={<></>}>
            <UsernameWithImage label={translate('common.table.name_of_item')} />
            <DateField
              source='dateAdded'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <CategoryField label={translate('common.table.category')} />
            <TextField
              source='publicOrPrivate'
              label={translate('common.table.private_public')}
            />
            <TextField
              source='mediaItemType'
              label={translate('common.table.media_item_type')}
            />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </Box>
  );
};

export default AddProducts;
