// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SaveButton,
  SimpleForm,
  TextInput,
  SelectInput,
  Toolbar,
  BooleanInput,
  useUpdate,
  required,
  email,
  useTranslate,
  useRefresh,
  ImageField,
  Datagrid,
  ListContextProvider,
  useList,
  TextField as RaTextField,
  useRecordContext,
  PasswordInput,
  localStorageStore,
} from 'react-admin';
import axios from '../../../plugins/axios';
import { createPassword } from '../../../utils/passwordGenerator';
import { ToastContext } from '../../../context/toastContext';
import { CustomerContext } from '../../../context/customerContext';

// components
import {
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import UploadIcon from '../../shared/UploadIcon';

// utils
import { removeFileFromArray } from '../../../utils/removeFileFromArray';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// css
import { theme } from '../../layout/themes';

const CustomToolbar = ({ basePath, ...props }) => {
  const navigate = useNavigate();
  const translate = useTranslate();

  return (
    <Toolbar {...props} style={{ padding: 0, backgroundColor: 'transparent' }}>
      <SaveButton
        style={{ margin: 0, marginRight: 10 }}
        sx={{ '& .MuiSvgIcon-root': { display: 'none' } }}
        label={translate('buttons.save_changes')}
        alwaysEnable
      />
      <CustomButton
        variant='primary'
        title={translate('buttons.discard')}
        handleClick={() => navigate('/customers/list')}
        sx={{ marginLeft: '30px' }}
      />
    </Toolbar>
  );
};

const CompanyInformation = () => {
  const { customer } = useContext(CustomerContext);
  // state
  const [userData, setUserData] = useState();
  const [profileFile, setProfilefile] = useState();
  const [previewProfileFile, setPreviewProfileFile] = useState();
  const [coverFile, setCoverFile] = useState();
  const [previewCoverFile, setPreviewCoverFile] = useState();
  const [selectLanguageIdOptions, setSelectlanguageOptions] = useState([]);
  const [isPassworedVisible, setIsPassworedVisible] = useState(
    customer?.isActive
  );
  const [passwordFieldsIsVisible, setPasswordFieldsIsVisible] = useState(false);
  const [newPasswordErrMsg, setNewPasswordErrMsg] = useState(false);
  const [repeatPasswordErrMsg, setRepeatPasswordErrMsg] = useState(false);
  const [passwordMismatchErrMsg, setPasswordMismatchErrMsg] = useState(false);
  const [reload] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  // hooks
  const { toast } = useContext(ToastContext);
  const navigate = useNavigate();
  const translate = useTranslate();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const emailListContext = useList({ data: emailsList });
  const store = localStorageStore();

  // refs
  const passwordInput = useRef(null);
  const hiddenProfileFileInput = useRef(null);
  const hiddenCoverFileInput = useRef(null);

  useEffect(() => {
    if (customer?.emails) setEmailsList(customer?.emails);
    // store.setItem('customerId', customer.id);
  }, [customer?.emails]);

  useEffect(() => {
    if (customer?.id) store.setItem('customerId', customer.id);
  }, [customer?.id]);

  useEffect(() => {
    if (!profileFile) {
      setPreviewProfileFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(profileFile);
    setPreviewProfileFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [profileFile]);

  useEffect(() => {
    if (customer?.isActive) {
      setIsPassworedVisible(true);
    }
  }, [customer?.isActive]);

  useEffect(() => {
    if (!coverFile) {
      setPreviewCoverFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(coverFile);
    setPreviewCoverFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [coverFile]);

  useEffect(() => {
    async function fetchLanguageData() {
      const response = await axios('/customer/languages', { method: 'GET' });
      return response;
    }

    fetchLanguageData().then((response) =>
      setSelectlanguageOptions(response.data.items)
    );
  }, [reload]);

  const handleProfileImageClick = (event) => {
    hiddenProfileFileInput.current.click();
  };

  const handleCoverImageClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const handleLogoUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setProfilefile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setProfilefile(fileUploaded);
  };

  const handleCoverUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCoverFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setCoverFile(fileUploaded);
  };

  const generatePasword = () => {
    passwordInput.current.value = createPassword(16);
  };

  const updateCustomer = async (data) => {
    try {
      let profileImageResponse = '';
      let coverImageResponse = '';

      if (passwordFieldsIsVisible) {
        if (
          data.newPassword.match(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?]).{6,}/
          )
        ) {
          setNewPasswordErrMsg(false);
        } else {
          setNewPasswordErrMsg(true);
          return;
        }

        if (
          data.repeatPassword.match(
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?]).{6,}/
          )
        ) {
          setRepeatPasswordErrMsg(false);
        } else {
          setRepeatPasswordErrMsg(true);
          return;
        }

        if (data.newPassword !== data.repeatPassword) {
          setPasswordMismatchErrMsg(true);
          return;
        } else {
          setPasswordMismatchErrMsg(false);
          data.password = data.newPassword;
        }
      }

      if (previewProfileFile || previewCoverFile) {
        if (previewProfileFile) {
          const formData = new FormData();
          formData.append('file', profileFile);

          const response = await axios.post(
            '/customer/upload-image',
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
          profileImageResponse = response.data;
        }

        if (previewCoverFile) {
          const formData = new FormData();
          formData.append('file', coverFile);

          const response = await axios.post(
            '/customer/upload-image',
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
          coverImageResponse = response.data;
        }

        data.coverPath = previewCoverFile ? coverImageResponse : data.coverPath;
        data.coverPathOriginal = previewCoverFile
          ? coverImageResponse
          : data.coverPathOriginal;
        data.logoPath = previewProfileFile
          ? profileImageResponse
          : data.logoPath;
        data.logoPathOriginal = previewProfileFile
          ? profileImageResponse
          : data.logoPathOriginal;
        if (!data.password)
          data.password = data.editPassword
            ? passwordInput?.current?.value
            : '';

        const transformed = emailsList.map((item) => {
          if (item.emailAddress === item.id) item.id = 0;

          return item;
        });

        data.emails = transformed;
        if (data.hasWildFactory)
          data.wildFactoryUrl = `${process.env.REACT_APP_WF_URL}/${data?.slug}`;

        setUserData(data);
        update('customer', { data }, { onSuccess, onError });
      } else {
        const transformed = emailsList.map((item) => {
          if (item.emailAddress === item.id) item.id = 0;

          return item;
        });

        data.emails = transformed;
        if (data.hasWildFactory)
          data.wildFactoryUrl = `${process.env.REACT_APP_WF_URL}/${data?.slug}`;
        if (!data.password)
          data.password = data.editPassword ? passwordInput.current.value : '';
        update('customer', { data }, { onSuccess, onError });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    toast.success(
      translate('notifications.success'),
      `${translate('resources.customer.customer')} ${
        data.firstName
      } ${translate('common.updated')}`
    );

    navigate('/customers/list');
  };

  const deleteFile = (record) => {
    setEmailsList(removeFileFromArray(emailsList, record.id));
    refresh();
  };

  const DeleteButton = () => {
    const record = useRecordContext();

    return (
      <CustomButton
        label={translate('common.table.actions')}
        variant='link'
        title={translate('buttons.delete')}
        handleClick={() => deleteFile(record)}
        sx={{ color: '#E74C3C', minWidth: '20px' }}
      />
    );
  };

  const AddEmailModal = ({ isOpenEmail, close }) => {
    const translate = useTranslate();
    const [emailAddress, setEmailAddress] = useState();
    const [contactPerson, setContactPerson] = useState();
    // const [checked, setChecked] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [contactError, setContactError] = useState(false);

    const save = () => {
      if (!emailAddress || !contactPerson) {
        if (!emailAddress) setEmailError(true);
        if (!contactPerson) setContactError(true);
        return;
      }
      setEmailError(true);
      setContactError(true);

      if (emailsList) {
        setEmailsList([
          ...emailsList,
          {
            id: emailAddress,
            emailAddress: emailAddress,
            contactPerson: contactPerson,
            isDefault: false,
            // notificationsEnabled: checked,
          },
        ]);
      } else {
        setEmailsList([
          {
            id: emailAddress,
            emailAddress: emailAddress,
            contactPerson: contactPerson,
            isDefault: false,
            // notificationsEnabled: checked,
          },
        ]);
      }

      setIsOpen(false);
    };
    return (
      <Dialog open={isOpen} onClose={close} sx={{ minHeight: '400px' }}>
        <DialogContent>
          <Typography variant='h6' sx={{ mb: 3 }}>
            {translate(`resources.customer.additional_email`)}
          </Typography>
          <TextField
            value={emailAddress}
            type='email'
            label={translate('resources.customer.contact_email')}
            sx={{ width: '100%', mb: 3 }}
            size='small'
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          {emailError && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-25px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('general_settings.required')}
            </p>
          )}
          <TextField
            value={contactPerson}
            type='email'
            label={translate('common.contact_person')}
            sx={{ width: '100%', mb: 3 }}
            size='small'
            onChange={(e) => setContactPerson(e.target.value)}
          />
          {contactError && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-25px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('general_settings.required')}
            </p>
          )}
          {/* <FormControlLabel
            value='start'
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            sx={{ color: 'primary.main' }}
            label={translate('resources.customer.enable_notification')}
            labelPlacement='end'
          /> */}
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => save()}>
            {translate('ra.action.save')}
          </Button>
          <Button onClick={close}>{translate('ra.action.close')}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Typography variant='h6' sx={{ fontSize: '1rem', my: '2rem' }}>
        {translate('resources.customer.customers_informations')}
      </Typography>
      <SimpleForm
        record={customer}
        toolbar={
          <CustomToolbar
          // handleClick={() => navigate('/customers/list')}
          // isEdit={true}
          />
        }
        onSubmit={updateCustomer}
      >
        <TextInput
          source='companyName'
          label={translate('resources.customer.add_company_name')}
          validate={required()}
          sx={{ width: '60%', mb: 1 }}
        />
        <div style={{ width: '100%' }}>
          <TextInput
            source='email'
            type='email'
            disabled
            validate={[required(), email()]}
            label={translate('resources.customer.add_company_email')}
            sx={{ width: '60%' }}
          />

          {(!emailsList || emailsList?.length <= 2) && (
            <IconButton
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={() => setIsOpen(true)}
              sx={{ ml: 1 }}
            >
              <AddCircleIcon sx={{ fontSize: 22, color: 'primary.main' }} />
            </IconButton>
          )}
        </div>
        <TextInput
          source='contactPerson'
          label={translate('common.contact_person')}
          validate={required()}
          sx={{ width: '60%', mb: 1 }}
        />
        {emailsList?.length > 0 && (
          <ListContextProvider value={emailListContext}>
            <Datagrid bulkActionButtons={false} sx={{ width: '100%', mb: 5 }}>
              <RaTextField
                source='emailAddress'
                label={translate('common.table.email')}
              />
              <RaTextField
                source='contactPerson'
                label={translate('common.contact_person')}
              />
              {/* <RaTextField
                source='notificationsEnabled'
                label={translate('resources.customer.notification_enabled')}
              /> */}
              <DeleteButton label={translate('common.table.action')} />
            </Datagrid>
          </ListContextProvider>
        )}
        <div
          style={{
            margin: '0 0 1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <div
          style={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: '20px',
            gridColumnStart: 'auto',
          }}
        >
          <div>
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
              {translate('common.language')}
            </Typography>
            <SelectInput
              source='languageId'
              icon={<KeyboardArrowDownIcon />}
              sx={{ height: '40px', mt: 0, width: '100%' }}
              choices={selectLanguageIdOptions}
              optionText='name'
              optionValue='id'
            />
          </div>
          <div>
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
              {translate('common.country')}
            </Typography>
            <TextInput
              source='country'
              label={translate('common.country')}
              sx={{ width: '100%' }}
            />
          </div>
        </div>
        <div
          style={{
            margin: '1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
          {translate('resources.customer.showtime_licence')}
        </Typography>
        <TextInput
          source='showtimeLicense'
          label={translate('resources.customer.showtime_licence')}
          sx={{ width: '60%' }}
          autoComplete='off'
        />
        <div
          style={{
            margin: '1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>

        <>
          <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
            {translate('common.change_password')}
          </Typography>
          {isPassworedVisible ? (
            <CustomButton
              sx={{ mb: 3 }}
              title={translate('buttons.reset_password')}
              variant='primary'
              handleClick={() => setPasswordFieldsIsVisible(true)}
            />
          ) : null}

          {passwordFieldsIsVisible ? (
            <>
              <PasswordInput
                source='newPassword'
                label={translate('common.enter_new_password')}
                sx={{ width: '60%' }}
              />
              {newPasswordErrMsg && (
                <p
                  style={{
                    color: '#E74C3C',
                    marginTop: '-25px',
                    fontSize: '0.8rem',
                    marginLeft: '0.9rem',
                  }}
                >
                  {translate('common.password_validation')}
                </p>
              )}
              <PasswordInput
                source='repeatPassword'
                label={translate('common.repeat_new_password')}
                sx={{ width: '60%' }}
              />
              {repeatPasswordErrMsg && (
                <p
                  style={{
                    color: '#E74C3C',
                    marginTop: '-25px',
                    fontSize: '0.8rem',
                    marginLeft: '0.9rem',
                  }}
                >
                  {translate('common.password_validation')}
                </p>
              )}
              {passwordMismatchErrMsg && (
                <p
                  style={{
                    color: '#E74C3C',
                    marginTop: '-25px',
                    fontSize: '0.8rem',
                    marginLeft: '0.9rem',
                  }}
                >
                  {translate('common.password_mismatch')}
                </p>
              )}
            </>
          ) : null}
        </>

        <BooleanInput
          label={translate('resources.customer.active_user')}
          source='isActive'
          onChange={(value) => {
            if (!value.target.checked) {
              setPasswordFieldsIsVisible(false);
            }
            setIsPassworedVisible(value.target.checked);
          }}
          sx={{
            '& .MuiFormControlLabel-label': {
              color: theme.palette.primary.main,
            },
          }}
        />
        <BooleanInput
          label={translate('resources.customer.activate_wild_factory')}
          source='hasWildFactory'
          sx={{
            '& .MuiFormControlLabel-label': {
              color: theme.palette.primary.main,
            },
          }}
        />
        <div
          style={{
            margin: '0 0 1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
          {translate('common.customer_logo')}
        </Typography>
        <div style={{ display: 'flex', marginBottom: 38 }}>
          <UploadIcon />
          <CustomButton
            title={translate('common.click_to_upload')}
            variant='ghost'
            style={{ marginLeft: 4, marginRight: 4 }}
            handleClick={handleProfileImageClick}
          />
          <input
            type='file'
            accept='image/*'
            ref={hiddenProfileFileInput}
            onChange={handleLogoUploadChange}
            style={{ display: 'none' }}
          />
          {!previewProfileFile ? (
            <ImageField
              source='logoPath'
              title='avatar'
              sx={{
                '& .RaImageField-image': {
                  width: '6.3rem',
                  height: '6.3rem',
                },
              }}
            />
          ) : (
            <img
              src={previewProfileFile}
              alt='avatar'
              style={{ height: '6.3rem' }}
            />
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <div>
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
              {translate('common.cover_image')}
            </Typography>
            <div style={{ display: 'flex', marginBottom: 38 }}>
              <UploadIcon />
              <CustomButton
                title={translate('common.click_to_upload')}
                variant='ghost'
                style={{ marginLeft: 4, marginRight: 4 }}
                handleClick={handleCoverImageClick}
              />
              <input
                type='file'
                accept='image/*'
                ref={hiddenCoverFileInput}
                onChange={handleCoverUploadChange}
                style={{ display: 'none' }}
              />

              {!previewCoverFile ? (
                <ImageField
                  source='coverPath'
                  title='avatar'
                  sx={{
                    '& .RaImageField-image': {
                      width: '6.3rem',
                      height: '6.3rem',
                    },
                  }}
                />
              ) : (
                <img
                  src={previewCoverFile}
                  alt='avatar'
                  style={{ height: '6.3rem' }}
                />
              )}
            </div>
          </div>

          <div style={{ marginLeft: '4rem' }}>
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
              {translate('common.cover_position')}
            </Typography>
            <SelectInput
              source='coverPosition'
              icon={<KeyboardArrowDownIcon />}
              sx={{ height: '40px', mt: '-6', width: '100%' }}
              choices={[
                { name: 'top' },
                { name: 'center' },
                { name: 'bottom' },
              ]}
              optionText='name'
              optionValue='name'
            />
          </div>
        </div>
      </SimpleForm>
      <AddEmailModal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default CompanyInformation;
