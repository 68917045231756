import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './Toast.css';

export const INFO = 'INFO';
export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';
export const DANGER = 'DANGER';

const Toast = ({ type, title, message }) => {
  const generateIcon = (type) => {
    switch (type) {
      // case INFO:
      //   return <FaInfoCircle />;
      // case WARNING:
      //   return <FaExclamationTriangle />;
      case DANGER:
        return <DangerousIcon sx={{ color: 'white' }} />;
      case SUCCESS:
        return <CheckCircleOutlineOutlinedIcon sx={{ color: '#2E7D32' }} />;
      default:
        return;
    }
  };

  const generateBackgroundColor = (type) => {
    switch (type) {
      case INFO:
        return '#5bc0de';
      case WARNING:
        return '#f0ad4e';
      case DANGER:
        return '#d9534f';
      case SUCCESS:
        return '#EAF2EA';
      default:
        return;
    }
  };

  const generateTitleColor = (type) => {
    switch (type) {
      case INFO:
        return '#5bc0de';
      case WARNING:
        return '#f0ad4e';
      case DANGER:
        return '#fff';
      case SUCCESS:
        return '#2A9D8F';
      default:
        return;
    }
  };

  const generateMessageColor = (type) => {
    switch (type) {
      case INFO:
        return '#5bc0de';
      case WARNING:
        return '#f0ad4e';
      case DANGER:
        return '#fff';
      case SUCCESS:
        return '#2A9D8F';
      default:
        return;
    }
  };

  return (
    <div
      style={{
        background: generateBackgroundColor(type),
        boxShadow: `0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)`,
        '&:hover': {
          boxShadow:
            '0px 0px 2px rgba(0, 0, 0, 0.8), 0px 20px 20px rgba(0, 0, 0, 0.04)',
        },
        padding: '16px',
      }}
      className='notification'
    >
      <button className='close-button' onClick={() => {}}>
        <CloseOutlinedIcon
          sx={{
            width: '20px',
            height: '20px',
          }}
        />
      </button>
      <div className='notification-image'>{generateIcon(type)}</div>
      <div style={{ color: '#54595e', height: '100%' }}>
        <p
          className='notification-title'
          style={{ color: `${generateTitleColor(type)}` }}
        >
          {title}
        </p>
        <p
          className='notification-message'
          style={{ color: `${generateMessageColor(type)}` }}
        >
          {message}
        </p>
      </div>
    </div>
  );
};

export default Toast;
