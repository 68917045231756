// library modules
import { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { Outlet, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/userContext';

// components
import { Box } from '@mui/material';
import ButtonDropdown from '../shared/ButtonDropdown';
import TabRouter from '../shared/TabRouter';

export default function BasicTabs() {
  const translate = useTranslate();
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();

  const routes = [
    {
      name: translate('resources.content.media_catalogue'),
      url: '/media-catalog',
    },
    {
      name: translate('resources.content.categories'),
      url: '/categories',
    },
    { name: translate('resources.content.raw_footage'), url: '/raw' },
  ];

  if (user?.roleName !== 'CustomersAdmin')
    routes.push({
      name: translate('resources.content.deleted_files'),
      url: '/deleted-files',
    });

  return (
    <Box sx={{ width: '100%', position: 'relative', px: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <TabRouter basePath='/content' routes={routes} />
      </Box>
      <Outlet />
      {pathname === '/content/media-catalog' && <ButtonDropdown />}
    </Box>
  );
}
