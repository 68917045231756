import axios from '../plugins/axios';

export default async function uploadThumb(item) {
  const thumbFormData = new FormData();
  thumbFormData.append('file', item);

  const response = await axios.post(
    '/media-item/upload-file-item-thumbnail',
    thumbFormData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );

  return response.data;
}
