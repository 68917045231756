import axios from "axios";
import config from "../../config";
import { userManager } from "../../auth/authProvider";

const instance = axios.create({
  baseURL: `${config.apiBaseUrl}`,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    const user = await userManager.getUser();

    if (!user || user.expired) {
      return await userManager.signinRedirect();
    }

    config.headers.Authorization = `Bearer ${user.access_token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location = '/unauthorized';
  }
  return Promise.reject(error);
});

export default instance;
