// library modules
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ResourceContextProvider,
  useTranslate,
  useRecordContext,
  useRefresh,
  FunctionField,
  useCreate,
} from 'react-admin';
import { CustomerContext } from '../../../context/customerContext';
import { ToastContext } from '../../../context/toastContext';
import axios from '../../../plugins/axios';

// components
import ExpandedItem from './ExpandedItem';
import { ListFilter } from './ListFilter';
import ActionDropdown from '../../shared/ActionDropdown';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import UsernameWithImage from '../../shared/UsernameWithImage';
import CustomButton from '../../shared/CustomButton';

// icons
import FileDownloadOffOutlinedIcon from '@mui/icons-material/FileDownloadOffOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const RESOURCE = 'purchased-file-item';
const CREATE_PAGE = '/add-purchased';

const canExpand = (list) => {
  return list.length > 0 ? true : false;
};

const DropdownMenu = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const navigate = useNavigate();
  const { customer } = useContext(CustomerContext);
  const refresh = useRefresh();
  async function handleIsDownloadable() {
    const response = await axios('/purchased-file-item/set-is-download', {
      method: 'POST',
      data: {
        customerId: customer.id,
        itemId: record.mediaItemId,
        isDownload: record?.isDownload ? false : true,
      },

      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
    refresh();
    return response;
  }

  const options = [
    <CustomButton
      variant='link'
      title={
        record?.isDownload
          ? translate('common.disable_download').toUpperCase()
          : translate('common.enable_download').toUpperCase()
      }
      sx={{
        fontSize: '0.8125rem',
        px: 0.8,
        '& .MuiButton-startIcon': { marginRight: '8px' },
      }}
      handleClick={() => handleIsDownloadable()}
      startIcon={
        record?.isDownload ? (
          <FileDownloadOffOutlinedIcon />
        ) : (
          <FileDownloadOutlinedIcon />
        )
      }
    />,
    <DeleteConfirmationDialog
      message={`${translate('resources.content.media_item')} ${
        record.name
      } ${translate('notifications.deleted')}`}
      title={`${translate('resources.content.delete_media_item')} ${
        record.name
      }?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const PurchasedList = () => {
  const translate = useTranslate();
  const [create] = useCreate();
  const { customer } = useContext(CustomerContext);
  const { toast } = useContext(ToastContext);
  const refresh = useRefresh();

  async function handleCopy() {
    const response = await axios.post('/selected-file-item/get-filtered-list', {
      sorting: 'id ASC',
      skipCount: 0,
      maxResultCount: 999,
      filterRule: {
        condition: 'and',
        filterRules: [
          { field: 'LanguageId', operator: 'equals', value: '1' },
          { field: 'SectionId', operator: 'equals', value: '1' },
          {
            field: 'CustomerId',
            operator: 'equals',
            value: customer.id.toString(),
          },
        ],
      },
    });

    if (response.data.items.length > 0) {
      const payloadArray = response.data.items.map((object) => {
        return { mediaItemId: object.mediaItemId, customerId: customer.id };
      });

      create(
        'purchased-file-item/batch-create',
        {
          data: payloadArray,
        },
        {
          onSuccess: () => {
            toast.success(
              translate('notifications.success'),
              translate('common.items_added')
            );
            refresh();
          },
          onError: (data) => {
            toast.error(translate('notifications.error'), data.message);
          },
        }
      );
    }
  }

  return (
    <>
      <ResourceContextProvider resource={RESOURCE}>
        <List
          actions={null}
          filterDefaultValues={{ languageId: '1' }}
          filters={
            <ListFilter
              createPage={CREATE_PAGE}
              source='purchasedFileItemSearch'
              title={translate('buttons.add_product')}
              handleCopy={handleCopy}
            />
          }
          filter={{
            customerId: customer ? customer?.id.toString() : '0',
          }}
          resource={RESOURCE}
          empty={false}
          canExpand={canExpand}
        >
          <Datagrid expand={<ExpandedItem />} bulkActionButtons={false}>
            <UsernameWithImage label={translate('common.table.name_of_item')} />
            <DateField
              source='dateAdded'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <TextField
              source='publicOrPrivate'
              label={translate('common.table.private_public')}
            />
            <TextField
              source='mediaItemType'
              label={translate('common.table.media_item_type')}
            />
            <FunctionField
              render={(record) =>
                record.isDownload
                  ? `${translate('common.table.yes')}`
                  : `${translate('common.table.no')}`
              }
              source='isDownload'
              label={translate('resources.content.downloadable')}
            />
            <DropdownMenu
              label={translate('common.table.actions')}
              textAlign='center'
            />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </>
  );
};

export default PurchasedList;
