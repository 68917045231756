import React, { createContext } from 'react';
import { toast as addToast } from 'react-toastify';
import Toast, { DANGER, SUCCESS } from '../components/shared/Toast/Toast';

export const ToastContext = createContext();

export const ToastContextProvider = (props) => {
  const notify = (type, title, message) => {
    addToast(<Toast {...{ type, title, message }} />, {
      autoClose: 3000,
      position: 'bottom-left',
      hideProgressBar: true,
      closeButton: false,
    });
  };

  const toast = {
    success: (title, message) => notify(SUCCESS, title, message),
    error: (title, message) => notify(DANGER, title, message),
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      {props.children}
    </ToastContext.Provider>
  );
};
