// library modules
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  SearchInput,
  Filter,
  TextField,
  DateField,
  ResourceContextProvider,
  useRecordContext,
  EditButton,
  useTranslate,
} from 'react-admin';
import { CustomerContext } from '../../../context/customerContext';
import { InitialDataContext } from '../../../context/initialDataContext';

//components
import { Box } from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import ActionDropdown from '../../shared/ActionDropdown';
import BatchDeleteButton from '../../shared/BatchDeleteButton';
import UrlField from '../../shared/UrlField';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';

const RESOURCE = 'customer-document-invoice';
const CREATE_PAGE = '/add-invoices';
// const DOCUMENT_TYPE_ID = '1';

const DropdownMenu = () => {
  const translate = useTranslate();
  const record = useRecordContext();

  const options = [
    <EditButton record={record} resource='edit-invoice' key={'edit'} />,
    <DeleteConfirmationDialog
      message={`${translate('notifications.document_deleted')}`}
      title={`${translate('resources.contracts.delete_invoice')} ${
        record.name
      }?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const InvoicesFilter = (props) => {
  const navigate = useNavigate();
  const translate = useTranslate();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '3rem',
      }}
    >
      <Filter {...props} variant='outlined'>
        <SearchInput
          source='customerInvoicesSearch'
          variant='outlined'
          alwaysOn
          resettable={true}
        />
      </Filter>
      <CustomButton
        variant='secondary'
        title={translate('resources.contracts.add_invoice')}
        handleClick={() => navigate(CREATE_PAGE)}
        startIcon={<AddIcon />}
      />
    </Box>
  );
};

const InvoicesList = (props) => {
  const translate = useTranslate();
  const { customer } = useContext(CustomerContext);
  const initialData = useContext(InitialDataContext);

  return (
    <>
      <ResourceContextProvider resource={RESOURCE}>
        <List
          actions={null}
          filters={<InvoicesFilter />}
          resource={RESOURCE}
          filter={{
            documentTypeId: initialData?.invoiceId.toString(),
            customerId: customer ? customer?.id.toString() : '0',
          }}
          empty={false}
        >
          <Datagrid
            bulkActionButtons={<BatchDeleteButton resource='media-item' />}
          >
            <TextField source='name' label={translate('common.table.name')} />
            <DateField
              source='signatureDate'
              locales='de-DE'
              label={translate('resources.contracts.emission_date')}
            />
            <UrlField source='url' label={translate('common.table.url')} />
            <DropdownMenu
              label={translate('common.table.actions')}
              textAlign='center'
            />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </>
  );
};

export default InvoicesList;
