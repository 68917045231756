import { theme } from '../layout/themes';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

export default function UploadIcon() {
  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
        height: '40px',
        width: '40px',
        borderRadius: '100%',
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <UploadFileOutlinedIcon sx={{ color: '#fff' }} />
    </div>
  );
}
