// library modules
import { useContext } from 'react';
import {
  List,
  Datagrid,
  SearchInput,
  Filter,
  TextField,
  DateField,
  ResourceContextProvider,
  useTranslate,
  useRecordContext,
} from 'react-admin';
import { InitialDataContext } from '../../context/initialDataContext';
import { UserContext } from '../../context/userContext';

// components
import { Typography } from '@mui/material';
import ActionDropdown from '../shared/ActionDropdown';
import BatchDeleteButton from '../shared/BatchDeleteButton';
import UrlField from '../shared/UrlField';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';

const DropdownMenu = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  const options = [
    <DeleteConfirmationDialog
      message={`${translate('resources.contracts.invoice')} ${
        record.name
      } ${translate('notifications.deleted')}`}
      title={`${translate('resources.contracts.delete_invoice')} ${
        record.name
      }?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const InvoicesFilter = (props) => {
  return (
    <>
      <Filter {...props} variant='outlined'>
        <SearchInput
          source='customerInvoicesSearch'
          variant='outlined'
          alwaysOn
          resettable={true}
          sx={{ width: 350 }}
        />
      </Filter>
    </>
  );
};

const InvoicesList = () => {
  const translate = useTranslate();
  const initialData = useContext(InitialDataContext);
  const { user } = useContext(UserContext);

  return (
    <>
      <ResourceContextProvider resource='customer-document'>
        <Typography variant='h5' sx={{ mb: '1rem' }}>
          {translate('resources.contracts.list_of_invoices')}
        </Typography>
        <List
          actions={null}
          filters={<InvoicesFilter />}
          resource='customer-document-invoice-list'
          filter={{
            documentTypeId: initialData.invoiceId,
          }}
          empty={false}
          sx={{
            '& .MuiToolbar-root': { mb: '2rem !important' },
            '& .MuiTablePagination-root': {
              mr: '1rem',
            },
          }}
        >
          <Datagrid
            bulkActionButtons={
              user?.roleName !== 'ContentAdmin' && (
                <BatchDeleteButton sx={{ mt: 4 }} />
              )
            }
          >
            <TextField source='name' label={translate('common.table.name')} />
            <DateField
              source='creationTime'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <DateField
              source='signatureDate'
              locales='de-DE'
              label={translate('resources.contracts.emission_date')}
            />
            <TextField
              source='customerName'
              label={translate('common.customer_name')}
            />
            <UrlField label={translate('common.table.url')} />
            {user?.roleName !== 'ContentAdmin' && (
              <DropdownMenu
                label={translate('common.table.actions')}
                textAlign='center'
              />
            )}
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </>
  );
};

export default InvoicesList;
