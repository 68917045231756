// library modules
import { useState, useRef, useContext, useEffect } from 'react';
import { useRefresh, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import axios from '../../../plugins/axios';

// components
import { TextField } from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import FormDialog from '../../shared/FormDialog';

// context
import { ToastContext } from '../../../context/toastContext';

export default function CustomerUrlModal({
  modalOpen,
  setModalOpen,
  data,
  user,
}) {
  // state
  const [userData, setUserData] = useState();
  const [userObject, setUserObject] = useState();
  const [customerSpaceUrl, setCustomerSpaceUrl] = useState();
  const [wildFactoryUrl, setWildFactoryUrl] = useState();

  // hooks
  const refresh = useRefresh();
  const translate = useTranslate();
  const navigate = useNavigate();
  const { toast } = useContext(ToastContext);

  // refs
  const customerSpaceUrlInput = useRef(null);

  useEffect(() => {
    setUserData(data);
    setUserObject(user);
    setCustomerSpaceUrl(
      `${process.env.REACT_APP_WEB_BASE_URL}/${data?.slug}/our-products`
    );
    setWildFactoryUrl(`${process.env.REACT_APP_WF_URL}/${data?.slug}`);
  }, [data, user]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(customerSpaceUrlInput.current.value);
  };

  async function saveCustomerSpaceUrl() {
    const response = await axios('/customer/update-customer-space-url', {
      method: 'POST',
      data: {
        id: userData.id,
        url: customerSpaceUrl,
      },

      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  async function saveWildFactoryUrl() {
    const response = await axios('/customer/update-wild-factory-url', {
      method: 'POST',
      data: {
        id: userData.id,
        url: wildFactoryUrl,
      },

      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  const addCustomerSpaceUrl = () => {
    userObject.id = userData.id;
    userObject.statusId = userData.statusId;
    userObject.customerSpaceUrl = customerSpaceUrl;
    userObject.logoPathOriginal = userData.logoPathOriginal;
    userObject.coverPathOriginal = userData.coverPathOriginal;

    saveCustomerSpaceUrl()
      .then(() => {
        if (!userObject.hasWildFactory) {
          toast.success(translate('notifications.success'), 'Customer added');
          navigate('/customers/list');
          refresh();
        }
        if (userObject.hasWildFactory) {
          saveWildFactoryUrl()
            .then(() => {
              toast.success(
                translate('notifications.success'),
                'Customer added'
              );
              navigate('/customers/list');
              refresh();
            })
            .catch((err) => {
              toast.error(translate('notifications.error'), err.message);
            });
        }
      })
      .catch((err) => {
        toast.error(translate('notifications.error'), err.message);
      });
  };

  return (
    <FormDialog
      title='Add customer space'
      isOpen={modalOpen}
      handleClose={() => {
        setModalOpen(false);
      }}
      handleConfirm={addCustomerSpaceUrl}
      hideClose={true}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
          marginTop: 8,
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: '0rem', width: '120px' }}>
          {translate('resources.customer.url_adress')}
        </div>
        <TextField
          source='customerSpaceURL'
          value={customerSpaceUrl}
          type='text'
          label={''}
          inputRef={customerSpaceUrlInput}
          sx={{
            width: '100%',
            zIndex: 0,
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderWidth: 1,
              },
            },
            '& .MuiInputBase-input': {
              height: 28,
              py: '6px',
            },
            '& .MuiInputBase-root': {
              mr: 15,
              pr: 4,
              flexBasis: 'auto',
            },
          }}
        />
        <CustomButton
          title={translate('buttons.copy_link')}
          variant='primary-float-right'
          handleClick={copyToClipboard}
        />
      </div>
    </FormDialog>
  );
}
