// library modules
import { useState, useEffect, useContext } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import {
  TextInput,
  useUpdate,
  useTranslate,
  useRefresh,
  localStorageStore,
  SelectInput,
  SimpleForm,
  useGetOne,
} from 'react-admin';
import axios from '../../../plugins/axios';
import { ToastContext } from '../../../context/toastContext';
import { useNavigate } from 'react-router-dom';

// components
import { Box, Typography, Breadcrumbs } from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import CustomToolbar from '../../shared/CustomToolbar';
import TabRouter from '../../shared/TabRouter';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TemplateEdit = ({ name }) => {
  // state
  const [selectOptions, setSelectOptions] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);

  //hooks
  const [update] = useUpdate();
  const navigate = useNavigate();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { id } = useParams();
  const { toast } = useContext(ToastContext);
  const store = localStorageStore();
  store.setItem('template.isEdit', true);
  store.setItem('template.templateId', id);

  // routes
  const routes = [
    {
      name: translate('common.discover_wild_immersion'),
      url: '/our-products',
    },
    {
      name: translate('common.case_studies'),
      url: '/case-studies',
    },
    {
      name: translate('common.testimonials'),
      url: '/testimonials',
    },
  ];

  function resetStore() {
    store.removeItem('template.case.studies');
    store.removeItem('template.customerTypeId');
    store.removeItem('template.testimonials');
    store.removeItem('template.our.products');
    store.removeItem('template.name');
    store.removeItem('template.customerTypeId');
    store.removeItem('template.studiesNew');
    store.removeItem('template.ourProductsNew');
    store.removeItem('template.testimonialsNew');
    store.removeItem('template.isEdit');
    store.removeItem('template.case-studies.canFetch');
    store.removeItem('template.our.products.canFetch');
    store.removeItem('template.testimonials.canFetch');
    store.removeItem('template.deletedIds');
    store.removeItem('template.templateId');
  }

  const { data, isLoading, error } = useGetOne(
    'template',
    { id },
    {
      onSuccess: (data) => {
        setIsEnabled(false);
      },
      enabled: isEnabled,
    }
  );

  useEffect(() => {
    async function fetchCustomerTypes() {
      const response = await axios('/customer/customer-types', {
        method: 'GET',
      });
      return response;
    }

    fetchCustomerTypes().then((response) => {
      setSelectOptions(response.data);
    });

    // return () => resetStore();
  }, []);

  const handleSubmit = async (data) => {
    try {
      const ourProducts = store.getItem('template.ourProductsNew');
      const testimonials = store.getItem('template.testimonialsNew');
      const caseStudies = store.getItem('template.studiesNew');
      const deletedIds = store.getItem('template.deletedIds');

      const mediaList = [];

      if (ourProducts) {
        ourProducts.forEach((element) => {
          mediaList.push({
            mediaItemId: element.mediaItemId || element.id,
            section: 1,
          });
        });
      }
      if (testimonials) {
        testimonials.forEach((element) => {
          mediaList.push({
            mediaItemId: element.mediaItemId || element.id,
            section: 2,
          });
        });
      }
      if (caseStudies) {
        caseStudies.forEach((element) => {
          mediaList.push({
            mediaItemId: element.mediaItemId || element.id,
            section: 3,
          });
        });
      }

      data.deleted = deletedIds || [];
      data.new = mediaList;

      update('template', { data }, { onError, onSuccess });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);
      console.log(err);
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = () => {
    resetStore();
    toast.success(
      translate('notifications.success'),
      translate('resources.template.template_updated')
    );

    navigate('/customers/template');
    refresh();
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.template.template')}
          handleClick={() => {
            resetStore();
            navigate('/customers/template');
          }}
        />
        <CustomButton
          variant='link'
          title={translate('resources.template.edit_template')}
        />
      </Breadcrumbs>
      <Typography variant='h5' sx={{ mb: '1.7rem', ml: 3 }}>
        {translate('resources.template.edit_template')}
      </Typography>
      <SimpleForm
        record={data}
        toolbar={
          <CustomToolbar
            handleClick={() => {
              resetStore();
              navigate('/customers/template');
            }}
            isEdit={true}
            sx={{ ml: 3 }}
          />
        }
        onSubmit={handleSubmit}
      >
        <TextInput source='name' sx={{ width: '60%', paddingLeft: 1 }} />
        <SelectInput
          source='customerTypeId'
          icon={<KeyboardArrowDownIcon />}
          sx={{ height: '40px', mt: 0, width: '250px', mb: 5, pl: 1 }}
          choices={selectOptions}
          optionText='name'
          optionValue='id'
        />
      </SimpleForm>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, mx: 3 }}>
        <TabRouter basePath={`/template/${id}`} routes={routes} />
      </Box>
      <Outlet />
    </>
  );
};

export default TemplateEdit;
