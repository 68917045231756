// in src/i18nProvider.js
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { en } from '../locales/en';
import { fr } from '../locales/fr';

const translations = { en, fr };

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'en', // default locale
  [
    { locale: 'en', name: 'English' },
    { locale: 'fr', name: 'Français' },
  ]
);
