import axios from '../plugins/axios';

export default async function check(email) {
  const response = await axios(
    '/customer/email-exist-checker',
    { params: { email: email } },
    {
      method: 'GET',
    }
  );
  return response;
}
