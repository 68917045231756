// library modules
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  SearchInput,
  Filter,
  TextField,
  DateField,
  useRecordContext,
  ResourceContextProvider,
  useTranslate,
  useRefresh,
  AutocompleteInput,
  FilterForm,
  FilterButton,
  useGetList,
} from 'react-admin';
import { ToastContext } from '../../context/toastContext';
import { UserContext } from '../../context/userContext';
import axios from '../../plugins/axios';
import { format } from 'date-fns';

// components
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import CustomButton from '../shared/CustomButton';
import ActionDropdown from '../shared/ActionDropdown';
import BatchDeleteButton from '../shared/BatchDeleteButton';
import UsernameWithImage from '../shared/UsernameWithImage';
import StatusChip from './StatusChip';
import UrlField from '../shared/UrlField';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';

// icons
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const UpdateSalesPersonDialog = ({ isOpen, close, record, salesList }) => {
  const [salesPersonId, setSalesPersonId] = useState(record.salesPersonId);

  const translate = useTranslate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);

  async function updateUser(data) {
    const response = await axios('/customer', {
      method: 'PUT',
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  const saveUser = async () => {
    record.salesPersonId = salesPersonId;

    try {
      await updateUser(record).then((response) => {
        close();
        refresh();
        toast.success(
          translate('notifications.success'),
          translate('resources.customer.sales_person_updated')
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => close()}>
      <DialogContent>
        <Typography variant='h6' sx={{ mb: 3 }}>
          {translate(`resources.customer.change_sales_person`)}
        </Typography>
        <Select
          value={salesPersonId || record.salesPersonId}
          onChange={(e) => {
            setSalesPersonId(e.target.value);
          }}
          icon={<KeyboardArrowDownIcon />}
          sx={{ height: '40px', mt: 0, width: '250px', mb: 5 }}
          choices={salesList}
        >
          {salesList.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => saveUser()}>Save</Button>
        <Button onClick={() => close()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const CustomersFilter = (props) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { user } = useContext(UserContext);
  const [salesTeamList, setSalesTeamList] = useState([]);

  const { data } = useGetList(
    'sales-person-list',
    {},
    {
      onSuccess: (data) => {
        const res = data.data;
        res.push({ name: 'admin' });

        setSalesTeamList(res);
      },
    }
  );

  const postFilters = [
    <SearchInput
      source='customersSearch'
      variant='outlined'
      alwaysOn
      resettable={true}
      sx={{ width: 350 }}
    />,
    <AutocompleteInput
      source='salesPerson'
      optionValue='name'
      variant='outlined'
      label={translate('common.sales_person')}
      resettable='true'
      choices={salesTeamList}
      sx={{ width: 250 }}
    />,
    <AutocompleteInput
      source='status'
      optionValue='name'
      variant='outlined'
      label={translate('resources.customer.status')}
      resettable='true'
      choices={[{ name: 'Active' }, { name: 'Inactive' }]}
      sx={{ width: 250 }}
    />,
  ];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '16px',
      }}
    >
      <FilterForm
        filters={postFilters}
        sx={{ '& .RaFilterFormInput-hideButton': { mb: 0 } }}
      />
      <FilterButton
        filters={postFilters}
        sx={{
          '& .MuiButtonBase-root': {
            minWidth: 130,
            marginRight: 4,
          },
        }}
      />
      {user?.roleName !== 'ContentAdmin' && (
        <CustomButton
          variant='secondary'
          title={translate('resources.customer.add_customer')}
          handleClick={() => navigate('/customer/create')}
          startIcon={<AddIcon />}
        />
      )}
    </div>
  );
};

const CustomersList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSalesDialogOpen, setIsSalesDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState();
  const [harwareStatus, setHarwareStatus] = useState();
  const [list, setList] = useState([]);
  const [customerRecord, setCustomerRecord] = useState({});
  const [customerUpdateData, setCustomerUpdateData] = useState({});
  const [salesPersonId, setSalesPersonId] = useState({});
  const translate = useTranslate();
  const navigate = useNavigate();
  const { toast } = useContext(ToastContext);
  const { user } = useContext(UserContext);

  const UpdateSalesPersonButton = ({ sx }) => {
    const record = useRecordContext();

    return (
      <IconButton
        aria-label='delete'
        onClick={async () => {
          await fetchSalesPersonList().then((response) => {
            const arr = response.data.items.map((item) => {
              return { id: item.id, name: item.name };
            });

            setCustomerRecord(record);
            setList(arr);
          });

          await getCustomerData(record.id).then((response) => {
            setSalesPersonId(response.data.salesPersonId);
            setCustomerUpdateData(response.data);
          });

          setIsSalesDialogOpen(true);
        }}
        sx={{
          ...sx,
          color: 'primary.main',
          ':hover ': {
            backgroundColor: 'transparent',
            opacity: 0.9,
          },
        }}
      >
        <EditIcon
          sx={{
            width: 18,
            height: 18,
          }}
        />
      </IconButton>
    );
  };

  const SalesPersonField = () => {
    const translate = useTranslate();

    return (
      <>
        <TextField
          source='salesPerson'
          label={translate('common.sales_person')}
        />
        <UpdateSalesPersonButton sx={{ ml: 0.3 }} />
      </>
    );
  };

  const DropdownMenu = () => {
    const record = useRecordContext();

    const openModal = () => {
      try {
        async function getFeedback() {
          const response = await axios(
            '/customer-feedback',
            { params: { customerId: record.id } },
            {
              method: 'GET',
            }
          );
          return response;
        }

        getFeedback().then((response) => {
          const lastElement = response.data[response.data.length - 1];
          setFeedback(lastElement);
        });

        async function getHardwareStatus() {
          const response = await axios(
            '/hardware-status',
            { params: { customerId: record.id } },
            {
              method: 'GET',
            }
          );
          return response;
        }

        getHardwareStatus().then((response) => {
          const lastElement = response.data[response.data.length - 1];
          setHarwareStatus(lastElement);
          setIsOpen(true);
        });
      } catch (err) {
        toast.error(translate('notifications.error'), err.message);
        console.log(err);
      }
    };

    const options = [
      <CustomButton
        variant='link'
        title={translate('common.edit').toLocaleUpperCase()}
        key={record.id + 'edit'}
        handleClick={() => {
          navigate(`/customer/${record.id}/company-information`);
        }}
        startIcon={<ModeEditIcon />}
        sx={{ color: 'primary.main', fontSize: '0.8125rem' }}
      />,

      <DeleteConfirmationDialog
        message={`${translate('resources.customer.customer')} ${
          record.companyName
        } ${translate('notifications.deleted')}`}
        title={`${translate('resources.customer.delete_customer')} ${
          record.companyName
        }?`}
        key={`${record.id} + delete`}
      />,
      <CustomButton
        key={record.id + 'feedback'}
        variant='link'
        title='FEEDBACK'
        handleClick={openModal}
        sx={{
          color: '#2A9D8F',
          fontSize: '0.8rem',
          margin: '0 auto',
          p: '0 8px',
        }}
      />,
    ];

    return <ActionDropdown options={options} />;
  };

  const Feedback = ({ isOpen, close }) => {
    const translate = useTranslate();

    return (
      <Dialog open={isOpen} onClose={close} sx={{ minHeight: '400px' }}>
        <DialogContent>
          <Typography variant='h6'>
            {translate(`resources.content.customer_feedback`)}
          </Typography>
          <>
            {feedback &&
              feedback.feedback.map((item, index) => {
                return (
                  <div key={index} style={{ marginTop: '1.5rem' }}>
                    <Typography variant='h7' sx={{ marginTop: '1rem' }}>
                      {translate(`common.question`)}: {item.question}
                    </Typography>
                    <div style={{ margin: '0.5rem 0' }}>
                      {translate(`common.answer`)}: {item.answer}
                    </div>
                  </div>
                );
              })}
            {feedback?.url && (
              <div style={{ margin: '0.5rem 0' }}>
                {translate(`common.table.url`)}:{' '}
                <a href={feedback.url} className='custom-link'>
                  {translate(`common.link`)}
                </a>
              </div>
            )}
            {!feedback && (
              <div style={{ margin: '0.5rem 0' }}>
                {translate(`resources.content.no_feedback_found`)}!
              </div>
            )}
          </>

          <Typography variant='h6' sx={{ my: '1.5rem' }}>
            {translate(`resources.content.hardware_status`)}
          </Typography>
          {harwareStatus && (
            <>
              <Typography variant='h7' sx={{ marginBottom: '2rem' }}>
                {translate(`resources.content.description`)}
              </Typography>

              <div style={{ margin: '0.5rem 0 1rem 0' }}>
                {harwareStatus.description}
              </div>
              <Typography variant='h7' sx={{ marginBottom: '2rem' }}>
                {translate(`common.table.url`)}
              </Typography>
              <br />

              <a
                href={harwareStatus.url}
                target='_blank'
                rel='noreferrer'
                style={{
                  margin: '0.5rem 0 1rem 0',
                  display: 'block',
                  color: '#2A9D8F',
                }}
              >
                {translate(`common.link`)}
              </a>

              <Typography variant='h7' sx={{ marginBottom: '2rem' }}>
                {translate(`resources.customer.creation_date`)}
              </Typography>

              <div style={{ margin: '0.5rem 0 1rem 0' }}>
                {format(new Date(harwareStatus.creationDate), 'dd/MM/yyyy')}
              </div>
            </>
          )}

          {!harwareStatus && (
            <div style={{ margin: '0.5rem 0 1rem 0' }}>
              {translate(`resources.content.no_hardware_found`)}!
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  async function fetchSalesPersonList() {
    const response = await axios('/admin-user/change-sales-person', {
      method: 'GET',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  async function getCustomerData(id) {
    const response = await axios(`/customer/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  return (
    <>
      <Typography variant='h5' sx={{ mb: '2.2rem' }}>
        {translate('resources.customer.list_of_customers')}
      </Typography>
      <ResourceContextProvider resource='customer'>
        <List
          empty={false}
          actions={null}
          filters={<CustomersFilter />}
          resource='customer'
          sort={{ field: 'id', order: 'DESC' }}
          sx={{
            '& .MuiToolbar-root': {
              mb: '2rem !important',
            },
          }}
        >
          <Datagrid
            bulkActionButtons={
              user?.roleName !== 'ContentAdmin' && (
                <BatchDeleteButton resource='media-item' sx={{ mt: '28px' }} />
              )
            }
          >
            <UsernameWithImage
              sortBy='companyName'
              sortByOrder='DESC'
              imageSrc='logoPath'
              name='companyName'
              label={translate('resources.customer.company_name')}
            />
            <DateField
              source='added'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <TextField
              source='contactPerson'
              label={translate('common.contact_person')}
            />
            <SalesPersonField
              label={translate('common.sales_person')}
              sortBy='salesPerson'
              sortByOrder='DESC'
            />

            <TextField source='country' label={translate('common.country')} />
            <TextField
              source='languageName'
              label={translate('common.language')}
              sortable={false}
            />
            <UrlField
              source='customerSpaceUrl'
              label={translate('common.customer_space_url')}
            />
            <UrlField
              source='wildFactoryUrl'
              label={translate('common.wild_factory_url')}
            />
            <StatusChip
              label={translate('common.status')}
              sortBy='status'
              sortByOrder='DESC'
            />
            {user?.roleName !== 'ContentAdmin' && (
              <DropdownMenu
                textAlign='center'
                label={translate('common.table.actions')}
              />
            )}
          </Datagrid>
        </List>
      </ResourceContextProvider>
      <Feedback
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
          setFeedback();
        }}
      />
      <UpdateSalesPersonDialog
        isOpen={isSalesDialogOpen}
        close={() => {
          setIsSalesDialogOpen(false);
        }}
        record={customerUpdateData}
        salesList={list}
      />
    </>
  );
};

export default CustomersList;
