// library modules
import { useContext, useEffect, useState } from 'react';
import { useTranslate, localStorageStore, useCreate } from 'react-admin';
import { CustomerContext } from '../../../context/customerContext';
import axios from '../../../plugins/axios';
import { debounce } from '../../../utils/debounce';

// components
import { Typography, TextField, Select, MenuItem } from '@mui/material';
import TreeTable from '../../shared/TreeTable';
import CustomButton from '../../shared/CustomButton';

import { removeFileFromArray } from '../../../utils/removeFileFromArray';
import { ToastContext } from '../../../context/toastContext';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const WildFactory = () => {
  const [categories, setCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchText, setSearchText] = useState();
  const [localAddress, setLocalAddress] = useState('http://192.168.1.114:3006');
  const [lengthChoices, setLengthChoices] = useState([
    { value: '0', name: 'null' },
    { value: '5', name: '5 min' },
    { value: '10', name: '10 min' },
    { value: '15', name: '15 min' },
  ]);

  const { customer } = useContext(CustomerContext);
  const { toast } = useContext(ToastContext);
  const [selectedLength, setSelectedLength] = useState('');

  const translate = useTranslate();
  const store = localStorageStore();
  const [create] = useCreate();

  async function getCategories() {
    const response = await axios(
      `category/tree-view-items`,
      {
        params: {
          customer: customer?.id,
          language: customer?.languageId,
          isWildFactory: true,
        },
      },
      {
        method: 'GET',
      }
    );

    return response;
  }

  useEffect(() => {
    setLocalAddress(customer?.localAddress);
  }, [customer?.localAddress]);

  useEffect(() => {
    setSelectedLength(
      customer?.wildFactoryLength === null
        ? '0'
        : customer?.wildFactoryLength.toString()
    );
  }, [customer?.wildFactoryLength]);

  useEffect(() => {
    getCategories().then((response) => {
      setCategories(response.data);
      setCategoryList(response.data);

      let selectedCategories = [];
      response.data.forEach((category) => {
        if (category.selected) selectedCategories.push({ id: category.id });
        if (category.items.length > 0) {
          category.items.forEach((item) => {
            if (item.selected) selectedCategories.push({ id: item.id });
          });
        }
      });
      store.setItem('categoryList', selectedCategories);
    });

    return () => {
      store.removeItem('categoryList');
    };
  }, []);

  function filterBy(arr, query) {
    return query
      ? arr.reduce((acc, item) => {
          if (item.items?.length) {
            const filtered = filterBy(item.items, query);
            if (filtered.length) return [...acc, { ...item, items: filtered }];
          }

          const { items, ...itemWithoutChildren } = item;
          return item.name?.toLowerCase().includes(query.toLowerCase())
            ? [...acc, itemWithoutChildren]
            : acc;
        }, [])
      : arr;
  }

  const onQueryChange = (e) => {
    setSearchText(e.target.value);
    setCategoryList(filterBy(categories, e.target.value));
  };

  const saveCategories = () => {
    let categoryIds = store.getItem('categoryList');

    let transformed = categoryIds.map((item) => {
      return item.id;
    });

    create(
      'wild-factory',
      {
        data: {
          customerId: customer.id,
          categoryIds: transformed,
          localAddress: localAddress,
          wildFactoryLength: selectedLength === 0 ? null : selectedLength,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(
            translate('notifications.success'),
            `${translate('resources.customer.categories_saved')} `
          );
        },
      }
    );
  };

  const updateTreeView = (record, checked) => {
    const categoryListFromStore = store.getItem('categoryList');

    const updateCategoriesState = (updatedCategories) => {
      setCategoryList(updatedCategories);
      setCategories(updatedCategories);
      store.setItem('categoryList', categoryListFromStore);
    };

    if (record.parentId === 0) {
      // Handling parent category
      record.items.forEach((item) => {
        if (checked) {
          if (
            !categoryListFromStore.some((element) => item.id === element.id)
          ) {
            categoryListFromStore.push({ id: item.id });
          }
        } else {
          removeFileFromArray(categoryListFromStore, item.id);
        }
      });

      const updatedCategories = categories.map((category) => {
        if (category.id === record.id) {
          const updatedItems = category.items.map((item) => ({
            ...item,
            selected: checked,
          }));
          return {
            ...category,
            selected: checked,
            items: updatedItems,
          };
        }
        return category;
      });

      updateCategoriesState(updatedCategories);
    } else {
      // Handling child category
      if (checked) {
        categoryListFromStore.push({ id: record.id });
      } else {
        removeFileFromArray(categoryListFromStore, record.id);
      }

      const updatedCategories = categories.map((category) => {
        if (category.id === record.parentId) {
          const updatedItems = category.items.map((item) => ({
            ...item,
            selected: item.id === record.id ? checked : item.selected,
          }));

          const allChildrenSelected = updatedItems.every(
            (item) => item.selected
          );

          return {
            ...category,
            selected: allChildrenSelected,
            items: updatedItems,
          };
        }
        return category;
      });

      updateCategoriesState(updatedCategories);
    }
  };

  return (
    <>
      <Typography variant='h5' sx={{ mb: '3rem' }}>
        {translate('common.list_of_categories')}
      </Typography>
      <TextField
        value={searchText}
        onChange={(e) => debounce(onQueryChange(e), 400)}
        id='category'
        label={translate('ra.action.search')}
        sx={{ mt: '0.5rem', width: '242px', mb: '1.5rem' }}
        size='small'
        InputProps={{
          endAdornment: searchText ? (
            <CloseOutlinedIcon
              sx={{ color: '#BDBDBD', cursor: 'pointer' }}
              onClick={() => {
                setSearchText('');
                setCategoryList(categories);
              }}
            />
          ) : (
            <SearchOutlinedIcon sx={{ color: '#BDBDBD' }} />
          ),
        }}
      />
      <span
        style={{
          marginBottom: '1rem',
          color: '#828282',
          fontSize: '0.875rem',
          display: 'block',
        }}
      >
        {translate('common.name_of_categories')}
      </span>
      <TreeTable
        data={categoryList}
        isWildFactory={true}
        sx={{ marginBottom: '1.5rem' }}
        handleClick={updateTreeView}
      />
      <div style={{ display: 'flex', gap: 50, marginBottom: 50 }}>
        <div>
          <Typography variant='h7' sx={{ mt: '1rem', display: 'block' }}>
            {translate('resources.customer.wild_factory_address')}
          </Typography>
          <br />
          <TextField
            value={localAddress}
            onChange={(e) => debounce(setLocalAddress(e.target.value), 400)}
            id='localAddress'
            // label={translate('resources.customer.local_ip_address')}
            sx={{ mb: '1rem', width: '242px' }}
            style={{ zIndex: 0 }}
            size='small'
          />
        </div>
        <div>
          <Typography variant='h7' sx={{ mt: '1rem', display: 'block' }}>
            {translate('common.video_length')}
          </Typography>
          <br />
          <Select
            value={selectedLength}
            onChange={(e) => {
              setSelectedLength(e.target.value);
            }}
            icon={<KeyboardArrowDownIcon />}
            sx={{ height: '40px', mt: 0, width: '100px', mb: 5 }}
          >
            {lengthChoices.map((item) => {
              return (
                <MenuItem key={item.name} value={item.value}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
      <div
        style={{
          overflow: 'hidden',
          backgroundColor: 'white',
          position: 'fixed',
          bottom: '0',
          width: '100%',
          padding: '1rem 0',
          zIndex: 99,
        }}
      >
        <CustomButton
          title={translate('buttons.save')}
          variant='secondary'
          style={{ marginLeft: 4, marginRight: 4 }}
          handleClick={saveCategories}
        />
      </div>
    </>
  );
};

export default WildFactory;
