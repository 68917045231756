// library modules
import { useContext, useState, useRef, useEffect } from 'react';
import { useCreate, useRefresh, useTranslate } from 'react-admin';

// utils
import { debounce } from '../../utils/debounce';
import axios from '../../plugins/axios';

// components
import { TextField, Stack, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CustomButton from '../shared/CustomButton';
import FormDialog from '../shared/FormDialog';
import SubCategoryListItem from './SubCategoryListItem';

// icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ToastContext } from '../../context/toastContext';
import UploadIcon from '../shared/UploadIcon';

export default function AddCategoryModal({
  modalOpen,
  setModalOpen,
  isFactory = false,
  refresh,
  languageId,
}) {
  const [inputs, setInputs] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [categoryFile, setCategoryFile] = useState();
  const [previewCategoryFile, setPreviewCategoryFile] = useState();
  const [create] = useCreate();
  // const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const translate = useTranslate();

  const hiddenCategoryFileInput = useRef(null);

  useEffect(() => {
    if (!categoryFile) {
      setPreviewCategoryFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(categoryFile);
    setPreviewCategoryFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [categoryFile]);

  const handleCategoryImageClick = (event) => {
    hiddenCategoryFileInput.current.click();
  };

  const handleUploadCategoryImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCategoryFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setCategoryFile(fileUploaded);
  };

  const updateInputs = (file, id) => {
    const transformed = inputs.map((input) => {
      if (input.id === id) input.image = file;
      return input;
    });
    setInputs(transformed);
  };

  const getHighestLevel = () => {
    let highestLevel = 0;
    for (const input of inputs) {
      if (input.level > highestLevel) {
        highestLevel = input.level;
      }
    }
    return highestLevel;
  };

  const addCategory = async () => {
    let categoryImageResponse = '';

    if (categoryFile) {
      try {
        const imageFormData = new FormData();
        imageFormData.append('file', categoryFile);

        categoryImageResponse = await axios.post(
          '/category/upload-image',
          imageFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      } catch (err) {
        console.log(err);
      }
    }

    create(
      'categories',
      {
        data: {
          name: categoryName,
          parentId: 0,
          languageId: languageId,
          iconName: categoryImageResponse.data,
          isFactory: isFactory,
        },
      },
      {
        onSuccess: (res) => {
          const highestLevel = getHighestLevel();
          if (highestLevel > 0) {
            recursiveCreate(res.id, 1, highestLevel);
          } else {
            refresh();
            setCategoryName('');
            setModalOpen(false);
            setTimeout(() => setInputs([]), 250);
            toast.success(
              translate('notifications.success'),
              `${translate(
                'resources.content.category'
              )} ${categoryName} ${translate('notifications.created')}`
            );
          }
        },
      }
    );
  };

  const uploadSubCategoryImage = async (image) => {
    try {
      const imageFormData = new FormData();
      imageFormData.append('file', image);

      const response = await axios.post(
        '/category/upload-image',
        imageFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

  function recursiveCreate(parentId, level, highestLevel) {
    let subCategoryImageResponse = '';
    const filtered = inputs.filter((input) => input.level === level);

    filtered.forEach(async (input, i) => {
      if (input.image)
        subCategoryImageResponse = await uploadSubCategoryImage(input.image);

      create(
        'categories',
        {
          data: {
            name: input.value,
            parentId,
            languageId: languageId,
            iconName: subCategoryImageResponse,
            isFactory: isFactory,
          },
        },
        {
          onSuccess: (res) => {
            recursiveCreate(res.id, level + 1, highestLevel);
            if (level === highestLevel && i === filtered.length - 1) {
              setCategoryName('');
              setModalOpen(false);
              setTimeout(() => setInputs([]), 250);
            }
            refresh();
          },
        }
      );
    });
  }

  const iconButtonSx = {
    border: '1px solid',
    borderColor: 'primary.main',
    borderRadius: 100,
    color: 'primary.main',
    width: '2rem',
    height: '2rem',
    ':hover': {
      backgroundColor: 'primary.main',
      color: 'primary.light',
    },
  };

  return (
    <FormDialog
      title='Add category'
      isOpen={modalOpen}
      handleClose={() => {
        setModalOpen(false);
        setTimeout(() => setInputs([]), 250);
      }}
      handleConfirm={addCategory}
      isDisabled={!categoryName || inputs.find((i) => !i.value)}
    >
      <Box sx={{ mb: '2rem', color: 'neutral.light' }}>
        Choose the name for the category
      </Box>
      <TextField
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        id='category'
        label='Category'
        sx={{ mt: '0.5rem', width: '100%' }}
        InputProps={{
          endAdornment: (
            <CustomButton
              title={'+ Add Subcategory'}
              variant='secondary-light'
              handleClick={() =>
                setInputs((prevInputs) => [
                  ...prevInputs,
                  {
                    id: prevInputs.length + 1,
                    parentId: 0,
                    level: 1,
                    value: '',
                    iconName: '',
                    image: null,
                  },
                ])
              }
              sx={{
                whiteSpace: 'nowrap',
                minWidth: 0,
                width: '15.5rem',
              }}
            />
          ),
        }}
      />
      <Typography variant='h6' sx={{ fontSize: '1rem', my: 3 }}>
        {translate('common.category_image')}
      </Typography>
      <div style={{ display: 'flex', marginBottom: 38 }}>
        <UploadIcon />
        <CustomButton
          title={translate('common.click_to_upload')}
          variant='ghost'
          style={{ marginLeft: 4, marginRight: 4 }}
          handleClick={handleCategoryImageClick}
        />
        <input
          type='file'
          accept='image/*'
          ref={hiddenCategoryFileInput}
          onChange={handleUploadCategoryImageChange}
          style={{ display: 'none' }}
        />
        {categoryFile && (
          <img
            src={previewCategoryFile}
            alt={translate('common.profile_image')}
            style={{ height: '6.3rem' }}
          />
        )}
      </div>
      <Stack sx={{ mt: 2 }}>
        {inputs.map(({ id, parentId, level, value }, i) => (
          <>
            <Stack
              direction='row'
              key={id}
              sx={{
                pt: level === 1 ? 3 : 0,
                borderTop: level === 1 ? '1px solid lightgray' : 0,
                mt: level === 1 ? 1 : 0,
                mb: 2,
                ml: level * 4,
              }}
            >
              <TextField
                label='Subcategory'
                value={value}
                onChange={(e) =>
                  debounce(
                    setInputs((prevInputs) => {
                      return [
                        ...prevInputs.slice(0, i),
                        {
                          id,
                          parentId,
                          level,
                          value: e.target.value,
                          iconName: '',
                          image: null,
                        },
                        ...prevInputs.slice(i + 1),
                      ];
                    }),
                    300
                  )
                }
                sx={{ flexGrow: 1 }}
              />
              <Stack direction='row' alignItems='center' gap={2} ml={2.5}>
                <IconButton
                  aria-label='delete'
                  onClick={() =>
                    setInputs((prevInputs) =>
                      prevInputs.filter((input) => input.id !== id)
                    )
                  }
                  sx={iconButtonSx}
                >
                  <RemoveIcon />
                </IconButton>
                <IconButton
                  aria-label='add'
                  onClick={() =>
                    setInputs((prevInputs) => {
                      return [
                        ...prevInputs.slice(0, i + 1),
                        {
                          id: prevInputs.length + 1,
                          parentId: id,
                          level: level + 1,
                          value: '',
                          iconName: '',
                        },
                        ...prevInputs.slice(i + 1),
                      ];
                    })
                  }
                  sx={iconButtonSx}
                >
                  <AddIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Typography variant='h6' sx={{ fontSize: '1rem', my: 3, ml: 4 }}>
              {translate('common.category_image')}
            </Typography>
            <SubCategoryListItem
              inputs={inputs}
              id={id}
              updateInputs={updateInputs}
            />
          </>
        ))}
      </Stack>
    </FormDialog>
  );
}
