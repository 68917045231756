import frenchMessages from 'ra-language-french';

export const fr = {
  ...frenchMessages,
  common: {
    table: {
      yes: 'Oui',
      no: 'Non',
      added_to: 'Ajouté à',
      see: 'Voir',
      more: 'Plus...',
      name: 'Nom',
      length: 'Longueur',
      download_url: 'URL de téléchargement',
      stream_url: 'URL de diffusion',
      url: 'URL',
      public: 'Publique',
      default: 'Défaut',
      name_of_item: `Nom de l'élément`,
      date_added: 'Date ajoutée',
      private_public: 'Privé/Publique',
      media_item_type: `Type d'élément média`,
      user_name: `Nom d'utilisateur`,
      role: 'Rôle',
      password: 'Mot de passe',
      email: 'Email',
      category: 'Catégorie',
      file_version: 'Version de fichier',
      actions: 'Actions',
      action: 'Action',
      alternative_name: 'Nom alternatif',
      type: 'Type',
    },
    token_added: 'Jeton enregistré!',
    token: 'Jeton',
    testimonials: 'témoignages',
    copy_from: 'Copie de SdF',
    items_added: 'Articles ajoutés',
    cover_position: 'Emplacement du couvercle',
    encoding_started: `L'encodage a commencé`,
    enable_download: 'Activer le téléchargement',
    disable_download: 'Désactiver le téléchargement',
    re_encode_video: 'Ré-encoder la vidéo',
    video_length: 'Durée de la vidéo',
    name_of_categories: 'Nom des catégories',
    list_of_categories: 'Liste des catégories',
    wild_factory: 'Wild factory',
    category_image: 'Photo de catégorie',
    password_validation:
      'Le mot de passe doit contenir au moins 1 chiffre, caractère spécial, lettre minuscule et majuscule, longueur minimum 8 caractères',
    enter_old_password: `Entrez l'ancien mot de passe`,
    enter_new_password: 'Entrez un nouveau mot de passe',
    repeat_new_password: 'Répété le nouveau mot de passe',
    password_mismatch: 'Le mot de passe ne correspond pas',
    change_password: 'Changer le mot de passe',
    email_is_not_valid: `l'Email est invalide`,
    singnature_date: 'Date de signature',
    end_date: 'Date de fin',
    updated: 'mis à jour',
    question: 'Question',
    answer: 'Réponse',
    edit: 'Editer',
    add_language: 'Ajouter une langue',
    no_item_found: 'Aucun élément trouvé',
    link: 'Lien',
    add_raw: 'Ajouter un fichier RAW',
    add: 'AJOUTER',
    add_lovercase: 'Ajouter',
    country: 'Pays',
    language: 'Langue',
    choose_country: 'Sélectionner un pays',
    profile_image: 'Image de profil',
    customer_logo: 'Logo du client',
    cover_image: 'Image de couverture',
    click_to_upload: 'Cliquer pour charger',
    status: 'Statut',
    purchased: 'Acheté',
    invoices: 'Factures',
    contracts: 'Contrats',
    our_products: 'Movie selection',
    discover_wild_immersion: 'Sélection de films',
    my_content: 'Mon contenu',
    case_studies: 'Présentations',
    testimonials: 'Témoignages',
    chosen_products: 'Mes Contenus',
    contact_person: 'Contact',
    sales_person: 'Contact vente',
    choose_from_catalogue: 'Choisissez dans le catalogue',
    add_files_from_bunny: 'Ajouter des fichiers de Bunny',
    // customer_space_url: `URL d'espace client`,
    customer_space_url: `CS url`,
    wild_factory_url: `WF url`,
    add_item_to: `Ajouter l'élément à`,
    choose_items_to_add: 'Sélectionner les éléments à ajouter',
    customer_name: 'Nom du client',
    upload_file: 'Charger le fichier',
    login: 'Connexion',
    unauthorized: 'Non autorisé',
    application_packages: 'Packages Applications',
  },
  notifications: {
    file_is_required: 'Le champ est obligatoire',
    image_is_required: 'Image nécessaire',
    error: 'Erreur',
    success: 'Succès',
    created: 'créé',
    edited: 'édité',
    deleted: 'effacé',
    document_created: 'Document créé',
    document_edited: 'Document édité',
    document_deleted: 'Document effacé',
    check_media_item_name_error_msg: `L'éléments média existe déjà. Veuillez modifier le titre`,
    items_deleted: 'Eléments effacés',
    change_customer_email: 'Ce Email client existe déjà. Veuillez en changer',
  },
  buttons: {
    save: 'Sauvegarder',
    reset_password: 'Réinitialiser le mot de passe',
    delete: 'Effacer',
    delete_file: 'Effacer le fichier',
    copy_link: 'Copier le lien',
    discard: 'Annuler',
    save_changes: 'Enregistrer les modifications',
    auto_generate_password: 'Mot de passe généré',
    copy_url_address: `Copier l'adresse URL`,
    add_product: 'Ajouter un produit',
    add_testimonial: 'Ajouter un témoignage',
    add_to_customer_space: `Ajouter à l'espace client`,
    add_file_item: 'Ajouter un élément de fichier',
  },
  general_settings: {
    user: 'Utilisateur',
    add_user: 'Ajouter un utilisateur',
    edit_user: `Editer l'utilisateur`,
    list_of_users: 'Liste des utilisateurs',
    main_settings: 'Paramètres généraux',
    add_user_name: `Ajouter un nom d'utilisateur`,
    add_user_email: 'Ajouter un Email utilisateur',
    user_name: `Nom de l'utilisateur`,
    user_email: `Email de l'utilisateur`,
    user_role: `Rôle de l'utilisateur`,
    user_password: `Mot de passe de l'utilisateur`,
    type_of_admin: `Type d'admin`,
    role: 'Rôle',
    delete_user: `Effacer l'utilisateur`,
    type_password_or_aut_generate: `Saisir ou générer un mot de passe`,
    required: 'Nécessaire',
  },
  resources: {
    testimonials: {
      list_of_testimonials: 'Liste des témoignages',
    },
    content: {
      win_store_link: 'Lien Windows Store',
      play_store_link: 'Lien Google Play',
      app_store_link: 'Lien Apple Store',
      internet_connection_required: 'Internet requis',
      user_age: "Age recommandé de l'utilisateur",
      app_size: "Taille de l'application",
      hardware_specs: "Spécificités de l'équipement",
      preferred_browser: 'Navigateur privilégié',
      os_to_run_on: "Système d'exploitation requis",
      devices_to_run_on: 'Exécuter sur les appareils',
      application_specifications: "Spécificités de l'application",
      delete_raw_footage: 'Effacer le fichier RAW',
      file_item_thumbnail: `Miniature de l'élément`,
      customer_feedback: 'Evaluations des clients',
      hardware_status: 'Etat du matériel',
      no_feedback_found: 'Aucune évaluation trouvée',
      no_hardware_found: `Il n'y a pas de problème matériel`,
      edit_application_package: 'Editer le paquet applicatif',
      edit_movie_package: 'Editer le paquet du film',
      edit_media_item: `Editer l'élément média`,
      create_raw_footage: 'Créer un fichier RAW',
      edit_raw_footage: 'Editer le fichier RAW',
      list_of_deleted_files: 'Liste des fichiers effacés',
      list_of_rawfootage: 'Liste de fichiers RAW',
      media_catalogue: 'Catalogue de médias',
      raw_footage: 'Fichier RAW',
      deleted_files: 'Fichiers effacés',
      media_item: 'Eléments média',
      add_to_testimonials: 'Ajouter aux témoignages',
      add_to_case_studies: 'Ajouter aux présentations',
      public_or_private: 'Publique ou privé',
      delete_media_item: `Effacer l'élément média`,
      list_of_media_items: 'Liste des éléments média',
      available_languages: 'Langues disponibles',
      add_movie_package: 'Ajouter un paquet vidéo',
      movie_package: 'Paquet vidéo',
      add_media_item: 'Ajouter un élément média',
      content: 'Contenu',
      description: 'Description',
      title: 'Titre',
      alternative_title: 'Titre alternatif',
      type_of_file: 'Type de fichier',
      animals_included: 'Animaux inclus',
      showcased_animals: 'Animaux présentés',
      publish_to_media_catalogue: 'Publier dans le catalogue média',
      mark_as_new: 'Marquer comme nouveau',
      file_version: 'Version de fichier',
      label_of_file: 'Etiquette du fichier',
      full_view: 'Vue complète',
      downloadable: 'Téléchargeable',
      new: 'Nouveau',
      category: 'Catégorie',
      categories: 'Catégories',
      subcategory: 'Sous catégories',
      add_categories: 'Ajouter des catégories',
      file_item: 'Elément de fichier',
      file_name: 'Nom de fichier',
      url: 'URL',
      media_type_id: 'ID du type de média',
      media_type: 'Type de média',
      media_item_cover_image: `Couverture de l'élément média`,
      summary: 'Résumé',
      add_application_package: 'Ajouter un paquet applicatif',
      application_package: 'Paquet applicatif',
      application_packages: 'Dossiers de candidature',
      name_of_item: `Nom de l'élément`,
      file_size: 'Taille de fichier',
      media_type_name: 'Nom du type de média',
      media_item_must: `L'élément média doit comporter au moins 1 fichier média`,
    },
    customer: {
      active_user: 'Client actif',
      wild_factory_address: 'Wild Factory adresse IP locale',
      local_ip_address: 'Adresse IP locale',
      categories_saved: 'Catégories enregistrées',
      activate_wild_factory: `Activer l'usine sauvage`,
      sales_person_updated: 'Vendeur informé',
      change_sales_person: 'Changer de vendeur',
      issue: 'Problème',
      creation_date: 'Date de création',
      contact_email: 'Email de contact',
      items_added_to: 'éléments ajoutés à',
      email_exists: `L'Email exite déjà`,
      list_of_customers: 'Liste des clients',
      url_adress: 'Adresse URL',
      save_customer: 'Enregistrer le client',
      customers_informations: 'Informations client',
      add_company_name: 'Ajouter un nom de société',
      add_company_email: 'Ajouter un Email de société',
      create_user_log_in_credentials:
        'Créer des identifiants de connexion utilisateur',
      edit_user_log_in_credentials:
        'Modifier les identifiants de connexion de utilisateur',
      type_of_customer: 'Type de client',
      customer_space: 'Espace client',
      customers: 'Clients',
      edit_customer: 'Editer les clients',
      add_customer: 'Ajouter un client',
      add_new_customer: 'Ajouter un nouveau client',
      company_informations: 'Informations de la société',
      add_case_study: 'Ajouter une présentation',
      company_name: 'Nom de la société',
      delete_customer: 'Effacer le client',
      customer: 'Client',
      showtime_licence: 'Licence Showtime',
      additional_email: 'Email additionnel',
      enable_notification: 'Activers les notifications',
      notification_enabled: 'Notifications activées',
      status: `Statut de l'utilisateur`,
    },
    template: {
      list_of_templates: 'Liste des modèles',
      apply_template: 'Appliquer le modèle',
      templates: 'Modèles',
      template: 'Modèle',
      add_template: 'Ajouter un modèle',
      delete_template: 'Effacer le modèle',
      edit_template: 'Editer le modèle',
      template_updated: 'Modèle mis à jour',
      template_deleted: 'Modèle effacé',
    },
    case_studies: {
      list_of_case_studies: 'Liste des études de cas',
    },
    contracts: {
      delete_contract: 'Effacer le contrat',
      list_of_contracts: 'Liste des contrats',
      list_of_invoices: 'Liste des factures',
      delete_invoice: 'Effacer la facture',
      invoice: 'Facture',
      contract: 'Contrat',
      add_invoice: 'Ajouter une facture',
      add_contract: 'Ajouter un contrat',
      emission_date: `Date d'émission`,
      start_date: 'Date de départ',
      signature_date: 'Date de signature',
      end_date: 'Date de fin',
    },
  },
};
