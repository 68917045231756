// library modules
import { useState, useContext } from 'react';
import { useCreate, useTranslate, useGetOne } from 'react-admin';
import { debounce } from '../../utils/debounce';
import { ToastContext } from '../../context/toastContext';

// components
import { Stack, Typography, TextField } from '@mui/material';
import { Box } from '@mui/system';
import CustomButton from '../shared/CustomButton';

export default function TestemonialsList() {
  const translate = useTranslate();
  const [token, setToken] = useState({});
  const [create] = useCreate();
  const { toast } = useContext(ToastContext);

  const { data } = useGetOne(
    'token',
    {},
    {
      onSuccess: (data) => {
        setToken(data);
      },
      // enabled: isEnabled,
    }
  );

  const saveToken = () => {
    create(
      'token',
      {
        data: token,
      },
      {
        onSuccess: (res) => {
          toast.success(
            translate('notifications.success'),
            `${translate('commo.token_added')} `
          );
        },
      }
    );
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant='h5' sx={{ mb: '3.6rem' }}>
        {translate('common.token')}
      </Typography>
      <br />
      <TextField
        value={token.token}
        onChange={(e) =>
          debounce(setToken({ ...token, token: e.target.value }), 400)
        }
        id='token'
        // label={}
        sx={{ mb: '1rem', width: '242px' }}
        style={{ zIndex: 0 }}
        size='small'
      />
      <br />
      <CustomButton
        title={translate('buttons.save')}
        variant='secondary'
        style={{ marginLeft: 4, marginRight: 4 }}
        handleClick={saveToken}
      />
    </Box>
  );
}
