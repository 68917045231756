import React from 'react';
import Button from '@mui/material/Button';

const CustomButton = (props) => {
  return (
    <Button
      variant={props.variant}
      color={props.color}
      onClick={props.handleClick}
      type={props.type ? props.type : 'button'}
      disabled={props.disabled ? true : false}
      sx={{
        ...props.sx,
        background: props.disabled ? 'rgba(0, 0, 0, 0.12)' : null,
        border: props.disabled ? 'transparent' : null,
      }}
      startIcon={props.startIcon}
    >
      {props.title}
    </Button>
  );
};

export default CustomButton;
