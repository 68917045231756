// library modules
import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';

// components
import { Menu } from '@mui/material';
import Box from '@mui/material/Box';
import CustomButton from './CustomButton';

// icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';

// constants
const ITEM_HEIGHT = 45;

export default function ButtonDropdown() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const translate = useTranslate();
  const navigate = useNavigate();

  const options = [
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <AddIcon sx={{ mr: 1 }} />
      <CustomButton
        variant='link'
        key={'create-media-item'}
        title={translate('resources.content.media_item')}
        handleClick={() => navigate('/content/create-media-item')}
      />
    </Box>,
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <AddIcon sx={{ mr: 1 }} />
      <CustomButton
        variant='link'
        key={'create-media-package'}
        title={translate('resources.content.movie_package')}
        handleClick={() => navigate('/content/create-media-package')}
      />
    </Box>,
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AddIcon sx={{ mr: 1 }} />
      <CustomButton
        variant='link'
        key={'create-app-package'}
        title={translate('resources.content.application_package')}
        handleClick={() => navigate('/content/create-app-package')}
      />
    </Box>,
  ];

  return (
    <>
      <CustomButton
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              px: '0.25rem',
            }}
          >
            {translate('common.add')}
            <ArrowDropDownIcon fontSize='small' />
          </Box>
        }
        variant='secondary'
        sx={{
          width: '7.5rem',
          height: '2.5rem',
          minWidth: 0,
          position: 'absolute',
          top: 3,
          right: 24,
        }}
        handleClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        id='long-menu'
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            padding: 12,
          },
        }}
      >
        {options.map((option, index) => {
          if (option) {
            return <div key={index + option}>{option}</div>;
          }

          return null;
        })}
      </Menu>
    </>
  );
}
