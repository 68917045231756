// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import {
  DateInput,
  TextInput,
  useUpdate,
  useTranslate,
  useRefresh,
  localStorageStore,
  SimpleForm,
  Edit,
} from 'react-admin';
import axios from '../../../plugins/axios';
import { ToastContext } from '../../../context/toastContext';
import { useNavigate } from 'react-router-dom';
import { InitialDataContext } from '../../../context/initialDataContext';

// components
import { Box, Stack, Typography, Breadcrumbs } from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import UploadIcon from '../../shared/UploadIcon';
import CustomToolbar from '../../shared/CustomToolbar';
import UrlField from '../../shared/UrlField';

export default function EditCustomerDocument({ name }) {
  // state
  const [profileFile, setProfileFile] = useState();
  const [previewProfileFile, setPreviewProfileFile] = useState();

  // hooks
  const [update] = useUpdate();
  const navigate = useNavigate();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const initialData = useContext(InitialDataContext);

  // refs
  const hiddenProfileFileInput = useRef(null);

  // store
  const store = localStorageStore();
  const documentTypeIdInvoice = initialData.invoiceId;
  const documentTypeIdContract = initialData.contractId;
  const customerId = store.getItem('userId');

  useEffect(() => {
    if (!profileFile) {
      setPreviewProfileFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(profileFile);
    setPreviewProfileFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [profileFile]);

  const handleProfileImageClick = (event) => {
    hiddenProfileFileInput.current.click();
  };

  const handleUploadProfileImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      profileFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setProfileFile(fileUploaded);
  };

  const handleSubmit = async (data) => {
    let url = '';
    if (name === 'invoice') {
      url = '/customer-document/upload-invoice';
    } else {
      url = '/customer-document/upload-contract';
    }

    try {
      if (profileFile) {
        const imageFormData = new FormData();
        imageFormData.append('file', profileFile);

        const profileImageResponse = await axios.post(url, imageFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        data.url = profileImageResponse.data;
        data.urlOriginal = profileImageResponse.data;
      }

      data.documentTypeId =
        name === 'invoice' ? documentTypeIdInvoice : documentTypeIdContract;
      data.customerId = customerId;

      update('customer-document', { data }, { onError, onSuccess });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);
      console.log(err);
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = () => {
    toast.success(
      translate('notifications.success'),
      translate('notifications.document_edited')
    );

    navigate(-1);
    refresh();
  };

  return (
    <Box sx={{ p: '1.5rem', width: '100%' }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.customer.customers')}
          handleClick={() => navigate(-1)}
        />
        <CustomButton
          variant='link'
          title={`${translate('common.edit')} ${name}`}
        />
      </Breadcrumbs>
      <Typography variant='h5' sx={{ mb: '1rem' }}>
        {translate('common.edit')} {name}
      </Typography>
      <Edit
        resource='customer-document'
        className='raw-create'
        sx={{
          width: '100%',
          '& .MuiPaper-root': {
            boxShadow: 'unset',
          },
          '& .MuiCardContent-root': {
            p: 0,
          },
        }}
      >
        <SimpleForm
          toolbar={
            <CustomToolbar handleClick={() => navigate(-1)} isEdit={true} />
          }
          onSubmit={handleSubmit}
        >
          <Stack sx={{ pt: 1, width: '100%' }}>
            <TextInput source='name' sx={{ width: '60%' }} />
            <DateInput source='signatureDate' sx={{ width: '217px' }} />
            {name === 'contract' && (
              <DateInput
                source='endDate'
                label={translate('resources.contracts.end_date')}
                sx={{ width: '217px' }}
              />
            )}
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
              {translate('common.upload_file')}
            </Typography>

            <div style={{ display: 'flex', marginBottom: 38 }}>
              <UploadIcon />
              <CustomButton
                title={translate('common.click_to_upload')}
                variant='ghost'
                style={{ marginLeft: 4, marginRight: 4 }}
                handleClick={handleProfileImageClick}
              />
              <input
                type='file'
                // accept='image/*'
                ref={hiddenProfileFileInput}
                onChange={handleUploadProfileImageChange}
                style={{ display: 'none' }}
              />
              {profileFile ? (
                <div
                  style={{
                    fontSize: '0.8rem',
                    border: '1px solid #D7D7D7',
                    padding: '12px',
                    color: '#264653',
                  }}
                >
                  <div>
                    {translate('resources.content.file_name')}:{' '}
                    {profileFile.name}
                  </div>
                  <div>
                    {translate('resources.content.file_size')}:{' '}
                    {profileFile.size}
                  </div>
                </div>
              ) : (
                <div style={{ marginTop: 8 }}>
                  {translate('common.table.url')}: <UrlField />
                </div>
              )}
            </div>
          </Stack>
        </SimpleForm>
      </Edit>
    </Box>
  );
}
