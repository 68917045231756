// library modules
import { useContext } from 'react';
import { useTranslate, localStorageStore } from 'react-admin';
import { Outlet, useParams } from 'react-router-dom';
import { CustomerContext } from '../../../context/customerContext';

// components
import { Box } from '@mui/material';
import TabRouter from '../../shared/TabRouter';

const CustomersSpaceEdit = () => {
  const translate = useTranslate();
  const store = localStorageStore();
  const params = useParams();
  const { customer } = useContext(CustomerContext);

  const ACTIVE_ROUTES = [
    {
      name: translate('common.discover_wild_immersion'),
      url: '/our-products',
    },
    {
      name: translate('common.case_studies'),
      url: '/case-studies',
    },
    {
      name: translate('resources.content.application_packages'),
      url: '/application-packages',
    },
    // {
    //   name: translate('common.testimonials'),
    //   url: '/testimonials',
    // },
    {
      name: translate('common.my_content'),
      url: '/chosen-products',
    },
    {
      name: translate('common.invoices'),
      url: '/invoices',
    },
    {
      name: translate('common.contracts'),
      url: '/contracts',
    },
  ];

  const FACTORY_ACTIVE_ROUTES = [
    {
      name: translate('common.discover_wild_immersion'),
      url: '/our-products',
    },
    {
      name: translate('resources.content.application_packages'),
      url: '/application-packages',
    },
    {
      name: translate('common.case_studies'),
      url: '/case-studies',
    },
    // {
    //   name: translate('common.testimonials'),
    //   url: '/testimonials',
    // },
    {
      name: translate('common.my_content'),
      url: '/chosen-products',
    },
    {
      name: translate('common.invoices'),
      url: '/invoices',
    },
    {
      name: translate('common.contracts'),
      url: '/contracts',
    },
    {
      name: translate('common.wild_factory'),
      url: '/wild-factory',
    },
  ];

  const INACTIVE_ROUTES = [
    {
      name: translate('common.discover_wild_immersion'),
      url: '/our-products',
    },
    {
      name: translate('resources.content.application_packages'),
      url: '/application-packages',
    },
    {
      name: translate('common.case_studies'),
      url: '/case-studies',
    },
    // {
    //   name: translate('common.testimonials'),
    //   url: '/testimonials',
    // },
  ];

  const routes =
    customer?.status === 'Active'
      ? customer.hasWildFactory
        ? FACTORY_ACTIVE_ROUTES
        : ACTIVE_ROUTES
      : INACTIVE_ROUTES;

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <TabRouter
          basePath={`/customer/${params.id}/customer-space`}
          routes={routes}
        />
      </Box>
      <Outlet />
    </Box>
  );
};

export default CustomersSpaceEdit;
