import axios from '../plugins/axios';
import config from '../config';
import { createQueryParams, cleanResource } from './helpers';
import { localStorageStore } from 'react-admin';
const baseUrl = config.apiBaseUrl;

const dataProvider = async (type, resourceType, params) => {
  let url = '';
  let resource = cleanResource(resourceType);
  const store = localStorageStore();

  const options = {
    config: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  };

  switch (type) {
    case 'GET_LIST':
      url = `${baseUrl}/${resource}/get-filtered-list`;
      options.data = createQueryParams(params);
      options.method = 'POST';

      if (resource === 'media-item') {
        url = `${baseUrl}/${resource}/get-media-items-with-files`;
        if (store.getItem('customerId')) {
          url += `?customer=${store.getItem('customerId')}`;
        }
      }

      if (resource === 'media-item-filtered')
        url = `${baseUrl}/media-item/get-media-items-with-files`;

      if (resource === 'media-item-deleted')
        url = `${baseUrl}/media-item/get-archived-file-items`;

      if (resource === 'media-item-raw')
        url = `${baseUrl}/media-item/get-raw-filtered-list`;

      if (
        resource === 'media-item-list' ||
        resource === 'single-media-item-list'
      ) {
        url = `${baseUrl}/media-item/get-media-items-with-files`;
      }

      if (resource === 'purchased-file-item') {
        url += '/' + params.filter.customerId;
      }

      if (resource === 'category') {
        url = `${baseUrl}/${resource}/tree-view-items`;
        options.method = 'GET';
      }

      if (resource === 'factory-categories') {
        url = `${baseUrl}/category/tree-view-items`;
        options.method = 'GET';
      }

      if (resource === 'testimonial' || resource === 'case-studies') {
        url = `${baseUrl}/selected-file-item/get-filtered-list`;
      }

      if (resource === 'admin-user-roles') {
        url = `${baseUrl}/admin-user/roles`;
        options.method = 'GET';
      }

      if (resource === 'sales-person-list') {
        url = `${baseUrl}/admin-user/change-sales-person`;
        options.method = 'GET';
      }

      break;

    case 'GET_ONE':
      url = `${baseUrl}/${resource}/${params.id}`;
      options.method = 'GET';

      // if (resource === 'media-item')
      //   url = `${baseUrl}/${resource}/${params.id}/null`;

      if (resource === 'translatable-ids')
        url = `${baseUrl}/media-item/${params.id}/translatable-ids`;

      if (resource === 'media-item-raw')
        url = `${baseUrl}/media-item/${params.id}/raw`;
      if (resource === 'token') url = `${baseUrl}/content-token`;
      break;

    case 'CREATE':
      url = `${baseUrl}/${resource}`;
      options.data = params.data;
      options.method = 'POST';

      if (resource === 'customer') url = `${url}/register-customer`;
      if (resource === 'media-item-raw') url = `${baseUrl}/media-item/raw`;
      if (resource === 'token') url = `${baseUrl}/content-token/or-update`;
      if (resource === 'wild-factory')
        url = `${baseUrl}/customer/customer-category`;
      break;

    case 'UPDATE':
      url = `${baseUrl}/${resource}/${params.id}`;
      options.data = params.data;
      options.method = 'PUT';
      if (resource === 'customer-space-url') {
        options.method = 'POST';
        url = `${baseUrl}/customer/customer-space-url`;
      }

      if (
        resource === 'customer-document' ||
        resource === 'media-item' ||
        resource === 'template'
      )
        url = `${baseUrl}/${resource}`;

      if (resource === 'media-item-list') url = `${baseUrl}/media-item/raw`;
      if (resource === 'media-item-raw') url = `${baseUrl}/media-item/raw`;
      if (resource === 'category') url = `${baseUrl}/category`;

      if (resource === 'admin-user' || resource === 'customer')
        url = `${baseUrl}/${resource}`;
      break;

    case 'DELETE':
      url = `${baseUrl}/${resource}/${params.id}`;
      options.method = 'DELETE';

      if (resource === 'selected-file-item') {
        // options.data = {
        //   customerId: params.previousData.customerId,
        //   fileId: params.id,
        // };
        options.data = [params.id];
        url = `${baseUrl}/${resource}/batch-delete/${params.previousData.customerId}`;
      }
      if (resource === 'purchased-file-item') {
        url = `${baseUrl}/${resource}/batch-delete/${
          params.previousData.customerId || store.getItem('customerId')
        }`;
        options.data = [params.id];
      }

      if (resource === 'media-item-raw')
        url = `${baseUrl}/media-item/${params.id}/raw`;

      if (resource === 'media-item-list')
        url = `${baseUrl}/media-item/${params.id}`;
      break;

    case 'DELETE_MANY':
      const ids = params.ids.map((id) => 'ids=' + id).join('&');
      url = `${baseUrl}/${resource}/batch-delete?${ids}`;
      options.method = 'DELETE';

      if (resource === 'media-item-list')
        url = `${baseUrl}/media-item/batch-delete?${ids}`;
      break;

    default:
      throw new Error(`Unsupported Data Provider type ${type}`);
  }

  const response = await axios({ url, ...options });
  if (response.status === 200 || response.status === 204) {
    switch (type) {
      case 'GET_LIST':
        if (resource === 'category') {
          return {
            data: response.data,
            total: response.data.length,
          };
        }
        if (resource === 'admin-users-roles') {
          return {
            data: response.data,
            total: response.data.length,
          };
        }
        return {
          data: response.data?.items,
          total: response.data?.totalCount,
        };

      case 'GET_ONE':
      case 'CREATE':
      case 'UPDATE':
        return {
          data: response.data ? response.data : { id: params.id },
        };
      case 'DELETE':
        return { data: true };
      case 'DELETE_MANY':
        return { data: [] };

      default:
        throw new Error(`Unsupported Data Provider type ${type}`);
    }
  }

  return false;
};

export default dataProvider;
