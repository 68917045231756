// library modules
import { useEffect, useRef, useState } from 'react';

// icons
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

// components
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { CreateButton, DeleteButton, EditButton } from 'react-admin';
import AddSubcategoryModal from './AddSubcategoryModal';
import EditCategoryModal from './EditCategoryModal';
import DeleteCategoryModal from './DeleteCategoryModal';

import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function OptionsMenu({
  record,
  isFactory = false,
  refresh,
  languageId,
}) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <AddSubcategoryModal
        record={record}
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
        isFactory={isFactory}
        refresh={refresh}
        languageId={languageId}
      />
      <EditCategoryModal
        record={record}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        isFactory={isFactory}
        refresh={refresh}
        languageId={languageId}
      />
      <DeleteCategoryModal
        record={record}
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        refresh={refresh}
      />
      <Stack
        direction='row'
        spacing={2}
        justifyContent='end'
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <Button
            ref={anchorRef}
            id='composition-button'
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
            sx={{
              background: open ? '#2A9D8F' : null,
              minWidth: 0,
              width: '2rem',
              padding: 0,
              height: '100%',
              borderRadius: 0,
              ':hover': { background: '#2A9D8F' },
            }}
          >
            <MoreVertIcon
              sx={{
                color: open ? 'white' : '#0000008A',
                ':hover': { color: 'white' },
              }}
            />
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement='bottom-end'
            transition
            disablePortal
            sx={{ zIndex: 10 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-end' ? 'right top' : 'right bottom',
                }}
              >
                <Paper
                  sx={{
                    boxShadow:
                      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                    '& .MuiTreeView-root': { overflowY: 'unset !important' },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id='composition-menu'
                      aria-labelledby='composition-button'
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          setEditModalOpen(true);
                        }}
                      >
                        <EditButton
                          record={record}
                          label='Edit'
                          sx={{ pointerEvents: 'none' }}
                          icon={<EditIcon sx={{ opacity: '0.5' }} />}
                        />
                      </MenuItem>
                      {languageId === '1' && (
                        <>
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              setAddModalOpen(true);
                            }}
                          >
                            <CreateButton
                              label='Add Subcategory'
                              sx={{ pointerEvents: 'none', color: 'black' }}
                              icon={<AddIcon sx={{ opacity: '0.5' }} />}
                            />
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpen(false);
                              setDeleteModalOpen(true);
                            }}
                          >
                            <DeleteButton
                              record={record}
                              label='Delete'
                              sx={{ pointerEvents: 'none' }}
                            />
                          </MenuItem>
                        </>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
    </>
  );
}
