// library modules
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Datagrid,
  SearchInput,
  TextField,
  useList,
  DateField,
  useRecordContext,
  ResourceContextProvider,
  useTranslate,
  AutocompleteInput,
  ListBase,
  FilterButton,
  FilterForm,
  Pagination,
  ListContextProvider,
  localStorageStore,
} from 'react-admin';
import { InitialDataContext } from '../../context/initialDataContext';

// components
import { Typography, Stack } from '@mui/material';
import ActionDropdown from '../shared/ActionDropdown';
import CustomButton from '../shared/CustomButton';
import BatchDeleteButton from '../shared/BatchDeleteButton';
import UsernameWithImage from '../shared/UsernameWithImage';
import CategoryField from '../shared/CategoryField';
import UrlField from '../shared/UrlField';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';

// icons
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const DropdownMenu = () => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const translate = useTranslate();
  const store = localStorageStore();

  const setResource = (id) => {
    switch (id) {
      case 1:
        return '/content/edit-media-item';
      case 2:
        return '/content/edit-movie-package';
      case 3:
        return '/content/edit-application-package';
      default:
        return;
    }
  };

  const options = [
    <CustomButton
      variant='link'
      title={translate('common.edit').toLocaleUpperCase()}
      handleClick={() => {
        store.setItem('mediaItemId', record.id);
        navigate(setResource(record.mediaItemTypeId));
      }}
      startIcon={<ModeEditIcon />}
      sx={{ color: 'primary.main', fontSize: '0.8125rem' }}
    />,
    <DeleteConfirmationDialog
      message={`${translate('resources.content.media_item')} ${
        record.name
      } ${translate('notifications.deleted')}`}
      title={`${translate('resources.content.delete_media_item')} ${
        record.name
      }?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const ListToolbar = () => {
  const translate = useTranslate();
  const initialData = useContext(InitialDataContext);

  const postFilters = [
    <SearchInput
      source='mediaItemListSearch'
      variant='outlined'
      alwaysOn
      resettable={true}
      sx={{ width: 350 }}
    />,
    <AutocompleteInput
      source='publicOrPrivate'
      optionValue='name'
      variant='outlined'
      label={translate('resources.content.public_or_private')}
      resettable='true'
      choices={[{ name: 'Public' }, { name: 'Private' }]}
      sx={{ width: 250 }}
    />,
    <AutocompleteInput
      source='mediaItemTypeId'
      optionValue='id'
      variant='outlined'
      label={translate('common.table.media_item_type')}
      resettable='true'
      choices={[
        { id: '1', name: 'Single' },
        { id: '2', name: 'MoviePackage' },
        { id: '3', name: 'ApplicationPackage' },
      ]}
      sx={{ width: 250 }}
    />,
  ];

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{ mb: '3.2rem', mt: '-16px' }}
    >
      <FilterForm
        filters={postFilters}
        sx={{ '& .RaFilterFormInput-hideButton': { mb: 0 } }}
      />
      <FilterButton filters={postFilters} sx={{ width: 130 }} />
    </Stack>
  );
};

const ExpandPanel = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const listContext = useList({ data: record?.fileItems });

  return (
    <div className='media-catalog'>
      <ResourceContextProvider></ResourceContextProvider>
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          className='media-catalog'
          sx={{
            pb: 5,
          }}
        >
          <TextField source='name' label={translate('common.table.name')} />
          <TextField source='length' label={translate('common.table.length')} />

          <UrlField
            source={'downloadUrl'}
            label={translate('common.table.download_url')}
          />
        </Datagrid>
      </ListContextProvider>
    </div>
  );
};

const MediaCatalog = () => {
  const translate = useTranslate();

  return (
    <>
      <Typography variant='h5' sx={{ mb: '3rem' }}>
        {translate('resources.content.list_of_media_items')}
      </Typography>
      <ResourceContextProvider resource='media-item-list'>
        <ListBase
          actions={null}
          resource='media-item-list'
          sort={{ field: 'id', order: 'DESC' }}
          filter={{
            languageId: '1',
          }}
          sx={{
            '& .MuiToolbar-root': { mb: '2rem !important' },
            '& .MuiTablePagination-root': {
              position: 'absolute',
              bottom: 0,
              right: 0,
              mr: '1rem',
            },
          }}
        >
          <ListToolbar />
          <Datagrid
            sx={{
              boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            }}
            expand={<ExpandPanel />}
            bulkActionButtons={<BatchDeleteButton />}
          >
            <UsernameWithImage
              label={translate('resources.content.name_of_item')}
            />
            <DateField
              source='dateAdded'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <CategoryField label={translate('common.table.category')} />
            <TextField
              source='publicOrPrivate'
              label={translate('common.table.private_public')}
            />
            <TextField
              sortable={false}
              source='mediaItemType'
              label={translate('common.table.media_item_type')}
            />
            <DropdownMenu
              label={translate('common.table.actions')}
              textAlign='center'
            />
          </Datagrid>
          <Pagination />
        </ListBase>
      </ResourceContextProvider>
    </>
  );
};

export default MediaCatalog;
