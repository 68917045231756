// components
import { TreeItem, TreeView } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import OptionsMenu from '../categories/OptionsMenu';
import CustomCheckbox from './CustomCheckbox';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function StyledTreeItem({
  languageId,
  label,
  record,
  isWildFactory,
  isFactory = false,
  data,
  handleClick,
  refresh,
  ...other
}) {
  return (
    <TreeItem
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography color={record.parentId !== 0 ? 'gray' : null}>
            {label}
          </Typography>
          {isWildFactory ? (
            <CustomCheckbox record={record} handleClick={handleClick} />
          ) : (
            <OptionsMenu
              record={record}
              isFactory={isFactory}
              refresh={refresh}
              languageId={languageId}
            />
          )}
        </Box>
      }
      {...other}
    />
  );
}

export default function TreeTable({
  data,
  isWildFactory = false,
  isFactory = false,
  sx,
  handleClick,
  refresh,
  languageId,
}) {
  if (!data) return null;

  const renderTree = (node, treePath) => {
    node.treePath = treePath;
    if (node.items?.length > 0) {
      return (
        <StyledTreeItem
          languageId={languageId}
          key={node.id}
          nodeId={node.id.toString()}
          label={node.name}
          record={node}
          isWildFactory={isWildFactory}
          isFactory={isFactory}
          refresh={refresh}
          data={data}
          handleClick={handleClick}
        >
          {Array.isArray(node.items)
            ? node.items.map((node) =>
                renderTree(node, treePath + `/ ${node.name}`)
              )
            : null}
        </StyledTreeItem>
      );
    }
    return (
      <StyledTreeItem
        languageId={languageId}
        key={node.id}
        nodeId={node.id.toString()}
        label={node.name}
        record={node}
        isWildFactory={isWildFactory}
        data={data}
        handleClick={handleClick}
        isFactory={isFactory}
        refresh={refresh}
      />
    );
  };

  return (
    <TreeView
      aria-label='rich object'
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{
        ...sx,
        height: '100%',
        flexGrow: 1,
        maxWidth: '100%',
        // overflowY: 'auto',
      }}
    >
      {data.map((node) => renderTree(node, node.name))}
    </TreeView>
  );
}
