import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function CustomCheckbox({ record, data, handleClick }) {
  const handleChange = (event) => {
    handleClick(record, event.target.checked);
  };

  return (
    <Checkbox
      sx={{ p: 0.5 }}
      checked={record.selected}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}
