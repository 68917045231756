import { useState, useEffect } from 'react';
import { Admin, Resource, CustomRoutes, localStorageStore } from 'react-admin';
import { Route, BrowserRouter } from 'react-router-dom';
import authProvider from './auth/authProvider.js';
import customDataProvider from './dataProvider/customDataProvider';
import { ThemeProvider, Box, CircularProgress } from '@mui/material';
import { i18nProvider } from './i18nProvider';
import { theme } from './components/layout/themes';
import axios from './plugins/axios';
import { InitialDataContext } from './context/initialDataContext';
import { UserContext } from './context/userContext';
import { CustomerContext } from './context/customerContext';
import { SpinnerContext } from './context/spinnerContext';

import { Layout } from './components/layout/index.js';
import Customers from './components/customers';
import CustomersCreate from './components/customers/create/CustomersCreate';
import CustomersEdit from './components/customers/create/CustomersEdit';
import MediaCatalog from './components/content/MediaCatalog';
import { settings } from './components/settings/index';
import { token } from './components/token/index';
import { caseStudies } from './components/case-studies/index.js';
import Contracts from './components/contracts/Contracts';
import DocumentContracts from './components/contracts/ContractsList';
import DocumentInvoice from './components/contracts/InvoicesList';
import AddProducts from './components/customers/customer-space/AddProducts';
import AddCustomerDocument from './components/customers/customer-space/AddCustomerDocument.js';
import TemplateCreate from './components/customers/create/TemplateCreate';
import TemplateEdit from './components/customers/edit/TemplateEdit';
import Content from './components/content/Content';
import MediaItemCreate from './components/content/MediaItemCreate';
import EditContent from './components/content/EditContent';
import EditApplication from './components/content/EditApplication';
import MediaPackageCreate from './components/content/MediaPackageCreate';
import EditPackage from './components/content/EditPackage';
import ApplicationPackageCreate from './components/content/ApplicationPackageCreate';
import RawCreate from './components/content/RawCreate';
import RawEdit from './components/content/RawEdit';
import EditCustomerDocument from './components/customers/edit/EditCustomerDocument';
import AddTemplateProducts from './components/customers/create/AddTemplateProducts';
import TestemonialsList from './components/testemonials/TestemonialsList';
import RawFootageList from './components/content/RawFootageList';
import FileArchiveList from './components/content/FileArchiveList';
import CategoriesList from './components/categories/CategoriesList';
import CustomerList from './components/customers/CustomersList';
import TemplateList from './components/customers/TemplateList';
import Redirect from './components/shared/Redirect';
import TemplateOurProductsList from './components/customers/create/TemplateOurProductsList';
import TemplateCaseStudiesList from './components/customers/create/TemplateCaseStudiesList';
import TemplateTestimonialsList from './components/customers/create/TemplateTestimonialsList';
import TemplateOutlet from './components/customers/customer-space/TemplateOutlet';
import CompanyInformationsEdit from './components/customers/create/CompanyInformationsEdit';
import CustomerSpaceEdit from './components/customers/edit/CustomerSpaceEdit';
import OurProductsList from './components/customers/customer-space/OurProductsList';
import CaseStudiesList from './components/customers/customer-space/CaseStudiesList';
import TestimonialsList from './components/customers/customer-space/TestimonialsList';
import PurchasedList from './components/customers/customer-space/PurchasedList';
import InvoicesList from './components/customers/customer-space/Invoices';
import ContractsList from './components/customers/customer-space/Contracts';
import WildFactory from './components/customers/customer-space/WildFactory';
import StyledToastContainer from './components/shared/Toast/StyledToastContainer.js';
import Unauthorized from './components/auth/Unauthorized.jsx';
import ApplicationPackagesList from './components/customers/customer-space/ApplicationPackagesList.js';

const App = () => {
  const [isAdminReady, setIsAdminReady] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const [initialData, setInitialData] = useState();
  const [user, setUser] = useState();
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const store = localStorageStore();
  store.setItem('sidebar.open', true);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (window.location.pathname === '/unauthorized') {
          setIsAppReady(true);
          return;
        }

        const documentTypes = await axios({
          url: '/customer-document/document-types',
        });

        const languages = await axios('/customer/languages', { method: 'GET' });

        let defaultLanguage;
        let languageListToString = [];

        languages.data.items.forEach((lang) => {
          languageListToString.push({
            id: lang.id.toString(),
            name: lang.name,
          });
          if (lang.name === 'en-US') {
            defaultLanguage = lang.id.toString();
          }
        });

        setInitialData({
          defaultLanguage: defaultLanguage,
          languageList: languageListToString,
          invoiceId: documentTypes.data[0].id.toString(),
          contractId: documentTypes.data[1].id.toString(),
        });

        setIsAppReady(true);
      } catch (e) {
        console.log(e);
      }
    };
    if (isAdminReady && !isAppReady) {
      loadData();
    }
  }, [isAdminReady, isAppReady, initialData]);

  const ReadyScreen = () => {
    useEffect(() => {
      setIsAdminReady(true);
    }, []);
    return <></>;
  };

  function CircularIndeterminate() {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fff',
          zIndex: '999',
          opacity: 0.6,
        }}
      >
        <CircularProgress sx={{ mx: 'auto' }} />
      </Box>
    );
  }

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <InitialDataContext.Provider value={initialData}>
            <UserContext.Provider value={{ user, setUser }}>
              <CustomerContext.Provider value={{ customer, setCustomer }}>
                <SpinnerContext.Provider value={{ isLoading, setIsLoading }}>
                  {isLoading && <CircularIndeterminate />}
                  <StyledToastContainer />
                  <Admin
                    dataProvider={customDataProvider}
                    authProvider={authProvider}
                    i18nProvider={i18nProvider}
                    loginPage={false}
                    layout={Layout}
                    title='Wild Immersion'
                    theme={theme}
                    store={store}
                    requireAuth
                    ready={ReadyScreen}
                    disableTelemetry
                  >
                    {isAppReady && (
                      <>
                        <CustomRoutes noLayout>
                          <Route
                            path='/unauthorized'
                            element={<Unauthorized />}
                          />
                        </CustomRoutes>
                        <CustomRoutes>
                          <Route exact path='/' element={<Redirect />} />
                          <Route path='/raw-create' element={<RawCreate />} />
                          {/* CONTENT START */}
                          <Route path='/content' element={<Content />}>
                            <Route
                              path='/content/media-catalog'
                              element={<MediaCatalog />}
                            />
                            <Route
                              path='/content/categories'
                              element={<CategoriesList />}
                            />
                            <Route
                              path='/content/raw'
                              element={<RawFootageList />}
                            />

                            <Route
                              path='/content/deleted-files'
                              element={<FileArchiveList />}
                            />
                          </Route>
                          <Route
                            path='/content/create-media-item'
                            element={<MediaItemCreate />}
                          />
                          <Route
                            path='/content/edit-media-item'
                            element={<EditContent />}
                          />
                          <Route
                            path='/content/create-media-package'
                            element={<MediaPackageCreate />}
                          />
                          <Route
                            path='/content/edit-movie-package'
                            element={<EditPackage />}
                          />
                          <Route
                            path='/content/create-app-package'
                            element={<ApplicationPackageCreate />}
                          />
                          <Route
                            path='/content/edit-application-package'
                            element={<EditApplication />}
                          />
                          <Route
                            path='/media-item-raw/:id' // TODO: set diferent path
                            element={<RawEdit />}
                          />
                          {/* CONTENT ENDS */}

                          <Route
                            path='/add-our-products'
                            element={
                              <AddProducts
                                name={'discover_wild_immersion'}
                                section={1}
                                createUrl={
                                  'selected-file-item/batch-create-media-item'
                                }
                              />
                            }
                          />
                          <Route
                            path='/add-testimonials'
                            element={
                              <AddProducts
                                name={'testimonials'}
                                section={2}
                                createUrl={
                                  'selected-file-item/batch-create-media-item'
                                }
                              />
                            }
                          />
                          <Route
                            path='/add-case-studies'
                            element={
                              <AddProducts
                                name={'case_studies'}
                                section={3}
                                createUrl={
                                  'selected-file-item/batch-create-media-item'
                                }
                              />
                            }
                          />
                          <Route
                            path='/add-application-packages'
                            element={
                              <AddProducts
                                name={'application_packages'}
                                section={5}
                                createUrl={
                                  'selected-file-item/batch-create-media-item'
                                }
                              />
                            }
                          />
                          <Route
                            path='/add-purchased'
                            element={
                              <AddProducts
                                name={'my_content'}
                                createUrl={'purchased-file-item/batch-create'}
                              />
                            }
                          />
                          <Route
                            path='/add-invoices'
                            element={
                              <AddCustomerDocument
                                name={'invoice'}
                                documentTypeId={1}
                              />
                            }
                          />
                          <Route
                            path='/add-contracts'
                            element={
                              <AddCustomerDocument
                                name={'contract'}
                                documentTypeId={1}
                              />
                            }
                          />
                          <Route
                            path='/edit-invoice/:id'
                            element={
                              <EditCustomerDocument
                                name={'invoice'}
                                documentTypeId={1}
                              />
                            }
                          />

                          <Route
                            path='/edit-contract/:id'
                            element={
                              <EditCustomerDocument
                                name={'contract'}
                                documentTypeId={1}
                              />
                            }
                          />
                          {/* CUSTOMERS START */}
                          <Route path='/customers' element={<Customers />}>
                            <Route
                              path='/customers/list'
                              element={<CustomerList />}
                            />
                            <Route
                              path='/customers/template'
                              element={<TemplateList />}
                            />
                          </Route>
                          <Route
                            path='/customer/create'
                            element={<CustomersCreate />}
                          />
                          <Route
                            path='/customer/:id'
                            element={<CustomersEdit />}
                          >
                            <Route
                              path='company-information'
                              element={<CompanyInformationsEdit />}
                            />
                            <Route
                              path='customer-space'
                              element={<CustomerSpaceEdit />}
                            >
                              <Route
                                path='chosen-products'
                                element={<PurchasedList />}
                              />
                              <Route
                                path='application-packages'
                                element={<ApplicationPackagesList />}
                              />
                              <Route
                                path='wild-factory'
                                element={<WildFactory />}
                              />
                              <Route
                                path='invoices'
                                element={<InvoicesList />}
                              />
                              <Route
                                path='contracts'
                                element={<ContractsList />}
                              />
                              <Route
                                path='our-products'
                                element={<OurProductsList />}
                              />
                              <Route
                                path='case-studies'
                                element={<CaseStudiesList />}
                              />
                              <Route
                                path='testimonials'
                                element={<TestimonialsList />}
                              />
                            </Route>
                          </Route>
                          {/* CUSTOMERS END */}

                          {/* TEMPLATE START */}

                          <Route
                            path='/template'
                            element={<TemplateOutlet />} // TODO: set diferent path
                          >
                            <Route
                              path='/template/create'
                              element={<TemplateCreate />} // TODO: set diferent path
                            >
                              <Route
                                path='/template/create/our-products'
                                element={<TemplateOurProductsList />} // TODO: set diferent path
                              />
                              <Route
                                path='/template/create/case-studies'
                                element={<TemplateCaseStudiesList />} // TODO: set diferent path
                              />
                              <Route
                                path='/template/create/testimonials'
                                element={<TemplateTestimonialsList />} // TODO: set diferent path
                              />
                            </Route>
                            <Route
                              path='/template/add-our-products'
                              element={
                                <AddTemplateProducts
                                  name={'discover_wild_immersion'}
                                  section={1}
                                />
                              }
                            />
                            <Route
                              path='/template/add-testimonials'
                              element={
                                <AddTemplateProducts
                                  name={'testemonials'}
                                  section={2}
                                />
                              }
                            />
                            <Route
                              path='/template/add-case-studies'
                              element={
                                <AddTemplateProducts
                                  name={'case_studies'}
                                  section={3}
                                />
                              }
                            />
                            <Route
                              path='/template/:id'
                              element={<TemplateEdit />}
                            >
                              <Route
                                path='/template/:id/our-products'
                                element={<TemplateOurProductsList />}
                              />
                              <Route
                                path='/template/:id/case-studies'
                                element={<TemplateCaseStudiesList />}
                              />
                              <Route
                                path='/template/:id/testimonials'
                                element={<TemplateTestimonialsList />}
                              />
                            </Route>
                          </Route>
                          {/* TEMPLATE END */}

                          {/* CONTRACTS & INVOICES START */}
                          <Route path='/documents' element={<Contracts />}>
                            <Route
                              path='/documents/contracts'
                              element={<DocumentContracts />}
                            />
                            <Route
                              path='/documents/invoices'
                              element={<DocumentInvoice />}
                            />
                          </Route>
                          {/* CONTRACTS & INVOICES END */}

                          {/* TESTIMONIALS START */}
                          <Route
                            path='/testimonials'
                            element={<TestemonialsList />}
                          />
                          {/* TESTIMONIALS END */}
                          <Route
                            path='/wild-factory'
                            element={
                              <Box sx={{ p: 3 }}>
                                <CategoriesList isFactory={true} />
                              </Box>
                            }
                          />
                        </CustomRoutes>
                        <Resource
                          name='case-studies'
                          options={{ label: 'Case studies' }}
                          {...caseStudies}
                        />
                        <Resource
                          name='admin-user'
                          options={{ label: 'Main Settings' }}
                          {...settings}
                        />
                        <Resource
                          name='token'
                          options={{ label: 'Token' }}
                          {...token}
                        />
                      </>
                    )}
                  </Admin>
                </SpinnerContext.Provider>
              </CustomerContext.Provider>
            </UserContext.Provider>
          </InitialDataContext.Provider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
