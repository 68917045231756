const apiDomain = process.env.REACT_APP_API_ENDPOINT;
const clientRoot = process.env.REACT_APP_BASE_URL;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

const config = {
  apiDomain,
  authBaseUrl: apiDomain + '/api',
  apiBaseUrl: apiDomain + '/api/app',
  clientRoot: clientRoot,
  clientSecret: clientSecret,
  clientId: 'WildImmersion_API_App',
  scope: 'openid profile email address phone roles',
};

export default config;
