import { useRef } from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import CustomButton from './CustomButton';
import CloseIcon from '@mui/icons-material/Close';

const PromptDialog = ({
  isOpen,
  title = '',
  handleClose,
  handleConfirm,
  closeButtonLabel,
  confirmButtonLabel,
  content,
  children,
}) => {
  const nodeRef = useRef(null);
  return (
    <Dialog
      open={isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      ref={nodeRef}
      onClose={handleClose}
      maxWidth={false}
    >
      <DialogTitle
        id='alert-dialog-title'
        sx={{ padding: 0, marginBottom: '2.5rem' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {title}
          <button
            style={{
              backgroundColor: 'transparent',
              padding: '0',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent sx={{ padding: 0, marginBottom: '2.5rem' }}>
        {content}
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <CustomButton
          title={closeButtonLabel}
          variant="primary"
          //   sx={{ ml: 4 }}
          handleClick={handleClose}
        />
        <CustomButton
          title={confirmButtonLabel}
          variant="error"
          sx={{ ml: 4 }}
          handleClick={handleConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;
