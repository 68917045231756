// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  useTranslate,
  AutocompleteArrayInput,
  useRefresh,
  useList,
  Datagrid,
  ListContextProvider,
  TextField,
  useRecordContext,
  useUpdate,
  useGetOne,
  DateField,
  SearchInput,
  AutocompleteInput,
  FilterForm,
  List,
  localStorageStore,
  ResourceContextProvider,
} from 'react-admin';
import axios from '../../plugins/axios';
import { ToastContext } from '../../context/toastContext';
import { theme } from '../layout/themes';

// utils
import { removeFileFromArray } from '../../utils/removeFileFromArray';
import uploadThumb from '../../utils/uploadFileItemThumb';
import { SpinnerContext } from '../../context/spinnerContext';
import { InitialDataContext } from '../../context/initialDataContext';

// components
import {
  Typography,
  MenuItem,
  TextField as MuiTextField,
  Autocomplete,
  DialogActions,
  Dialog,
  DialogContent,
  Stack,
  Button,
} from '@mui/material';
import CustomToolbar from '../shared/CustomToolbar';
import CustomButton from '../shared/CustomButton';
import UploadIcon from '../shared/UploadIcon';
import UsernameWithImage from '../shared/UsernameWithImage';
import { RichTextInput } from 'ra-input-rich-text';
import CategoryField from '../shared/CategoryField';
import UrlField from '../shared/UrlField';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ListToolbar = () => {
  const translate = useTranslate();
  const initialData = useContext(InitialDataContext);

  const postFilters = [
    <SearchInput
      source='mediaItemListSearch'
      variant='outlined'
      alwaysOn
      resettable={true}
      sx={{ width: 250 }}
    />,
    <AutocompleteInput
      source='languageId'
      optionValue='id'
      variant='outlined'
      label={translate('common.language')}
      choices={initialData.languageList}
      sx={{ width: 250 }}
      alwaysOn
    />,
  ];

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{ mb: '3rem', mt: '-1px' }}
    >
      <FilterForm
        filters={postFilters}
        sx={{ '& .RaFilterFormInput-hideButton': { mb: 0 } }}
      />
    </Stack>
  );
};

const ExpandPanel = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const listContext = useList({ data: record?.fileItems });

  return (
    <div className='media-catalog'>
      <ResourceContextProvider></ResourceContextProvider>
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          className='media-catalog'
          sx={{
            pb: 5,
          }}
        >
          <TextField source='name' label={translate('common.table.name')} />
          <TextField source='length' label={translate('common.table.length')} />

          <UrlField
            source={'downloadUrl'}
            label={translate('common.table.download_url')}
          />
        </Datagrid>
      </ListContextProvider>
    </div>
  );
};

function ImportModal({ isOpen, setModalOpen, close, save }) {
  // state

  // hooks
  const translate = useTranslate();
  const store = localStorageStore();

  const handleSave = () => {
    const selectedIds = store.getItem('single-media-item-list.selectedIds');
    save(selectedIds);
    close();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={close}
        maxWidth={'xl'}
        sx={{ minHeight: '400px' }}
      >
        <DialogContent>
          <Typography variant='h5' sx={{ mb: '3rem' }}>
            {translate('resources.content.list_of_media_items')}
          </Typography>
          <List
            actions={null}
            resource='single-media-item-list'
            filters={<ListToolbar />}
            filter={{ MediaItemTypeId: '1' }}
            filterDefaultValues={{ languageId: '1' }}
            sx={{
              '& .MuiToolbar-root': { mb: '2rem !important' },
              '& .MuiTablePagination-root': {
                mr: '1rem',
              },
            }}
          >
            <Datagrid
              sx={{
                boxShadow:
                  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                '& .RaDatagrid-headerCell': {
                  position: 'unset',
                },
                '& .RaBulkActionsToolbar-topToolbar': {
                  display: 'none',
                },
                '& .MuiToolbar-root': {
                  top: '-4px',
                },
              }}
              expand={<ExpandPanel />}
            >
              <UsernameWithImage
                label={translate('resources.content.name_of_item')}
              />
              <DateField
                source='dateAdded'
                locales='de-DE'
                label={translate('common.table.date_added')}
              />
              <CategoryField label={translate('common.table.category')} />
            </Datagrid>
          </List>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => handleSave()}>
            {translate('ra.action.save')}
          </Button>
          <Button onClick={close}>{translate('ra.action.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const ApplicationPackageEdit = ({ langId, id }) => {
  // state
  const [coverFile, setCoverFile] = useState();
  const [previewCoverFile, setPreviewCoverFile] = useState();
  const [fileItemThumbnailFile, setFileItemThumbnailFile] = useState();
  const [previewFileItemThumbnailFile, setPreviewFileItemThumbnailFile] =
    useState();
  const [fileItemsList, setFileItemsList] = useState([]);
  const [selectTypeOfFileOptions, setSelectTypeOfFileOptions] = useState([]);
  const [selectCategoryOptions, setSelectCategoryOptions] = useState([]);
  const [fileItemName, setFileItemName] = useState();
  const [fileItemUrl, setFileItemUrl] = useState();
  const [fileItemMediaType, setFileItemMediaType] = useState();
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [autoCompleteDevicesValue, setAutoCompleteDevicesValue] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const [langError, setLangError] = useState(false);
  const [fileItemListErrorMsg, setFileItemListErrorMsg] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // hooks
  const translate = useTranslate();
  const navigate = useNavigate();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const listContext = useList({ data: fileItemsList });
  const { isLoading, setIsLoading } = useContext(SpinnerContext);
  const store = localStorageStore();

  // refs
  const hiddenCoverFileInput = useRef(null);
  const hiddenMediaFileThumbnailInput = useRef(null);

  const DeleteButton = () => {
    const record = useRecordContext();

    return (
      <CustomButton
        label={translate('common.table.actions')}
        variant='link'
        title={translate('buttons.delete')}
        handleClick={() => deleteFile(record)}
        sx={{ color: '#E74C3C', minWidth: '20px' }}
      />
    );
  };

  const { data } = useGetOne(
    'media-item',
    { id },
    {
      onSuccess: (data) => {
        setFileItemsList(data.fileItems);
        setAutoCompleteValue(data.languagesIncluded);
        setAutoCompleteDevicesValue(data.specification?.devicesToRunOn || []);

        data.categories = data.categories.map((item) => {
          return item.id.toString();
        });

        data.fileName = data.fileItems[0].name;
        data.url = data.fileItems[0].url;
        data.mediaTypeId = data.fileItems[0].mediaTypeId.toString();

        setIsEnabled(false);
      },
      enabled: isEnabled,
    }
  );

  useEffect(() => {
    async function fetchTypeOfFileData() {
      const response = await axios('/file-item/media-types', { method: 'GET' });
      return response;
    }

    fetchTypeOfFileData().then((response) =>
      setSelectTypeOfFileOptions(response.data.items)
    );

    async function fetchCategoryData() {
      const response = await axios('/category/get-filtered-list', {
        method: 'POST',
        data: {
          maxResultCount: 1000,
          sorting: 'name ASC',
          filterRule: {
            condition: 'and',
            filterRules: [
              {
                field: 'IsFactory',
                operator: 'equals',
                value: 'false',
              },
              {
                field: 'LanguageId',
                operator: 'equals',
                value: langId?.toString(),
              },
            ],
          },
        },
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      });
      return response;
    }

    fetchCategoryData().then((response) => {
      const numbers = response.data.items.filter((c) =>
        /^(?![a-z])/i.test(c.name)
      );
      setSelectCategoryOptions(
        response.data.items.slice(numbers.length).concat(numbers)
      );
    });
  }, [langId]);

  useEffect(() => {
    if (!coverFile) {
      setPreviewCoverFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(coverFile);
    setPreviewCoverFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [coverFile]);

  useEffect(() => {
    if (!fileItemThumbnailFile) {
      setPreviewFileItemThumbnailFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileItemThumbnailFile);
    setPreviewFileItemThumbnailFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileItemThumbnailFile]);

  const handleCoverClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const handleMediaFileThumbnailClick = (event) => {
    hiddenMediaFileThumbnailInput.current.click();
  };

  const handleCoverUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCoverFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setCoverFile(fileUploaded);
  };

  const handleFileItemThumbnailUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setFileItemThumbnailFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setFileItemThumbnailFile(fileUploaded);
  };

  const addToFileItems = () => {
    const mediaType = selectTypeOfFileOptions.filter(
      (item) => item.id === fileItemMediaType
    );
    setFileItemsList([
      ...fileItemsList,
      {
        id: Math.floor(Math.random() * 135) + fileItemName,
        name: fileItemName,
        url: fileItemUrl,
        thumbObj: fileItemThumbnailFile,
        thumbnail: URL.createObjectURL(fileItemThumbnailFile),
        mediaTypeId: fileItemMediaType,
        mediaTypeName: mediaType[0].name,
        isPublic: true,
        isDefault: true,
      },
    ]);

    setPreviewFileItemThumbnailFile(undefined);
    setFileItemThumbnailFile(undefined);
    setFileItemUrl('');
    setFileItemName('');
    hiddenMediaFileThumbnailInput.current.value = '';
  };

  const deleteFile = (record) => {
    setFileItemsList(removeFileFromArray(fileItemsList, record.id));
    refresh();
  };

  const saveMediaItem = async (data) => {
    setIsLoading(true);

    let coverImageResponse = '';
    const transformedFileItemList = fileItemsList.slice();

    if (autoCompleteValue.length === 0 || fileItemsList.length === 0) {
      if (autoCompleteValue.length === 0) setLangError(true);
      if (fileItemsList.length === 0) setFileItemListErrorMsg(true);
      return;
    }

    try {
      if (coverFile) {
        const coverFormData = new FormData();
        coverFormData.append('file', coverFile);

        coverImageResponse = await axios.post(
          '/media-item/upload-cover-image',
          coverFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      }

      data.categoryIds = data.categories.map((item) => {
        return parseInt(item);
      });

      data.languagesIncluded = autoCompleteValue;
      data.downloadVersion = true;
      data.fullVersion = true;
      data.coverImage = coverFile ? coverImageResponse.data : data.coverImage;
      data.coverImageOriginal = coverFile
        ? coverImageResponse.data
        : data.coverImageOriginal;
      data.mediaItemType = 3;
      data.languageId = langId;
      data.fileItems = transformedFileItemList;
      data.specification.devicesToRunOn = autoCompleteDevicesValue;

      const newArr = [];

      for (const file of transformedFileItemList) {
        if (file.thumbObj) {
          file.thumbnail = await uploadThumb(file.thumbObj);
          file.id = 0;
          newArr.push(file);
        } else {
          newArr.push(file);
        }
      }

      data.fileItems = newArr.map((item) => {
        return {
          isFromCatalog: item.isFromCatalog,
          id: item.id,
          isDefault: true,
          isPublic: true,
          mediaTypeId: item.mediaTypeId,
          mediaTypeName: item.mediaTypeName,
          name: item.name,
          url: item.url,
          thumbnail: item.thumbnail,
        };
      });

      delete data.categories;
      delete data.fileName;
      delete data.typeOfFile;
      delete data.mediaType;

      update('media-item', { data }, { onError, onSuccess });

      transformedFileItemList.forEach((item) => {
        URL.revokeObjectURL(item.thumbObj);
      });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);

      console.log(err);
    }
  };

  const onError = (data) => {
    setIsLoading(false);
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    setIsLoading(false);
    toast.success(
      translate('notifications.success'),
      `${translate('resources.content.application_package')} ${
        data.name
      } ${translate('notifications.edited')}`
    );
  };

  const handleCatalogFiles = (payload) => {
    const filteredArray = fileItemsList.filter(
      (obj) => !payload.some((item) => item.id === obj.id)
    );

    store.removeItem('single-media-item-list.selectedIds');
    setFileItemsList([...filteredArray, ...payload]);
  };

  const handleImport = (payload) => {
    const selectedIds = store.getItem('single-media-item-list.selectedIds');
    const baseUrl = 'media-item/file-items-by-media-item-ids';
    const params = payload.map((id) => `ids=${id}`);
    const urlWithParams = `${baseUrl}?${params.join('&')}`;
    const importList = [];

    async function getItems() {
      const response = await axios(urlWithParams, {
        method: 'GET',
      });

      return response;
    }

    getItems().then((response) => {
      const sortedArray = [];
      selectedIds.forEach((id) => {
        response.data.forEach((item) => {
          if (id === item.mediaItemId) {
            sortedArray.push(item);
          }
        });
      });

      sortedArray.forEach((item) => {
        importList.push({
          ...item,
          isFromCatalog: true,
          coverImage: item.thumbnail,
        });
      });

      handleCatalogFiles(importList);
    });
  };

  return (
    <>
      <SimpleForm
        className='form-css'
        toolbar={
          <CustomToolbar
            handleClick={() => navigate('/content/media-catalog')}
            isEdit={true}
          />
        }
        record={data}
        onSubmit={saveMediaItem}
      >
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
          {translate('resources.content.description')}
        </Typography>
        <TextInput
          source='name'
          label={translate('resources.content.title')}
          validate={required()}
          sx={{ width: '60%', mb: 1 }}
        />
        <div className='media-item-description' style={{ width: '60%' }}>
          <RichTextInput
            source='description'
            multiline
            maxRows={8}
            label={translate('resources.content.description')}
            validate={required()}
            className='custom-rich-text-input'
          />
        </div>
        <div className='form-css' style={{ width: '100%' }}>
          <Autocomplete
            multiple
            id='tags-outlined'
            size='small'
            options={[]}
            disablePortal={true}
            value={autoCompleteValue}
            onChange={(e, newval, reason) => {
              setAutoCompleteValue(newval);
            }}
            sx={{
              width: '60%',
              mb: 1,
            }}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                variant='outlined'
                label={translate('resources.content.available_languages')}
                // placeholder='Favorites'
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.target.value) {
                    e.preventDefault();
                    setLangError(false);
                    setAutoCompleteValue(
                      autoCompleteValue.concat(e.target.value)
                    );
                  }
                }}
              />
            )}
          />
          {langError && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-5px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('general_settings.required')}
            </p>
          )}
        </div>
        <div
          style={{
            margin: '1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
          {translate('resources.content.application_specifications')}
        </Typography>
        <TextInput
          source='specification.playStoreLink'
          label={translate('resources.content.play_store_link')}
          sx={{ width: '60%', mb: 1 }}
        />
        <TextInput
          source='specification.appStoreLink'
          label={translate('resources.content.app_store_link')}
          sx={{ width: '60%', mb: 1 }}
        />
        <TextInput
          source='specification.windowsStoreLink'
          label={translate('resources.content.win_store_link')}
          sx={{ width: '60%', mb: 1 }}
        />
        <div
          className='form-css'
          style={{ width: '100%', marginBottom: '24px' }}
        >
          <Autocomplete
            multiple
            id='tags-outlined'
            size='small'
            options={[]}
            disablePortal={true}
            value={autoCompleteDevicesValue}
            onChange={(e, newval, reason) => {
              setAutoCompleteDevicesValue(newval);
            }}
            sx={{
              width: '60%',
              mb: 1,
            }}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                variant='outlined'
                label={`${translate('resources.content.devices_to_run_on')}`}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.target.value) {
                    e.preventDefault();
                    setAutoCompleteDevicesValue(
                      autoCompleteDevicesValue.concat(e.target.value)
                    );
                  }
                }}
              />
            )}
          />
        </div>
        <TextInput
          source='specification.osToRunOn'
          label={translate('resources.content.os_to_run_on')}
          sx={{ width: '60%', mb: 1 }}
        />
        <TextInput
          source='specification.preferredBrowser'
          label={translate('resources.content.preferred_browser')}
          sx={{ width: '60%', mb: 1 }}
        />
        <TextInput
          source='specification.hardwareSpecs'
          label={translate('resources.content.hardware_specs')}
          sx={{ width: '60%', mb: 1 }}
        />
        <TextInput
          source='specification.appSize'
          label={translate('resources.content.app_size')}
          sx={{ width: '60%', mb: 1 }}
        />
        <TextInput
          source='specification.userAge'
          label={translate('resources.content.user_age')}
          sx={{ width: '60%', mb: 1 }}
        />
        <BooleanInput
          label={translate('resources.content.internet_connection_required')}
          source='specification.internetConnectionRequired'
          sx={{
            mr: 2,
            '& .MuiFormControlLabel-label': {
              color: theme.palette.primary.main,
            },
          }}
        />
        <div
          style={{
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', my: 3 }}>
          {translate('resources.content.media_item_cover_image')}
        </Typography>
        <div style={{ display: 'flex', marginBottom: 38 }}>
          <UploadIcon />
          <CustomButton
            title={translate('common.click_to_upload')}
            variant='ghost'
            style={{ marginLeft: 4, marginRight: 4 }}
            handleClick={handleCoverClick}
          />
          <input
            type='file'
            accept='image/*'
            ref={hiddenCoverFileInput}
            onChange={handleCoverUploadChange}
            style={{ display: 'none' }}
          />

          {coverFile ? (
            <img
              src={previewCoverFile}
              alt={coverFile.name}
              style={{ height: '6.3rem' }}
            />
          ) : (
            <img
              src={data?.coverImage}
              alt='cover'
              style={{ height: '6.3rem' }}
            />
          )}
        </div>
        <div
          style={{
            margin: '3rem 0 1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <div style={{ display: 'flex' }}>
          <BooleanInput
            label={translate('resources.content.publish_to_media_catalogue')}
            source='isPublic'
            sx={{
              mr: 2,
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
          <BooleanInput
            label={translate('resources.content.mark_as_new')}
            source='isNew'
            sx={{
              mr: 2,
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
          <BooleanInput
            label={translate('resources.content.add_to_case_studies')}
            source='isCaseStudy'
            sx={{
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
        </div>
        <div
          style={{
            marginBottom: '1.5rem',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <div
          style={{
            marginBottom: '1.5rem',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
          {translate('resources.content.categories')}
        </Typography>
        <AutocompleteArrayInput
          source='categories'
          label={translate('resources.content.add_categories')}
          icon={<KeyboardArrowDownIcon />}
          sx={{ width: '60%' }}
          choices={selectCategoryOptions}
          validate={required()}
          optionText='name'
          optionValue='id'
          size='small'
        />
        <div
          style={{
            margin: '1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
          {translate('resources.content.file_item')}
        </Typography>
        <CustomButton
          handleClick={() => {
            setDialogOpen(true);
          }}
          variant='secondary'
          title={translate('common.choose_from_catalogue')}
        />
        <Typography variant='h6' sx={{ fontSize: '1rem', mt: 5 }}>
          {translate('common.add_files_from_bunny')}
        </Typography>
        <Typography variant='h7' sx={{ fontSize: '0.9rem', my: 3 }}>
          {translate('resources.content.file_item_thumbnail')}
        </Typography>
        <div style={{ display: 'flex', marginBottom: 38 }}>
          <UploadIcon />
          <CustomButton
            title={translate('common.click_to_upload')}
            variant='ghost'
            style={{ marginLeft: 4, marginRight: 4 }}
            handleClick={handleMediaFileThumbnailClick}
          />
          <input
            type='file'
            ref={hiddenMediaFileThumbnailInput}
            onChange={handleFileItemThumbnailUploadChange}
            style={{ display: 'none' }}
          />

          {fileItemThumbnailFile && (
            <img
              src={previewFileItemThumbnailFile}
              alt={fileItemThumbnailFile.name}
              style={{ height: '6.3rem' }}
            />
          )}
        </div>
        <MuiTextField
          value={fileItemName}
          label={translate('resources.content.file_name')}
          onChange={(e) => setFileItemName(e.target.value)}
          sx={{ width: '60%', mb: 3 }}
          size='small'
        />

        <MuiTextField
          value={fileItemUrl}
          id='outlined-multiline-flexible'
          onChange={(e) => setFileItemUrl(e.target.value)}
          label={translate('resources.content.url')}
          sx={{ width: '60%', mb: 3 }}
          size='small'
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            paddingBottom: '30px',
            alignItems: 'center',
          }}
        >
          <MuiTextField
            value={fileItemMediaType}
            select
            defaultValue={''}
            onChange={(e) => setFileItemMediaType(e.target.value)}
            label={translate('resources.content.media_type')}
            icon={<KeyboardArrowDownIcon />}
            sx={{ width: '30%', height: '40px', mr: '40px' }}
            choices={selectTypeOfFileOptions}
            size='small'
          >
            {selectTypeOfFileOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </MuiTextField>
          <CustomButton
            variant='primary'
            title={translate('buttons.add_file_item')}
            handleClick={addToFileItems}
            sx={{
              mb: '-4px',
            }}
            disabled={
              fileItemName &&
              fileItemUrl &&
              fileItemMediaType &&
              fileItemThumbnailFile
                ? false
                : true
            }
          />
        </div>
        {fileItemListErrorMsg && (
          <p
            style={{
              color: '#E74C3C',
              marginTop: '-25px',
              fontSize: '0.84rem',
              marginLeft: '0.9rem',
            }}
          >
            {translate('resources.content.media_item_must')}
          </p>
        )}
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false}>
            <UsernameWithImage
              imageSrc='thumbnail'
              label={translate('common.table.name')}
            />
            <TextField source='url' label={translate('common.table.url')} />
            <TextField
              source='mediaTypeName'
              label={translate('resources.content.media_type_name')}
            />
            <DeleteButton />
          </Datagrid>
        </ListContextProvider>
      </SimpleForm>
      <ImportModal
        isOpen={dialogOpen}
        setModalOpen={setDialogOpen}
        close={() => {
          setDialogOpen(false);
          store.removeItem('single-media-item-list.selectedIds');
        }}
        save={handleImport}
      />
    </>
  );
};

export default ApplicationPackageEdit;
