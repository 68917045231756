import axios from '../plugins/axios';

export default async function check(name) {
  const response = await axios(
    '/media-item/media-item-exist-checker',
    { params: { name: name } },
    {
      method: 'GET',
    }
  );
  return response;
}
