import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, IconButton } from '@mui/material';

const ITEM_HEIGHT = 45;

const ActionDropdown = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon style={{ fontSize: 22 }} />
      </IconButton>
      <Menu
        id='long-menu'
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            padding: 12,
          },
        }}
      >
        {options.map((option) => {
          if (option) {
            return <div key={option.key}>{option}</div>;
          }

          return null;
        })}
      </Menu>
    </>
  );
};

export default ActionDropdown;
