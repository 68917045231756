// library modules
import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTranslate,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  email,
  useGetList,
  useCreate,
  useRefresh,
} from 'react-admin';
import axios from '../../plugins/axios';
import { ToastContext } from '../../context/toastContext';

// utils
import { createPassword } from '../../utils/passwordGenerator';
import check from '../../utils/checkAdminEmail';

// components
import { Typography, Breadcrumbs, TextField } from '@mui/material';
import CustomButton from '../shared/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UploadIcon from '../shared/UploadIcon';
import CustomToolbar from '../shared/CustomToolbar';

const SettingsCreate = () => {
  // state
  const [profileFile, setProfilefile] = useState();
  const [previewProfileFile, setPreviewProfileFile] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [doesExist, setDoesExist] = useState(false);

  //hooks
  const navigate = useNavigate();
  const translate = useTranslate();
  const refresh = useRefresh();
  const [create] = useCreate();
  const { data } = useGetList('admin-user-roles');
  const { toast } = useContext(ToastContext);

  //refs
  const passwordInput = useRef(null);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    setSelectOptions(data);
  }, [data]);

  useEffect(() => {
    if (!profileFile) {
      setPreviewProfileFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(profileFile);
    setPreviewProfileFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [profileFile]);

  const generatePasword = () => {
    passwordInput.current.value = createPassword(16);
    setPasswordErrorMsg(false);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      profileFile(undefined);
      return;
    }
    setImageErrorMsg(false);
    const fileUploaded = event.target.files[0];
    setProfilefile(fileUploaded);
  };

  const saveUser = async (data) => {
    const error = await check(data.email);

    setDoesExist(error.data);

    if (!profileFile) {
      setImageErrorMsg(true);
      return;
    }
    if (!passwordInput.current.value) {
      setPasswordErrorMsg(true);
      return;
    }
    if (
      passwordInput.current.value.match(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?]).{6,}/
      )
    ) {
      setPasswordErrorMsg(false);
    } else {
      setPasswordErrorMsg('validation');
      return;
    }
    if (error.data) return;

    if (profileFile) {
      try {
        const formData = new FormData();
        formData.append('file', profileFile);

        const response = await axios.post(
          '/admin-user/upload-image',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
        const imageUrl = response.data;

        data.profileImagePath = imageUrl;
        data.profileImagePathOriginal = imageUrl;
        data.password = passwordInput.current.value;

        create('admin-user', { data }, { onError, onSuccess });
      } catch (err) {
        toast.error(translate('notifications.error'), err.message);
      }
    }
  };

  const onError = (data) => {
    toast.error(
      translate('notifications.error'),
      data.response.data.error.message
    );
  };

  const onSuccess = (data) => {
    toast.success(
      translate('notifications.success'),
      `${translate('general_settings.user')} ${data.firstName} ${translate(
        'notifications.created'
      )}`
    );

    navigate('/admin-user');
    refresh();
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('general_settings.main_settings')}
          handleClick={() => navigate('/admin-user')}
        />
        <CustomButton
          variant='link'
          title={translate('general_settings.add_user')}
        />
      </Breadcrumbs>
      <Typography variant='h4' sx={{ paddingLeft: 3 }}>
        {translate('general_settings.add_user')}
      </Typography>
      <Create
        redirect={'/admin-user'}
        className='general settings'
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'unset',
          },
          '& .MuiCardContent-root': { px: 3 },
        }}
      >
        <SimpleForm
          toolbar={
            <CustomToolbar
              handleClick={() => navigate('/admin-user')}
              sx={{ mx: 3 }}
            />
          }
          onSubmit={saveUser}
        >
          <TextInput
            InputLabelProps={{ shrink: true }}
            source='name'
            label={translate('general_settings.add_user_name')}
            validate={required()}
            sx={{ width: '60%', mb: 1 }}
          />

          <TextInput
            InputLabelProps={{ shrink: true }}
            source='email'
            type='email'
            validate={[required(), email()]}
            label={translate('general_settings.add_user_email')}
            sx={{ width: '60%', mb: 1 }}
            onBlur={(e) =>
              check(e.target.value).then((response) => {
                setDoesExist(response.data);
              })
            }
          />
          {doesExist && (
            <div
              style={{
                color: '#E74C3C',
                marginTop: '-30px',
                fontSize: '0.8rem',
                padding: '0 0 8px 14px',
              }}
            >
              {translate('resources.customer.email_exists')}
            </div>
          )}
          <div
            style={{
              margin: '0 0 1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>
          <Typography variant='h6' sx={{ fontSize: '1rem' }}>
            {translate('general_settings.user_role')}
          </Typography>
          <SelectInput
            InputLabelProps={{ shrink: true }}
            source='roleId'
            label={translate('general_settings.type_of_admin')}
            icon={<KeyboardArrowDownIcon />}
            sx={{ width: '30%', height: '40px' }}
            choices={selectOptions}
            validate={required()}
            optionText='name'
            optionValue='id'
          />
          <div
            style={{
              margin: '1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>
          <div
            style={{
              width: '60%',
              position: 'relative',
            }}
          >
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 4 }}>
              {translate('general_settings.user_password')}
            </Typography>
            <div
              style={{
                display: 'flex',
                // position: 'relative',
                alignItems: 'center',
                flexGrow: 2,
                marginTop: '-22px',
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                source='password'
                type='text'
                validate={required()}
                label={translate(
                  'general_settings.type_password_or_aut_generate'
                )}
                inputRef={passwordInput}
                size='small'
                sx={{
                  width: '70%',
                  zIndex: 0,
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderWidth: 1,
                    },
                  },
                  '& .MuiInputBase-root': {
                    flexBasis: 'auto',
                  },
                }}
              />
              <CustomButton
                title={translate('buttons.auto_generate_password')}
                variant='primary'
                handleClick={generatePasword}
                sx={{ width: 240, ml: 5 }}
              />
            </div>
            {passwordErrorMsg && (
              <p
                style={{
                  color: '#E74C3C',
                  marginTop: '3px',
                  fontSize: '0.8rem',
                  marginLeft: '0.9rem',
                }}
              >
                {passwordErrorMsg === 'validation'
                  ? translate('common.password_validation')
                  : translate('general_settings.required')}
              </p>
            )}
          </div>

          <div
            style={{
              margin: '1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>

          <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
            {translate('common.profile_image')}
          </Typography>

          <div style={{ display: 'flex', marginBottom: 38 }}>
            <UploadIcon />
            <CustomButton
              title={translate('common.click_to_upload')}
              variant='ghost'
              style={{ marginLeft: 4, marginRight: 4 }}
              handleClick={handleClick}
            />
            <input
              type='file'
              accept='image/*'
              ref={hiddenFileInput}
              onChange={handleUploadChange}
              style={{ display: 'none' }}
            />
            {profileFile && (
              <img
                src={previewProfileFile}
                alt={profileFile.name}
                style={{ height: '6.3rem' }}
              />
            )}
          </div>
          {imageErrorMsg && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-25px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('general_settings.required')}
            </p>
          )}
        </SimpleForm>
      </Create>
    </>
  );
};

export default SettingsCreate;
