// library modules
import {
  List,
  Datagrid,
  SearchInput,
  Filter,
  TextField,
  DateField,
  ResourceContextProvider,
  useTranslate,
} from 'react-admin';

// components
import { Typography, Stack } from '@mui/material';
import { Box } from '@mui/system';

const CaseStudiesList = () => {
  const translate = useTranslate();
  return (
    <div style={{ padding: '24px' }}>
      <Typography variant='h5' sx={{ mb: '2.5rem' }}>
        {translate('resources.case_studies.list_of_case_studies')}
      </Typography>
      <ResourceContextProvider resource='selected-file-item-case-studies'>
        <List
          actions={null}
          filters={
            <Filter variant='outlined'>
              <SearchInput
                source='case-studies-search'
                variant='outlined'
                alwaysOn
                resettable={true}
                sx={{ width: 350 }}
              />
            </Filter>
          }
          resource='selected-file-item-case-studies'
          filter={{ sectionId: '3', languageId: '1', customerId: null }}
          empty={false}
          sx={{
            '& .MuiToolbar-root': { mb: '2rem !important' },
            '& .MuiTablePagination-root': {
              mr: '1rem',
            },
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            expand={(data) => {
              return (
                <Stack sx={{ py: '0.5rem', ml: '3rem', gap: '0.25rem' }}>
                  {data.record.fileItems.map((item, i) => (
                    <Box key={i}>{item.name}</Box>
                  ))}
                </Stack>
              );
            }}
          >
            <TextField
              source='name'
              label={translate('common.table.name_of_item')}
            />
            <DateField
              source='dateAdded'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <TextField
              source='publicOrPrivate'
              label={translate('common.table.private_public')}
            />
            <TextField
              source='mediaItemType'
              label={translate('common.table.media_item_type')}
            />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </div>
  );
};

export default CaseStudiesList;
