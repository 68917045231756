// library modules
import { useLocation } from 'react-router-dom';
import {
  Menu,
  MenuItemLink,
  Logout,
  LocalesMenuButton,
  localStorageStore,
} from 'react-admin';

// components
import ProfileMenuItem from './ProfileMenuItem';
import { useSidebarState } from 'react-admin';
import { IconButton } from '@mui/material';

// icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DescriptionIcon from '@mui/icons-material/Description';
import ExtensionIcon from '@mui/icons-material/Extension';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import FactoryIcon from '@mui/icons-material/Factory';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';

// css
import { theme } from './themes';

const CustomMenu = () => {
  const location = useLocation();
  const [open, setOpen] = useSidebarState();
  const store = localStorageStore();

  const locale = store.getItem('locale');

  const margin = locale === 'en' ? '18px' : '22px';

  const MyLogoutButton = ({ sx }) => (
    <Logout
      icon={
        <LogoutIcon sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }} />
      }
      sx={{
        '& .MuiTypography-root': {
          fontSize: '0.875rem !important',
          fontWeight: '500',
        },
        marginLeft: '0.6rem',
        color: theme.palette.tetriary.main,
        borderRadius: '5px !important',
        '&:hover': {
          backgroundColor: 'transparent',
          opacity: 0.7,
        },
      }}
    />
  );

  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        aria-label='toggle-sidebar'
        onClick={() => {
          setOpen(open === true ? false : true);
        }}
        sx={{
          backgroundColor: 'primary.main',
          position: 'absolute',
          top: '105%',
          right: '-20px',
          zIndex: 10,
          ':hover': {
            backgroundColor: 'primary.main',
            color: 'primary.light',
          },
        }}
      >
        <ChevronLeftOutlinedIcon sx={{ color: '#fff' }} />
      </IconButton>
      <ProfileMenuItem open={open} />
      <div
        style={{ borderBottom: '1px solid #E5E5E5', margin: '20px 0px' }}
      ></div>
      <Menu>
        <MenuItemLink
          to={'/content/media-catalog'}
          name='content'
          primaryText={'Content'}
          key={'content'}
          leftIcon={
            <DashboardIcon
              style={
                location.pathname.includes('/content')
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname.includes('/content')
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname.includes('/content')}
        />
        <MenuItemLink
          to={'/customers/list'}
          name='customers'
          key={'customers'}
          primaryText={'Customers'}
          leftIcon={
            <PeopleIcon
              style={
                location.pathname.includes('/customer')
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname.includes('/customer')
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname.includes('/customers')}
        />
        <MenuItemLink
          to={'/documents/contracts'}
          name='contracts'
          primaryText={'Contracts & Invoices'}
          key={'contracts'}
          leftIcon={
            <AssignmentIcon
              style={
                location.pathname.includes('/documents')
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname.includes('/documents')
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname.includes('/documents')}
        />
        {/* <MenuItemLink
          to={'/testimonials'}
          name='testimonials'
          primaryText={'Testimonials'}
          key={'testimonials'}
          leftIcon={
            <DescriptionIcon
              style={
                location.pathname === '/testimonials'
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname === '/testimonials'
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname === '/testimonials'}
        /> */}
        <MenuItemLink
          to={'/case-studies'}
          name='case-studies'
          primaryText={'Presentations'}
          key={'case-studies'}
          leftIcon={
            <ExtensionIcon
              style={
                location.pathname === '/case-studies'
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname === '/case-studies'
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname === '/case-studies'}
        />
        <MenuItemLink
          to={'/wild-factory'}
          name='wild-factory'
          primaryText={'Wild Factory'}
          key={'wild-factory'}
          leftIcon={
            <FactoryIcon
              style={
                location.pathname === '/wild-factory'
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname === '/wild-factory'
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname === '/wild-factory'}
        />
        <div
          style={{ borderBottom: '1px solid #E5E5E5', margin: '20px 0px' }}
        ></div>
        <Menu.Item
          name='general-settings'
          to={'/admin-user'}
          primaryText={'Main Settings'}
          key={'general-settings'}
          leftIcon={
            <SettingsIcon
              style={
                location.pathname.includes('/admin-user')
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname.includes('/admin-user')
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname.includes('/admin-user')}
        />
        <MenuItemLink
          to={'/token'}
          name='token'
          primaryText={'Token'}
          key={'token'}
          leftIcon={
            <TokenOutlinedIcon
              style={
                location.pathname === '/token'
                  ? { color: theme.palette.success.main }
                  : { color: theme.palette.menu.main }
              }
              sx={open ? { marginLeft: 0 } : { marginLeft: '-10px' }}
            />
          }
          style={
            location.pathname === '/token'
              ? { color: '#fff', backgroundColor: theme.palette.primary.main }
              : {
                  color: theme.palette.tetriary.main,
                  backgroundColor: 'transparent',
                }
          }
          selected={location.pathname === '/token'}
        />
        <div style={open ? { marginLeft: 0 } : { marginLeft: margin }}>
          <LocalesMenuButton />
        </div>
      </Menu>
      <div
        style={{ borderBottom: '1px solid #E5E5E5', margin: '20px 0px' }}
      ></div>
      <MyLogoutButton />
    </div>
  );
};

export default CustomMenu;
