// library modules
import { useContext } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ResourceContextProvider,
  useTranslate,
  useRecordContext,
} from 'react-admin';
import { CustomerContext } from '../../../context/customerContext';

// components
import { ListFilter } from './ListFilter';
import ActionDropdown from '../../shared/ActionDropdown';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import UsernameWithImage from '../../shared/UsernameWithImage';

const RESOURCE = 'selected-file-item-case-studies-list';
const SECTION_ID = '3';

const DropdownMenu = () => {
  const translate = useTranslate();
  const record = useRecordContext();

  const options = [
    <DeleteConfirmationDialog
      message={`${translate('resources.content.media_item')} ${
        record.name
      } ${translate('notifications.deleted')}`}
      title={`${translate('resources.content.delete_media_item')} ${
        record.name
      }?`}
      key={`${record.id} + delete`}
    />,
  ];

  return <ActionDropdown options={options} />;
};

const TestimonialsList = (pops) => {
  const translate = useTranslate();
  const { customer } = useContext(CustomerContext);

  const CREATE_PAGE = `/add-case-studies`;

  return (
    <>
      <ResourceContextProvider resource={RESOURCE}>
        <List
          actions={null}
          filters={
            <ListFilter
              createPage={CREATE_PAGE}
              source='customerCaseStudiesSearch'
              title={translate('resources.customer.add_case_study')}
            />
          }
          resource={RESOURCE}
          filterDefaultValues={{ languageId: '1' }}
          filter={{
            sectionId: SECTION_ID,
            customerId: customer ? customer?.id.toString() : '0',
          }}
          empty={false}
        >
          <Datagrid bulkActionButtons={false}>
            <UsernameWithImage label={translate('common.table.name_of_item')} />
            <DateField
              source='dateAdded'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <TextField
              source='publicOrPrivate'
              label={translate('common.table.private_public')}
            />
            <TextField
              source='mediaItemType'
              label={translate('common.table.media_item_type')}
            />
            <DropdownMenu label='Actions' textAlign='center' />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </>
  );
};

export default TestimonialsList;
