import { useEffect, useContext } from 'react';
import axios from '../../plugins/axios';
import { useGetIdentity, useRedirect } from 'react-admin';
import { UserContext } from '../../context/userContext';
import Avatar from '@mui/material/Avatar';

const UsernameWithImage = ({ image, name, email, isMinimised }) => {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr', width: '100%' }}>
      <Avatar alt={name} src={image} />
      {isMinimised && (
        <p style={{ overflowWrap: 'break-word', margin: '0.5rem 0 0 0 ' }}>
          {name}
        </p>
      )}

      {isMinimised && <div style={{ fontSize: '0.8rem' }}>{email}</div>}
    </div>
  );
};

const ProfileMenuItem = ({ open }) => {
  const { data: userData, isLoading } = useGetIdentity();
  const { user, setUser } = useContext(UserContext);
  const redirect = useRedirect();

  useEffect(() => {
    if (!isLoading) {
      if (userData.role === 'Customer') {
        redirect('/unauthorized');
      }

      async function getUser() {
        const response = await axios(`/admin-user/${userData.sub}`, {
          method: 'GET',
        });

        return response;
      }

      getUser().then((response) => {
        setUser(response.data);
      });
    }
  }, [isLoading]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: open ? '10px' : '0.45rem',
      }}
    >
      <UsernameWithImage
        image={user?.profileImagePath}
        name={user?.name}
        email={user?.email}
        isMinimised={open}
      />

      <button
        style={{
          backgroundColor: 'transparent',
          padding: '0',
          border: 0,
        }}
      ></button>
    </div>
  );
};

export default ProfileMenuItem;
