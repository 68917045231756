// library modules
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams, Outlet, useLocation } from 'react-router-dom';
import { localStorageStore, useTranslate, useGetOne } from 'react-admin';
import { CustomerContext } from '../../../context/customerContext';

// components
import { Typography, Box, Breadcrumbs, TextField } from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import TabRouter from '../../shared/TabRouter';

const CustomersEdit = () => {
  const [isEnabled, setIsEnabled] = useState(true);
  const navigate = useNavigate();
  const params = useParams();
  let { pathname } = useLocation();
  const translate = useTranslate();
  const { customer, setCustomer } = useContext(CustomerContext);

  const { data } = useGetOne(
    'customer',
    { id: params.id },
    {
      onSuccess: (data) => {
        setIsEnabled(false);
        setCustomer(data);
      },
      enabled: isEnabled,
    }
  );

  const routes = [
    {
      name: translate('resources.customer.company_informations'),
      url: '/company-information',
    },
    {
      name: translate('resources.customer.customer_space'),
      url: '/customer-space',
    },
  ];

  useEffect(() => {
    if (pathname.endsWith('customer-space')) {
      navigate(
        data?.status === 'Active'
          ? 'customer-space/chosen-products'
          : 'customer-space/our-products'
      );
    }

    // return () => setCustomer(null);
  }, [pathname]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(customer.customerSpaceURL);
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
          <CustomButton
            variant='link'
            title={translate('resources.customer.customers')}
            handleClick={() => navigate('/customers/list')}
          />
          <CustomButton
            variant='link'
            title={translate('resources.customer.edit_customer')}
          />
        </Breadcrumbs>
        <Typography variant='h4' sx={{ paddingLeft: 3 }}>
          {translate('resources.customer.edit_customer')}
        </Typography>

        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <div style={{ position: 'relative' }}>
            <TextField
              value={customer?.customerSpaceURL}
              type='text'
              label=''
              disabled
              sx={{
                width: '600px',
                zIndex: 0,
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderWidth: 1,
                  },
                },
                '& .MuiInputBase-input': {
                  height: 44,
                  py: '6px',
                },
                '& .MuiInputBase-root': {
                  pr: 22,
                  flexBasis: 'auto',
                },
              }}
            />
            <CustomButton
              title={translate('buttons.copy_url_address')}
              variant='inside-input'
              handleClick={copyToClipboard}
            />
          </div>
          <div
            style={{
              width: '100px',
              display: 'flex',
              alignItems: 'center',
              height: 56,
              textAlign: 'center',
              border: '1px solid primary',
              color:
                customer?.status === 'Active' ? 'primary.main' : 'neutral.main',
              zIndex: 0,
              marginLeft: '16px',
              marginRight: '16px',
              backgroundColor:
                customer?.status === 'Active' ? '#DAFFFA' : '#FFFBF1',
            }}
          >
            <p style={{ margin: ' 0 auto' }}>{customer?.status}</p>
          </div>
        </div>
      </div>
      <Box sx={{ width: '100%', position: 'relative', px: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <TabRouter basePath={`/customer/${params.id}`} routes={routes} />
        </Box>
        <Outlet />
      </Box>
    </>
  );
};

export default CustomersEdit;
