// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import {
  DateInput,
  Form,
  SaveButton,
  TextInput,
  useCreate,
  useTranslate,
  useRefresh,
  required,
} from 'react-admin';
import axios from '../../../plugins/axios';
import { ToastContext } from '../../../context/toastContext';
import { useNavigate } from 'react-router-dom';
import { InitialDataContext } from '../../../context/initialDataContext';
import { CustomerContext } from '../../../context/customerContext';

// components
import { Box, Stack, Typography, Breadcrumbs } from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import UploadIcon from '../../shared/UploadIcon';

export default function AddCustomerDocument({ name }) {
  // state
  const [profileFile, setProfileFile] = useState();
  const [, setPreviewProfileFile] = useState();
  const [imageError, setImageError] = useState(false);

  // hooks
  const hiddenProfileFileInput = useRef(null);
  const [create] = useCreate();
  const navigate = useNavigate();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const initialData = useContext(InitialDataContext);
  const { customer } = useContext(CustomerContext);

  // store

  const documentTypeIdInvoice = initialData.invoiceId;
  const documentTypeIdContract = initialData.contractId;

  useEffect(() => {
    if (!profileFile) {
      setPreviewProfileFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(profileFile);
    setPreviewProfileFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [profileFile]);

  const handleProfileImageClick = (event) => {
    hiddenProfileFileInput.current.click();
  };

  const handleUploadProfileImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      profileFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setProfileFile(fileUploaded);
    setImageError(false);
  };

  const handleSubmit = async (data) => {
    if (!profileFile) {
      setImageError(true);
      return;
    }

    let url = '';
    if (name === 'invoice') {
      url = '/customer-document/upload-invoice';
    } else {
      url = '/customer-document/upload-contract';
    }

    const imageFormData = new FormData();
    imageFormData.append('file', profileFile);

    try {
      const profileImageResponse = await axios.post(url, imageFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      data.url = profileImageResponse.data;
      data.documentTypeId =
        name === 'invoice' ? documentTypeIdInvoice : documentTypeIdContract;
      data.customerId = customer.id;

      create('customer-document', { data }, { onError, onSuccess });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);
      console.log(err);
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    toast.success(
      translate('notifications.success'),
      `${translate('notifications.document_created')}`
    );

    navigate(-1);
    refresh();
  };

  return (
    <Box sx={{ p: '1.5rem' }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.customer.customers')}
          handleClick={() => navigate(-1)}
        />
        <CustomButton
          variant='link'
          title={`${translate('common.add_lovercase')} ${name}`}
        />
      </Breadcrumbs>
      <Typography variant='h5' sx={{ mb: '3.25rem' }}>
        {translate('common.add_lovercase')} {name}
      </Typography>
      <Form onSubmit={handleSubmit}>
        <Stack sx={{ maxWidth: '47.5rem' }}>
          <TextInput
            source='name'
            validate={required()}
            label={translate('common.table.name')}
          />
          <DateInput
            source='signatureDate'
            validate={required()}
            label={
              name === 'invoice'
                ? translate('resources.contracts.emission_date')
                : translate('resources.contracts.start_date')
            }
            sx={{ width: '217px' }}
          />

          {name === 'contract' && (
            <DateInput
              source='endDate'
              validate={required()}
              label={translate('resources.contracts.end_date')}
              sx={{ width: '217px' }}
            />
          )}

          <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
            {translate('common.upload_file')}
          </Typography>

          <div
            style={{
              display: 'flex',
              marginBottom: 38,
            }}
          >
            <UploadIcon />
            <CustomButton
              title={translate('common.click_to_upload')}
              variant='ghost'
              style={{ marginLeft: 4, marginRight: 4 }}
              handleClick={handleProfileImageClick}
            />
            <input
              type='file'
              // accept='image/*'
              ref={hiddenProfileFileInput}
              onChange={handleUploadProfileImageChange}
              style={{ display: 'none' }}
            />
            {profileFile && (
              <div
                style={{
                  fontSize: '0.8rem',
                  border: '1px solid #D7D7D7',
                  padding: '12px',
                  color: '#264653',
                }}
              >
                <div>
                  {translate('resources.content.file_name')}: {profileFile.name}
                </div>
                <div>
                  {translate('resources.content.file_size')}: {profileFile.size}
                </div>
              </div>
            )}
          </div>
          {imageError && (
            <p
              style={{
                color: '#E74C3C',
                fontSize: '0.8rem',
                margin: '-1.3rem 0 1rem 1rem',
              }}
            >
              {translate('notifications.file_is_required')}
            </p>
          )}

          <SaveButton />
        </Stack>
      </Form>
    </Box>
  );
}
