// library modules
import { useContext, useState, useRef, useEffect } from 'react';
import { useCreate, useRefresh, useTranslate } from 'react-admin';
import axios from '../../plugins/axios';

// components
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FormDialog from '../shared/FormDialog';
import CustomButton from '../shared/CustomButton';

import { ToastContext } from '../../context/toastContext';

// icons
import UploadIcon from '../shared/UploadIcon';

export default function AddSubcategoryModal({
  record,
  modalOpen,
  setModalOpen,
  isFactory = false,
  refresh,
  languageId,
}) {
  const [subcategoryName, setSubcategoryName] = useState('');
  const [categoryFile, setCategoryFile] = useState();
  const [previewCategoryFile, setPreviewCategoryFile] = useState();
  const [create] = useCreate('categories');
  // const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const translate = useTranslate();

  const hiddenCategoryFileInput = useRef(null);

  useEffect(() => {
    if (!categoryFile) {
      setPreviewCategoryFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(categoryFile);
    setPreviewCategoryFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [categoryFile]);

  const handleCategoryImageClick = (event) => {
    hiddenCategoryFileInput.current.click();
  };

  const handleUploadCategoryImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCategoryFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setCategoryFile(fileUploaded);
  };

  const addSubcategory = async () => {
    let categoryImageResponse = '';

    if (categoryFile) {
      try {
        const imageFormData = new FormData();
        imageFormData.append('file', categoryFile);

        categoryImageResponse = await axios.post(
          '/category/upload-image',
          imageFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      } catch (err) {
        console.log(err);
      }
    }

    await create(
      'categories',
      {
        data: {
          parentId: record.id,
          name: subcategoryName,
          iconName: categoryImageResponse.data,
          isFactory: isFactory,
          languageId: languageId,
        },
      },
      {
        onSuccess: () => {
          refresh();
          setModalOpen(false);
          setSubcategoryName('');
          toast.success(
            translate('notifications.success'),
            `${translate(
              'resources.content.subcategory'
            )} ${subcategoryName} ${translate('notifications.created')}`
          );
        },
      }
    );
  };

  return (
    <FormDialog
      title='Add new subcategory'
      isOpen={modalOpen}
      handleClose={() => setModalOpen(false)}
      handleConfirm={() => addSubcategory()}
    >
      <Box sx={{ mb: '2rem', color: 'neutral.light' }}>
        Choose the name for the subcategory
      </Box>
      <Box sx={{ mb: '1.5rem', color: 'neutral.light' }}>
        <Box sx={{ mb: '0.5rem' }}>
          <Typography variant='sm'>Path</Typography>
        </Box>
        {record.treePath + '/ '}
        <Typography color='black'>Subcategory</Typography>
      </Box>
      <TextField
        value={subcategoryName}
        onChange={(e) => setSubcategoryName(e.target.value)}
        id='subcategory'
        label='Subcategory'
        sx={{ mt: '0.5rem', width: '100%' }}
      />
      <Typography variant='h6' sx={{ fontSize: '1rem', my: 3 }}>
        {translate('common.category_image')}
      </Typography>
      <div style={{ display: 'flex', marginBottom: 38 }}>
        <UploadIcon />
        <CustomButton
          title={translate('common.click_to_upload')}
          variant='ghost'
          style={{ marginLeft: 4, marginRight: 4 }}
          handleClick={handleCategoryImageClick}
        />
        <input
          type='file'
          accept='image/*'
          ref={hiddenCategoryFileInput}
          onChange={handleUploadCategoryImageChange}
          style={{ display: 'none' }}
        />
        {categoryFile && (
          <img
            src={previewCategoryFile}
            alt={translate('common.profile_image')}
            style={{ height: '6.3rem' }}
          />
        )}
      </div>
    </FormDialog>
  );
}
