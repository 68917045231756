import { useContext } from 'react';
import {
  useRecordContext,
  DeleteWithConfirmButton,
  useTranslate,
  useRefresh,
} from 'react-admin';
import { ToastContext } from '../../context/toastContext';

const DeleteConfirmationDialog = ({ title, message, props }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);

  return (
    <DeleteWithConfirmButton
      confirmTitle={title}
      {...props}
      record={record}
      style={{ width: '100%', display: 'flex', justifyContent: 'start' }}
      mutationOptions={{
        onSuccess: () => {
          toast.success(translate('notifications.success'), message);

          refresh();
        },
        onError: (data) => {
          toast.error(translate('notifications.error'), data.message);
        },
      }}
    />
  );
};

export default DeleteConfirmationDialog;
