// library modules
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  SearchInput,
  Filter,
  TextField,
  DateField,
  ResourceContextProvider,
  useTranslate,
  useListContext,
  useUnselectAll,
  localStorageStore,
} from 'react-admin';
import { InitialDataContext } from '../../../context/initialDataContext';

// components
import CustomButton from '../../shared/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Breadcrumbs } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ExpandedItem from '../customer-space/ExpandedItem';

const RESOURCE = 'media-item-filtered';

const canExpand = (list) => {
  return list.length > 0 ? true : false;
};

const OurProductsFilter = ({ section }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const unselectAll = useUnselectAll(RESOURCE);
  const { data, selectedIds } = useListContext();
  const store = localStorageStore();
  const [isEdit] = useState(store.getItem('template.isEdit') || false);

  const handleClick = () => {
    let payload = [];
    let newItems = [];

    data.forEach((item) => {
      if (selectedIds.includes(item.id)) payload.push(item);
    });

    if (section === 1) {
      const storeItems = store.getItem('template.our.products') || [];

      if (isEdit) newItems = store.getItem('template.ourProductsNew') || [];

      const merged = [...storeItems, ...payload];
      store.setItem('template.our.products', merged);

      if (isEdit) {
        const merged = [...newItems, ...payload];
        store.setItem('template.ourProductsNew', merged);
      }
    }
    if (section === 3) {
      const storeItems = store.getItem('template.case.studies') || [];
      if (isEdit) newItems = store.getItem('template.studiesNew') || [];

      if (storeItems?.length > 0) {
        const merged = [...storeItems, ...payload];
        store.setItem('template.case.studies', merged);
      } else {
        store.setItem('template.case.studies', payload);
      }

      if (isEdit) {
        const merged = [...newItems, ...payload];
        store.setItem('template.studiesNew', merged);
      }
    }
    if (section === 2) {
      const storeItems = store.getItem('template.testimonials') || [];
      if (isEdit) newItems = store.getItem('template.case.studiesNew') || [];

      if (storeItems?.length > 0) {
        const merged = [...storeItems, ...payload];
        store.setItem('template.testimonials', merged);
      } else {
        store.setItem('template.testimonials', payload);
      }

      if (isEdit) {
        const merged = [...newItems, ...payload];
        store.setItem('template.testimonialsNew', merged);
      }
    }

    unselectAll();
    navigate(-1);
  };

  return (
    <Stack
      direction='row'
      sx={{ mb: '1rem', alignItems: 'center', width: '100%' }}
    >
      <Filter variant='outlined'>
        <SearchInput
          source='customerOurProductsSearch'
          variant='outlined'
          alwaysOn
          resettable={true}
        />
      </Filter>
      <CustomButton
        variant='secondary'
        title={translate('resources.content.add_media_item')}
        handleClick={handleClick}
        startIcon={<AddIcon />}
        sx={{ width: '16rem', whiteSpace: 'nowrap' }}
      />
    </Stack>
  );
};

const AddTemplateProducts = ({ name, createUrl, section }) => {
  const translate = useTranslate();
  const navigate = useNavigate();

  const initialData = useContext(InitialDataContext);

  return (
    <Box sx={{ px: '1.5rem' }}>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.content.content')}
          handleClick={() => navigate(-1)}
        />
        <CustomButton
          variant='link'
          title={`${translate('common.add_item_to')} ${translate(
            'common.' + name
          )}`}
        />
      </Breadcrumbs>
      <Typography variant='h5'>
        {' '}
        {translate('common.add_item_to')} {translate('common.' + name)}
      </Typography>
      <Box
        variant='section-label'
        sx={{ mb: '3.25rem', color: 'neutral.light' }}
      >
        {translate('common.choose_items_to_add')}
      </Box>
      <ResourceContextProvider resource={RESOURCE}>
        <List
          actions={null}
          disableSyncWithLocation={true}
          filters={
            <OurProductsFilter createUrl={createUrl} section={section} />
          }
          filter={{ languageId: initialData.defaultLanguage }}
          resource={RESOURCE}
          empty={false}
          sx={{
            '& .MuiToolbar-root': { mb: '2rem !important' },
          }}
          canExpand={canExpand}
        >
          <Datagrid expand={<ExpandedItem />} bulkActionButtons={<></>}>
            <TextField
              source='name'
              label={translate('common.table.name_of_item')}
            />
            <DateField
              source='dateAdded'
              locales='de-DE'
              label={translate('common.table.date_added')}
            />
            <TextField
              source='category'
              label={translate('common.table.category')}
            />
            <TextField
              source='languageName'
              label={translate('common.table.category')}
            />
            <TextField
              source='publicOrPrivate'
              label={translate('common.table.private_public')}
            />
            <TextField
              source='mediaItemType'
              label={translate('common.table.media_item_type')}
            />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </Box>
  );
};

export default AddTemplateProducts;
