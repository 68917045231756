// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  useTranslate,
  ResourceContextProvider,
  AutocompleteArrayInput,
  useCreate,
  useList,
  ListContextProvider,
  TextField,
  Datagrid,
  useRefresh,
  useRecordContext,
  DateField,
  SearchInput,
  AutocompleteInput,
  FilterForm,
  List,
  localStorageStore,
} from 'react-admin';
import axios from '../../plugins/axios';
import { ToastContext } from '../../context/toastContext';
import { theme } from '../layout/themes';
import { InitialDataContext } from '../../context/initialDataContext';

// utils
import { removeFileFromArray } from '../../utils/removeFileFromArray';
import uploadThumb from '../../utils/uploadFileItemThumb';
import check from '../../utils/checkIfItemExists';

// components
import {
  Typography,
  Breadcrumbs,
  MenuItem,
  TextField as MuiTextField,
  Autocomplete,
  Stack,
  DialogActions,
  Dialog,
  DialogContent,
  Button,
} from '@mui/material';
import CustomToolbar from '../shared/CustomToolbar';
import CustomButton from '../shared/CustomButton';
import UploadIcon from '../shared/UploadIcon';
import UsernameWithImage from '../shared/UsernameWithImage';
import { RichTextInput } from 'ra-input-rich-text';
import CategoryField from '../shared/CategoryField';
import UrlField from '../shared/UrlField';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ListToolbar = () => {
  const translate = useTranslate();
  const initialData = useContext(InitialDataContext);

  const postFilters = [
    <SearchInput
      source='mediaItemListSearch'
      variant='outlined'
      alwaysOn
      resettable={true}
      sx={{ width: 250 }}
    />,
    <AutocompleteInput
      source='languageId'
      optionValue='id'
      variant='outlined'
      label={translate('common.language')}
      choices={initialData.languageList}
      sx={{ width: 250 }}
      alwaysOn
    />,
  ];

  return (
    <Stack
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      sx={{ mb: '3rem', mt: '-1px' }}
    >
      <FilterForm
        filters={postFilters}
        sx={{ '& .RaFilterFormInput-hideButton': { mb: 0 } }}
      />
      {/* <FilterButton filters={postFilters} sx={{ width: 130 }} /> */}
    </Stack>
  );
};

const ExpandPanel = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const listContext = useList({ data: record?.fileItems });

  return (
    <div className='media-catalog'>
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
          className='media-catalog'
          sx={{
            pb: 5,
          }}
        >
          <TextField source='name' label={translate('common.table.name')} />
          <TextField source='length' label={translate('common.table.length')} />

          <UrlField
            source={'downloadUrl'}
            label={translate('common.table.download_url')}
          />
        </Datagrid>
      </ListContextProvider>
    </div>
  );
};

function ImportModal({ isOpen, setModalOpen, close, save }) {
  // hooks
  const translate = useTranslate();
  const store = localStorageStore();

  const handleSave = () => {
    const selectedIds = store.getItem('single-media-item-list.selectedIds');
    save(selectedIds);
    close();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={close}
        maxWidth={'xl'}
        sx={{ minHeight: '400px' }}
      >
        <DialogContent>
          <Typography variant='h5' sx={{ mb: '3rem' }}>
            {translate('resources.content.list_of_media_items')}
          </Typography>
          <List
            actions={null}
            resource='single-media-item-list'
            filters={<ListToolbar />}
            filter={{ MediaItemTypeId: '1' }}
            filterDefaultValues={{ languageId: '1' }}
            sx={{
              '& .MuiToolbar-root': { mb: '2rem !important' },
              '& .MuiTablePagination-root': {
                mr: '1rem',
              },
            }}
          >
            <Datagrid
              sx={{
                boxShadow:
                  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                '& .RaDatagrid-headerCell': {
                  position: 'unset',
                },
                '& .RaBulkActionsToolbar-topToolbar': {
                  display: 'none',
                },
                '& .MuiToolbar-root': {
                  top: '-4px',
                },
              }}
              expand={<ExpandPanel />}
            >
              <UsernameWithImage
                label={translate('resources.content.name_of_item')}
              />
              <DateField
                source='dateAdded'
                locales='de-DE'
                label={translate('common.table.date_added')}
              />
              <CategoryField label={translate('common.table.category')} />
            </Datagrid>
          </List>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => handleSave()}>
            {translate('ra.action.save')}
          </Button>
          <Button onClick={close}>{translate('ra.action.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const MediaPackageCreate = () => {
  // state
  const [coverFile, setCoverFile] = useState();
  const [previewCoverFile, setPreviewCoverFile] = useState();
  const [fileItemThumbnailFile, setFileItemThumbnailFile] = useState();
  const [previewFileItemThumbnailFile, setPreviewFileItemThumbnailFile] =
    useState();
  const [animalsIncluded, setAnimalsIncluded] = useState([]);
  const [fileItemsList, setFileItemsList] = useState([]);
  const [selectTypeOfFileOptions, setSelectTypeOfFileOptions] = useState([]);
  const [fileItemName, setFileItemName] = useState();
  const [fileItemUrl, setFileItemUrl] = useState();
  const [fileItemMediaType, setFileItemMediaType] = useState(0);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [imageErrorMsg, setImageErrorMsg] = useState(false);
  const [fileItemListErrorMsg, setFileItemListErrorMsg] = useState(false);
  const [doesExist, setDoesExist] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // hooks
  const translate = useTranslate();
  const navigate = useNavigate();
  const [create] = useCreate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const listContext = useList({ data: fileItemsList });
  const initialData = useContext(InitialDataContext);
  const store = localStorageStore();

  // refs
  const hiddenCoverFileInput = useRef(null);
  const hiddenMediaFileThumbnailInput = useRef(null);

  const DeleteButton = () => {
    const record = useRecordContext();

    return (
      <CustomButton
        label={translate('common.table.actions')}
        variant='link'
        title={translate('buttons.delete')}
        handleClick={() => deleteFile(record)}
        sx={{ color: '#E74C3C', minWidth: '20px' }}
      />
    );
  };

  useEffect(() => {
    async function fetchTypeOfFileData() {
      const response = await axios('/file-item/media-types', { method: 'GET' });
      return response;
    }

    fetchTypeOfFileData().then((response) =>
      setSelectTypeOfFileOptions(response.data.items)
    );

    async function fetchAnimalsIncludedData() {
      const response = await axios('/media-item/animals-included-list', {
        method: 'GET',
      });
      return response;
    }

    fetchAnimalsIncludedData().then((response) => {
      setAnimalsIncluded(response.data);
    });
  }, [initialData.defaultLanguage]);

  useEffect(() => {
    if (!coverFile) {
      setPreviewCoverFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(coverFile);
    setPreviewCoverFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [coverFile]);

  useEffect(() => {
    if (!fileItemThumbnailFile) {
      setPreviewFileItemThumbnailFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileItemThumbnailFile);
    setPreviewFileItemThumbnailFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileItemThumbnailFile]);

  const handleCoverClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const handleMediaFileThumbnailClick = (event) => {
    hiddenMediaFileThumbnailInput.current.click();
  };

  const handleCoverUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCoverFile(undefined);
      return;
    }
    setImageErrorMsg(false);
    const fileUploaded = event.target.files[0];
    setCoverFile(fileUploaded);
  };

  const handleFileItemThumbnailUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setFileItemThumbnailFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setFileItemThumbnailFile(fileUploaded);
  };

  const addToFileItems = () => {
    const mediaType = selectTypeOfFileOptions.filter(
      (item) => item.id === fileItemMediaType
    );
    setFileItemsList([
      ...fileItemsList,
      {
        isFromCatalog: false,
        id: Math.floor(Math.random() * 135) + fileItemName,
        name: fileItemName,
        url: fileItemUrl,
        thumbObj: fileItemThumbnailFile,
        coverImage: fileItemThumbnailFile
          ? URL.createObjectURL(fileItemThumbnailFile)
          : '',
        mediaTypeId: fileItemMediaType,
        mediaTypeName: mediaType[0].name,
        isPublic: true,
        isDefault: true,
      },
    ]);
    setFileItemListErrorMsg(false);
    setPreviewFileItemThumbnailFile(undefined);
    setFileItemThumbnailFile(undefined);
    setFileItemUrl('');
    setFileItemName('');
    hiddenMediaFileThumbnailInput.current.value = '';
  };

  const handleCatalogFiles = (payload) => {
    const filteredArray = fileItemsList.filter(
      (obj) => !payload.some((item) => item.id === obj.id)
    );

    setFileItemsList([...filteredArray, ...payload]);
    store.removeItem('single-media-item-list.selectedIds');
  };

  const handleImport = (payload) => {
    const selectedIds = store.getItem('single-media-item-list.selectedIds');
    const baseUrl = 'media-item/file-items-by-media-item-ids';
    const params = payload.map((id) => `ids=${id}`);
    const urlWithParams = `${baseUrl}?${params.join('&')}`;
    const importList = [];

    async function getItems() {
      const response = await axios(urlWithParams, {
        method: 'GET',
      });

      return response;
    }

    getItems().then((response) => {
      const sortedArray = [];
      selectedIds.forEach((id) => {
        response.data.forEach((item) => {
          if (id === item.mediaItemId) {
            sortedArray.push(item);
          }
        });
      });

      sortedArray.forEach((item) => {
        importList.push({
          ...item,
          isFromCatalog: true,
          coverImage: item.thumbnail,
        });
      });

      handleCatalogFiles(importList);
    });
  };

  const deleteFile = (record) => {
    setFileItemsList(removeFileFromArray(fileItemsList, record.id));
    refresh();
  };

  const saveMediaItem = async (data) => {
    const error = await check(data.name);

    setDoesExist(error.data);

    if (!coverFile || error.data || fileItemsList.length === 0) {
      if (!coverFile) setImageErrorMsg(true);
      if (fileItemsList.length === 0) setFileItemListErrorMsg(true);
      return;
    }

    if (coverFile) {
      try {
        let coverImageResponse = '';

        const coverFormData = new FormData();

        coverFormData.append('file', coverFile);

        coverImageResponse = await axios.post(
          '/media-item/upload-cover-image',
          coverFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        data.downloadVersion = true;
        data.fullVersion = true;
        data.coverImage = coverFile ? coverImageResponse.data : '';
        data.coverImageOriginal = coverFile ? coverImageResponse.data : '';
        data.mediaItemType = 2;
        data.languageId = initialData.defaultLanguage;
        data.languagesIncluded = autoCompleteValue;
        data.summary = '';
        data.summaryOriginal = '';

        const transformedFileItemList = fileItemsList.slice();

        for (const file of transformedFileItemList) {
          file.thumbnail = file.thumbObj
            ? await uploadThumb(file.thumbObj)
            : coverImageResponse.data;
        }
        data.fileItems = transformedFileItemList.map((item) => {
          return {
            id: /[a-zA-Z]/.test(item.id) ? 0 : item.id,
            isFromCatalog: item.isFromCatalog,
            isDefault: true,
            isPublic: true,
            mediaTypeId: item.mediaTypeId,
            mediaTypeName: item.mediaTypeName,
            name: item.name,
            url: item.url,
            thumbnail: item.thumbnail,
          };
        });
        delete data.fileName;
        delete data.typeOfFile;
        delete data.mediaType;
        create('media-item', { data }, { onError, onSuccess });
        transformedFileItemList.forEach((item) => {
          URL.revokeObjectURL(item.coverImage);
        });
      } catch (err) {
        toast.error(translate('notifications.error'), err.message);
        console.log(err);
      }
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    toast.success(
      translate('notifications.success'),
      `${translate('resources.content.movie_package')} ${data.name} ${translate(
        'notifications.created'
      )}`
    );

    navigate('/content/media-catalog');
    refresh();
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.content.content')}
          handleClick={() => navigate('/content/media-catalog')}
        />
        <CustomButton
          variant='link'
          title={translate('resources.content.add_movie_package')}
        />
      </Breadcrumbs>
      <Typography variant='h5' sx={{ paddingLeft: 3 }}>
        {translate('resources.content.add_movie_package')}
      </Typography>
      <ResourceContextProvider resource='media-item'>
        <Create
          resource='media-item,'
          className='media-package-create'
          sx={{
            '& .MuiCard-root': { boxShadow: 'none', pb: 3, px: 1 },
          }}
        >
          <SimpleForm
            toolbar={
              <CustomToolbar
                sx={{ marginLeft: '16px !important' }}
                handleClick={() => navigate('/content/media-catalog')}
              />
            }
            onSubmit={saveMediaItem}
          >
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
              {translate('resources.content.description')}
            </Typography>
            <TextInput
              source='name'
              label={translate('resources.content.title')}
              validate={required()}
              sx={{ width: '60%', mb: 1 }}
              onBlur={(e) =>
                check(e.target.value).then((response) => {
                  setDoesExist(response.data);
                })
              }
            />
            {doesExist && (
              <div
                style={{
                  color: '#E74C3C',
                  marginTop: '-30px',
                  fontSize: '0.8rem',
                  padding: '0 0 8px 14px',
                }}
              >
                {translate('notifications.check_media_item_name_error_msg')}
              </div>
            )}
            <div className='media-item-description' style={{ width: '60%' }}>
              <RichTextInput
                source='description'
                multiline
                maxRows={8}
                label={translate('resources.content.description')}
                validate={required()}
                className='custom-rich-text-input'
              />
            </div>
            <div className='form-css' style={{ width: '100%' }}>
              <Autocomplete
                multiple
                id='tags-outlined'
                size='small'
                options={[]}
                disablePortal={true}
                value={autoCompleteValue}
                onChange={(e, newval, reason) => {
                  setAutoCompleteValue(newval);
                }}
                sx={{
                  width: '60%',
                  mb: 1,
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    variant='outlined'
                    label={`${translate(
                      'resources.content.available_languages'
                    )}`}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.target.value) {
                        e.preventDefault();
                        setAutoCompleteValue(
                          autoCompleteValue.concat(e.target.value)
                        );
                      }
                    }}
                  />
                )}
              />
            </div>
            <Typography variant='h6' sx={{ fontSize: '1rem', my: 3 }}>
              {translate('resources.content.media_item_cover_image')}
            </Typography>
            <div style={{ display: 'flex', marginBottom: 38 }}>
              <UploadIcon />
              <CustomButton
                title={translate('common.click_to_upload')}
                variant='ghost'
                style={{ marginLeft: 4, marginRight: 4 }}
                handleClick={handleCoverClick}
              />
              <input
                type='file'
                accept='image/*'
                ref={hiddenCoverFileInput}
                onChange={handleCoverUploadChange}
                style={{ display: 'none' }}
              />

              {coverFile && (
                <img
                  src={previewCoverFile}
                  alt='cover'
                  style={{ height: '6.3rem' }}
                />
              )}
            </div>
            {imageErrorMsg && (
              <p
                style={{
                  color: '#E74C3C',
                  marginTop: '-25px',
                  fontSize: '0.8rem',
                  marginLeft: '0.9rem',
                }}
              >
                {translate('general_settings.required')}
              </p>
            )}
            <AutocompleteArrayInput
              source='animalsIncluded'
              label={translate('resources.content.showcased_animals')}
              icon={<KeyboardArrowDownIcon />}
              sx={{ width: '60%' }}
              choices={animalsIncluded}
              validate={required()}
              optionText='name'
              optionValue='id'
              size='small'
            />

            <div
              style={{
                margin: '1.5rem 0',
                borderTop: '1px solid #D7D7D7',
                height: '1px',
                width: '100%',
              }}
            ></div>
            <div style={{ display: 'flex' }}>
              <BooleanInput
                label={translate(
                  'resources.content.publish_to_media_catalogue'
                )}
                source='isPublic'
                sx={{
                  mr: 2,
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
              <BooleanInput
                label={translate('resources.content.mark_as_new')}
                source='isNew'
                sx={{
                  mr: 2,
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
              <BooleanInput
                label={translate('resources.content.add_to_case_studies')}
                source='isCaseStudy'
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            </div>
            <div
              style={{
                margin: '0 0 1.5rem 0',
                borderTop: '1px solid #D7D7D7',
                height: '1px',
                width: '100%',
              }}
            ></div>
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
              {translate('resources.content.file_item')}
            </Typography>
            <CustomButton
              handleClick={() => {
                setDialogOpen(true);
              }}
              variant='secondary'
              title={translate('common.choose_from_catalogue')}
            />
            <Typography variant='h6' sx={{ fontSize: '1rem', mt: 5 }}>
              {translate('common.add_files_from_bunny')}
            </Typography>
            <Typography variant='h7' sx={{ fontSize: '0.9rem', mt: 2, mb: 3 }}>
              {translate('resources.content.file_item_thumbnail')}
            </Typography>
            <div style={{ display: 'flex', marginBottom: 38 }}>
              <UploadIcon />
              <CustomButton
                title={translate('common.click_to_upload')}
                variant='ghost'
                style={{ marginLeft: 4, marginRight: 4 }}
                handleClick={handleMediaFileThumbnailClick}
              />
              <input
                type='file'
                accept='image/*'
                ref={hiddenMediaFileThumbnailInput}
                onChange={handleFileItemThumbnailUploadChange}
                style={{ display: 'none' }}
              />

              {fileItemThumbnailFile && (
                <img
                  src={previewFileItemThumbnailFile}
                  alt={fileItemThumbnailFile.name}
                  style={{ height: '6.3rem' }}
                />
              )}
            </div>
            <MuiTextField
              value={fileItemName}
              label={translate('resources.content.file_name')}
              onChange={(e) => setFileItemName(e.target.value)}
              validate={required()}
              sx={{ width: '60%', mb: 3 }}
              size='small'
            />

            <MuiTextField
              value={fileItemUrl}
              id='outlined-multiline-flexible'
              onChange={(e) => setFileItemUrl(e.target.value)}
              label={translate('resources.content.url')}
              validate={required()}
              sx={{ width: '60%', mb: 3 }}
              size='small'
            />
            <div
              style={{
                display: 'flex',
                width: '100%',
                paddingBottom: '30px',
                alignItems: 'center',
              }}
            >
              <MuiTextField
                value={fileItemMediaType}
                select
                defaultValue={''}
                onChange={(e) => setFileItemMediaType(e.target.value)}
                label={translate('resources.content.media_type')}
                icon={<KeyboardArrowDownIcon />}
                sx={{ width: '30%', height: '40px', mr: '40px' }}
                choices={selectTypeOfFileOptions}
                validate={required()}
                optionText='name'
                optionValue='id'
                size='small'
              >
                {selectTypeOfFileOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </MuiTextField>

              <CustomButton
                variant='primary'
                title={translate('buttons.add_file_item')}
                handleClick={addToFileItems}
                sx={{ mb: '-4px' }}
                disabled={
                  fileItemName &&
                  fileItemUrl &&
                  fileItemMediaType &&
                  fileItemThumbnailFile
                    ? false
                    : true
                }
              />
            </div>
            {fileItemListErrorMsg && (
              <p
                style={{
                  color: '#E74C3C',
                  marginTop: '-25px',
                  fontSize: '0.84rem',
                  marginLeft: '0.9rem',
                }}
              >
                {translate('resources.content.media_item_must')}
              </p>
            )}
            <ListContextProvider value={listContext}>
              <Datagrid bulkActionButtons={false}>
                <UsernameWithImage label={translate('common.table.name')} />
                <TextField source='url' />
                <TextField source='mediaTypeName' />
                <DeleteButton />
              </Datagrid>
            </ListContextProvider>
          </SimpleForm>
        </Create>
      </ResourceContextProvider>
      <ImportModal
        isOpen={dialogOpen}
        setModalOpen={setDialogOpen}
        close={() => {
          setDialogOpen(false);
          store.removeItem('single-media-item-list.selectedIds');
        }}
        save={handleImport}
      />
    </>
  );
};

export default MediaPackageCreate;
