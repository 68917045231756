import { useState, useRef, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import CustomButton from '../shared/CustomButton';
import UploadIcon from '../shared/UploadIcon';

export default function SubCategoryListItem({ inputs, id, updateInputs }) {
  const [subCategoryFile, setSubCategoryFile] = useState();
  const [previewSubCategoryFile, setPreviewSubCategoryFile] = useState();

  const hiddenSubCategoryFileInput = useRef(null);
  const translate = useTranslate();

  useEffect(() => {
    if (!subCategoryFile) {
      setPreviewSubCategoryFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(subCategoryFile);

    setPreviewSubCategoryFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [subCategoryFile]);

  const handleSubCategoryImageClick = (event) => {
    hiddenSubCategoryFileInput.current.click();
  };

  const handleUploadSubCategoryImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setSubCategoryFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setSubCategoryFile(fileUploaded);
    updateInputs(fileUploaded, id);
  };
  return (
    <div style={{ display: 'flex', marginBottom: 38, marginLeft: 32 }}>
      <UploadIcon />
      <CustomButton
        title={translate('common.click_to_upload')}
        variant='ghost'
        style={{ marginLeft: 4, marginRight: 4 }}
        handleClick={handleSubCategoryImageClick}
      />
      <input
        type='file'
        accept='image/*'
        ref={hiddenSubCategoryFileInput}
        onChange={handleUploadSubCategoryImageChange}
        style={{ display: 'none' }}
      />
      {subCategoryFile && (
        <img
          src={previewSubCategoryFile}
          alt={translate('common.profile_image')}
          style={{ height: '6.3rem' }}
        />
      )}
    </div>
  );
}
