// library modules
import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTranslate,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  email,
  useGetList,
  ImageField,
  useUpdate,
  useRefresh,
  useRecordContext,
} from 'react-admin';
import axios from '../../plugins/axios';
import { ToastContext } from '../../context/toastContext';

// components
import { Typography, Breadcrumbs, TextField } from '@mui/material';
import CustomButton from '../shared/CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UploadIcon from '../shared/UploadIcon';
import CustomToolbar from '../shared/CustomToolbar';

// utils
import { createPassword } from '../../utils/passwordGenerator';
import { UserContext } from '../../context/userContext';

const UserRole = ({ user, selectOptions }) => {
  const record = useRecordContext();
  const translate = useTranslate();

  return user?.roleName === 'SuperAdmin' && user?.id !== record.id ? (
    <SelectInput
      source='roleId'
      icon={<KeyboardArrowDownIcon />}
      sx={{ width: '30%', height: '40px' }}
      choices={selectOptions}
      optionText='name'
      optionValue='id'
    />
  ) : (
    <TextInput
      source='roleName'
      disabled
      label={translate('general_settings.role')}
      sx={{ width: '60%', mb: 1 }}
    />
  );
};

const SettingsEdit = () => {
  // state
  const [previewProfileFile, setPreviewProfileFile] = useState();
  const [profileFile, setProfilefile] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);

  // hooks
  const translate = useTranslate();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const { data } = useGetList('admin-user-roles');
  const { toast } = useContext(ToastContext);
  const { user } = useContext(UserContext);

  // refs
  const passwordInput = useRef(null);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    setSelectOptions(data);
  }, [data]);

  useEffect(() => {
    if (!profileFile) {
      setPreviewProfileFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(profileFile);
    setPreviewProfileFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [profileFile]);
  const generatePasword = () => {
    passwordInput.current.value = createPassword(16);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      profileFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setProfilefile(fileUploaded);
  };

  const updateUser = async (data) => {
    let imageUrl = '';

    if (passwordInput.current.value) {
      if (
        passwordInput.current.value.match(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?]).{6,}/
        )
      ) {
        setPasswordErrorMsg(false);
      } else {
        setPasswordErrorMsg('validation');
        return;
      }
    }

    try {
      if (previewProfileFile) {
        const formData = new FormData();
        formData.append('file', profileFile);

        const response = await axios.post(
          '/admin-user/upload-image',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
        imageUrl = response.data;
      }

      data.profileImagePath = imageUrl || data.profileImagePath;
      data.profileImagePathOriginal = imageUrl || data.profileImagePathOriginal;
      data.password = passwordInput.current.value;

      update('admin-user', { data }, { onSuccess, onError });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);
      console.log(err);
    }
  };

  const onError = (data) => {
    toast.error(
      translate('notifications.error'),
      data.response.data.error.message
    );
  };

  const onSuccess = (data) => {
    toast.success(
      translate('notifications.success'),
      `${translate('general_settings.user')} ${data.firstName} ${translate(
        'notifications.edited'
      )}`
    );

    navigate('/admin-user');
    refresh();
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('general_settings.main_settings')}
          handleClick={() => navigate('/admin-user')}
        />
        <CustomButton
          variant='link'
          title={translate('general_settings.edit_user')}
        />
      </Breadcrumbs>
      <Typography variant='h4' sx={{ paddingLeft: 3 }}>
        {translate('general_settings.edit_user')}
      </Typography>
      <Edit className='edit-user' sx={{ px: 1 }}>
        <SimpleForm
          toolbar={
            <CustomToolbar
              handleClick={() => navigate('/admin-user')}
              isEdit={true}
              sx={{ ml: 3 }}
            />
          }
          onSubmit={updateUser}
        >
          <TextInput
            source='name'
            label={translate('general_settings.user_name')}
            sx={{ width: '60%', mb: 1 }}
          />

          <TextInput
            source='email'
            type='email'
            disabled
            validate={[email()]}
            label={translate('general_settings.user_email')}
            sx={{ width: '60%', mb: 1 }}
          />
          <div
            style={{
              margin: '0 0 1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>
          <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
            {translate('general_settings.user_role')}
          </Typography>
          <UserRole user={user} selectOptions={selectOptions} />
          <div
            style={{
              margin: '1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>
          <div
            style={{
              width: '60%',
              position: 'relative',
            }}
          >
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 6 }}>
              {translate('general_settings.user_password')}
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 2,
                marginTop: '-22px',
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                size='small'
                source='password'
                type='text'
                label='Type password or auto generate'
                inputRef={passwordInput}
                sx={{
                  width: '70%',
                  zIndex: 0,
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderWidth: 1,
                    },
                  },
                  '& .MuiInputBase-root': {
                    flexBasis: 'auto',
                  },
                }}
              />
              <CustomButton
                title={translate('buttons.auto_generate_password')}
                variant='primary'
                handleClick={generatePasword}
                sx={{ width: 240, ml: 5 }}
              />
            </div>
            {passwordErrorMsg && (
              <p
                style={{
                  color: '#E74C3C',
                  marginTop: '3px',
                  fontSize: '0.8rem',
                  marginLeft: '0.9rem',
                }}
              >
                {translate('common.password_validation')}
              </p>
            )}
          </div>

          <div
            style={{
              margin: '1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>

          <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
            {translate('common.profile_image')}
          </Typography>

          <div style={{ display: 'flex', marginBottom: 38 }}>
            <UploadIcon />
            <CustomButton
              title={translate('common.click_to_upload')}
              variant='ghost'
              style={{ marginLeft: 4, marginRight: 4 }}
              handleClick={handleClick}
            />
            <input
              type='file'
              accept='image/*'
              ref={hiddenFileInput}
              onChange={handleUploadChange}
              style={{ display: 'none' }}
            />
            {!previewProfileFile ? (
              <ImageField
                source='profileImagePath'
                title='avatar'
                sx={{
                  '& .RaImageField-image': {
                    width: '6.3rem',
                    height: '6.3rem',
                  },
                }}
              />
            ) : (
              <img
                src={previewProfileFile}
                alt='avatar'
                style={{ height: '6.3rem' }}
              />
            )}
          </div>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default SettingsEdit;
