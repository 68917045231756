import { useTranslate, useRecordContext } from 'react-admin';

const UrlField = ({ source = 'url' }) => {
  const record = useRecordContext();
  const translate = useTranslate();

  return record[source] ? (
    <a
      href={record[source]}
      target='_blank'
      rel='noreferrer'
      className='custom-link'
    >
      {translate(`common.link`)}
    </a>
  ) : null;
};
export default UrlField;
