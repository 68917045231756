// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  required,
  email,
  useTranslate,
  useCreate,
  useRefresh,
  Datagrid,
  ListContextProvider,
  useList,
  TextField as RaTextField,
  useRecordContext,
} from 'react-admin';

import axios from '../../../plugins/axios';

// utils
import { ToastContext } from '../../../context/toastContext';
import { createPassword } from '../../../utils/passwordGenerator';
import check from '../../../utils/checkEmail';
import { removeFileFromArray } from '../../../utils/removeFileFromArray';

// components
import {
  Typography,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import CustomButton from '../../shared/CustomButton';
import UploadIcon from '../../shared/UploadIcon';
import CustomerUrlModal from './CustomerUrlModal';
import CustomToolbar from '../../shared/CustomToolbar';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// css
import { theme } from '../../layout/themes';

const CompanyInformation = ({ basePath }) => {
  // state
  const [profileFile, setProfileFile] = useState();
  const [previewProfileFile, setPreviewProfileFile] = useState();
  const [coverFile, setCoverFile] = useState();
  const [previewCoverFile, setPreviewCoverFile] = useState();
  const [selectCustomerTypeOptions, setSelectCustomerTypeOptions] = useState(
    []
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userData, setUserData] = useState();
  const [userObject, setUserObject] = useState();
  const [selectLanguageIdOptions, setSelectlanguageOptions] = useState([]);
  const [isPassworedVisible, setIsPassworedVisible] = useState(false);
  const [passwordErrMsg, setPasswordErrMsg] = useState(false);
  const [reload] = useState(false);
  const [errorCheckMsg, setErrorCheckMsg] = useState();
  const [emptyPass, setEmptyPass] = useState(false);
  const [profileImageError, setProfileImageError] = useState(false);
  const [coverImageError, setCoverImageError] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [doesExist, setDoesExist] = useState(false);

  // hooks
  const translate = useTranslate();
  const [create] = useCreate();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const emailListContext = useList({ data: emailsList });

  //refs
  const passwordInput = useRef(null);
  const hiddenProfileFileInput = useRef(null);
  const hiddenCoverFileInput = useRef(null);
  const emailInput = useRef(null);
  const companyNameInput = useRef(null);

  var slugify = require('slugify');

  useEffect(() => {
    if (!profileFile) {
      setPreviewProfileFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(profileFile);
    setPreviewProfileFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [profileFile]);

  useEffect(() => {
    if (!coverFile) {
      setPreviewCoverFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(coverFile);
    setPreviewCoverFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [coverFile]);

  useEffect(() => {
    async function fetchLanguageData() {
      const response = await axios('/customer/languages', {
        method: 'GET',
      });
      return response;
    }

    async function fetchCustomerTemplateData() {
      const response = await axios('/template/get-filtered-list', {
        method: 'POST',
        data: {
          maxResultCount: 1000,
          sorting: 'name ASC',
        },
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      });
      return response;
    }

    fetchLanguageData().then((response) =>
      setSelectlanguageOptions(response.data.items)
    );

    fetchCustomerTemplateData().then((response) => {
      setSelectCustomerTypeOptions(response.data.items);
    });
  }, [reload]);

  const handleProfileImageClick = (event) => {
    hiddenProfileFileInput.current.click();
  };

  const handleCoverFileClick = (event) => {
    hiddenCoverFileInput.current.click();
  };

  const handleUploadProfileImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      profileFile(undefined);
      return;
    }
    setProfileImageError(false);
    const fileUploaded = event.target.files[0];
    setProfileFile(fileUploaded);
  };

  const handleUploadCoverImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      coverFile(undefined);
      return;
    }
    setCoverImageError(false);
    const fileUploaded = event.target.files[0];
    setCoverFile(fileUploaded);
  };

  const generatePasword = () => {
    passwordInput.current.value = createPassword(16);
  };

  const saveCustomer = async (data) => {
    let profileImageResponse = '';
    let coverImageResponse = '';
    const error = await check(data.email);

    setDoesExist(error.data);

    if (passwordInput.current?.value && data.isActive) {
      if (
        passwordInput.current.value.match(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#$@!%&*?]).{6,}/
        )
      ) {
        setPasswordErrMsg(false);
      } else {
        setPasswordErrMsg(true);
        return;
      }
    }

    if (!passwordInput.current?.value && data.isActive) {
      setEmptyPass(true);
      return;
    }

    if (!profileFile || !coverFile || error.data) {
      if (!profileFile) setProfileImageError(true);
      if (!coverFile) setCoverImageError(true);
      return;
    }
    if (profileFile && coverFile) {
      try {
        const imageFormData = new FormData();
        imageFormData.append('file', profileFile);

        profileImageResponse = await axios.post(
          '/customer/upload-image',
          imageFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        const coverFormData = new FormData();
        coverFormData.append('file', coverFile);

        coverImageResponse = await axios.post(
          '/customer/upload-image',
          coverFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        const transformed = emailsList.map((item) => {
          if (item.emailAddress === item.id) delete item.id;

          return item;
        });

        data.slug = slugify(data.companyName, {
          replacement: '-',
          remove: undefined,
          lower: true,
          strict: true,
          trim: true,
        });
        data.logoPath = profileImageResponse.data;
        data.coverPath = coverImageResponse.data;
        data.emails = transformed;
        data.password = data.isActive ? passwordInput.current.value : null;

        setUserObject(data);

        create('customer', { data }, { onError, onSuccess });
      } catch (err) {
        toast.error(translate('notifications.error'), err.message);
      }
    }
  };
  const onError = (data) => {
    toast.error(
      translate('notifications.error'),
      data.response.data.error.message
    );
  };

  const onSuccess = (res) => {
    setUserData(res);
    setDialogOpen(true);
  };

  const deleteFile = (record) => {
    setEmailsList(removeFileFromArray(emailsList, record.id));
    refresh();
  };

  const DeleteButton = () => {
    const record = useRecordContext();

    return (
      <CustomButton
        label={translate('common.table.actions')}
        variant='link'
        title={translate('buttons.delete')}
        handleClick={() => deleteFile(record)}
        sx={{ color: '#E74C3C', minWidth: '20px' }}
      />
    );
  };

  const AddEmailModal = ({ isOpenEmail, close }) => {
    const translate = useTranslate();
    const [emailErrValid, setEmailErrValid] = useState(false);
    const [emailAddress, setEmailAddress] = useState();
    const [contactPerson, setContactPerson] = useState();
    // const [checked, setChecked] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [contactError, setContactError] = useState(false);

    const save = () => {
      if (!emailAddress || !contactPerson) {
        if (!emailAddress) setEmailError(true);
        if (!contactPerson) setContactError(true);
        return;
      }

      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

      const isValidEmail = validateEmail(emailAddress);

      if (isValidEmail === null) {
        setEmailErrValid(true);
        return;
      }

      setEmailError(true);
      setContactError(true);

      setEmailsList([
        ...emailsList,
        {
          id: emailAddress,
          emailAddress: emailAddress,
          contactPerson: contactPerson,
          isDefault: false,
          // notificationsEnabled: checked,
        },
      ]);

      setIsOpen(false);
    };
    return (
      <Dialog open={isOpen} onClose={close} sx={{ minHeight: '400px' }}>
        <DialogContent>
          <Typography variant='h6' sx={{ mb: 3 }}>
            {translate(`resources.customer.additional_email`)}
          </Typography>

          <TextField
            value={emailAddress}
            type='email'
            validate={[required(), email()]}
            label={translate('resources.customer.contact_email')}
            sx={{ width: '100%', mb: 4 }}
            size='small'
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          {emailError && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-25px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('general_settings.required')}
            </p>
          )}
          {emailErrValid && (
            <div
              style={{
                color: '#E74C3C',
                marginTop: '-30px',
                fontSize: '0.8rem',
                padding: '0 0 8px 14px',
              }}
            >
              {translate('common.email_is_not_valid')}
            </div>
          )}
          <TextField
            value={contactPerson}
            type='text'
            validate={[required()]}
            label={translate('common.contact_person')}
            sx={{ width: '100%', mb: 3 }}
            size='small'
            onChange={(e) => setContactPerson(e.target.value)}
          />
          {contactError && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-25px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('general_settings.required')}
            </p>
          )}
          {/* <FormControlLabel
            value='start'
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            sx={{ color: 'primary.main' }}
            label={translate('resources.customer.enable_notification')}
            labelPlacement='end'
          /> */}
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => save()}>
            {translate('ra.action.save')}
          </Button>
          <Button onClick={close}>{translate('ra.action.close')}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Typography
        variant='h6'
        sx={{ fontSize: '1rem', px: '24px', mt: '2rem' }}
      >
        {translate('resources.customer.customers_informations')}
      </Typography>
      <Create
        resource='customer'
        className='add-customer'
        sx={{
          '& .MuiCard-root': { boxShadow: 'none', pb: 3, px: 1 },
        }}
      >
        <SimpleForm
          toolbar={
            <CustomToolbar
              handleClick={() => navigate('/customers/list')}
              sx={{ marginLeft: '16px !important' }}
            />
          }
          onSubmit={saveCustomer}
        >
          <TextInput
            source='companyName'
            inputRef={companyNameInput}
            label={translate('resources.customer.add_company_name')}
            validate={required()}
            sx={{ width: '60%', mb: 1 }}
          />

          <div style={{ width: '100%' }}>
            <TextInput
              source='email'
              inputRef={emailInput}
              type='email'
              validate={[required(), email()]}
              label={translate('resources.customer.add_company_email')}
              sx={{ width: '60%', mb: 1 }}
              onBlur={(e) =>
                check(e.target.value).then((response) => {
                  setDoesExist(response.data);
                })
              }
            />
            {doesExist && (
              <div
                style={{
                  color: '#E74C3C',
                  marginTop: '-30px',
                  fontSize: '0.8rem',
                  padding: '0 0 8px 14px',
                }}
              >
                {translate('resources.customer.email_exists')}
              </div>
            )}
            {emailsList.length <= 2 && (
              <IconButton
                aria-label='more'
                aria-controls='long-menu'
                aria-haspopup='true'
                onClick={() => setIsOpen(true)}
                sx={{ ml: 1 }}
              >
                <AddCircleIcon sx={{ fontSize: 22, color: 'primary.main' }} />
              </IconButton>
            )}
          </div>

          <TextInput
            source='contactPerson'
            inputRef={companyNameInput}
            label={translate('common.contact_person')}
            validate={required()}
            sx={{ width: '60%', mb: 1 }}
          />
          {emailsList.length > 0 && (
            <ListContextProvider value={emailListContext}>
              <Datagrid bulkActionButtons={false} sx={{ width: '100%', mb: 5 }}>
                <RaTextField
                  source='emailAddress'
                  label={translate('common.table.email')}
                />
                <RaTextField
                  source='contactPerson'
                  label={translate('common.contact_person')}
                />
                {/* <RaTextField
                  source='notificationsEnabled'
                  label={translate('resources.customer.notification_enabled')}
                /> */}
                <DeleteButton label={translate('common.table.action')} />
              </Datagrid>
            </ListContextProvider>
          )}
          <div
            style={{
              margin: '0 0 1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>
          <div
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: '20px',
              gridColumnStart: 'auto',
            }}
          >
            <div>
              <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
                {translate('resources.template.apply_template')}
              </Typography>

              <SelectInput
                source='customerTypeId'
                icon={<KeyboardArrowDownIcon />}
                sx={{ height: '40px', mt: 0, width: '100%' }}
                choices={selectCustomerTypeOptions}
                optionText='name'
                optionValue='id'
              />
            </div>
            <div>
              <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
                {translate('common.language')}
              </Typography>
              <SelectInput
                source='languageId'
                icon={<KeyboardArrowDownIcon />}
                sx={{ height: '40px', mt: 0, width: '100%' }}
                choices={selectLanguageIdOptions}
                optionText='name'
                optionValue='id'
              />
            </div>
            <div>
              <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
                {translate('common.country')}
              </Typography>
              <TextInput
                source='country'
                label={translate('common.country')}
                sx={{ width: '100%' }}
              />
            </div>
          </div>
          <div
            style={{
              margin: '1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>
          <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
            {translate('resources.customer.showtime_licence')}
          </Typography>
          <TextInput
            source='showtimeLicense'
            label={translate('resources.customer.showtime_licence')}
            sx={{ width: '60%' }}
          />
          <div
            style={{
              margin: '1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>

          {isPassworedVisible ? (
            <div
              style={{
                display: 'flex',
                position: 'relative',
                justifyContent: 'end',
                alignItems: 'center',
                // flexGrow: 2,
                marginBottom: '24px',
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                source='link'
                defaultValue={passwordInput.current?.value}
                type='text'
                size='small'
                label='Type password or auto generate'
                inputRef={passwordInput}
                sx={{
                  width: '400px',
                }}
              />

              <CustomButton
                title={translate('buttons.auto_generate_password')}
                variant='primary'
                handleClick={generatePasword}
                sx={{ width: 240, ml: 5 }}
              />
            </div>
          ) : null}

          {passwordErrMsg && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-20px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('common.password_validation')}
            </p>
          )}
          {emptyPass && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-20px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('common.password_empty')}
            </p>
          )}

          <BooleanInput
            label={translate('resources.customer.active_user')}
            source='isActive'
            onChange={(value) => {
              if (!value.target.checked) {
                passwordInput.current.value = null;
              }
              setIsPassworedVisible(value.target.checked);
            }}
            sx={{
              mr: 2,
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
          {/* </div> */}
          <BooleanInput
            label={translate('resources.customer.activate_wild_factory')}
            source='hasWildFactory'
            sx={{
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
          <div
            style={{
              margin: '0 0 1.5rem 0',
              borderTop: '1px solid #D7D7D7',
              height: '1px',
              width: '100%',
            }}
          ></div>

          <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
            {translate('common.customer_logo')}
          </Typography>

          <div style={{ display: 'flex', marginBottom: 38 }}>
            <UploadIcon />
            <CustomButton
              title={translate('common.click_to_upload')}
              variant='ghost'
              style={{ marginLeft: 4, marginRight: 4 }}
              handleClick={handleProfileImageClick}
            />
            <input
              type='file'
              accept='image/*'
              ref={hiddenProfileFileInput}
              onChange={handleUploadProfileImageChange}
              style={{ display: 'none' }}
            />
            {profileFile && (
              <img
                src={previewProfileFile}
                alt={translate('common.profile_image')}
                style={{ height: '6.3rem' }}
              />
            )}
          </div>
          {profileImageError && (
            <p
              style={{
                color: '#E74C3C',
                marginTop: '-25px',
                fontSize: '0.8rem',
                marginLeft: '0.9rem',
              }}
            >
              {translate('general_settings.required')}
            </p>
          )}
          <div style={{ display: 'flex' }}>
            <div>
              <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
                {translate('common.cover_image')}
              </Typography>
              <div style={{ display: 'flex', marginBottom: 38 }}>
                <UploadIcon />
                <CustomButton
                  title={translate('common.click_to_upload')}
                  variant='ghost'
                  style={{ marginLeft: 4, marginRight: 4 }}
                  handleClick={handleCoverFileClick}
                />
                <input
                  type='file'
                  accept='image/*'
                  ref={hiddenCoverFileInput}
                  onChange={handleUploadCoverImageChange}
                  style={{ display: 'none' }}
                />

                {coverFile && (
                  <img
                    src={previewCoverFile}
                    alt={translate('common.cover_image')}
                    style={{ height: '6.3rem' }}
                  />
                )}
              </div>
              {coverImageError && (
                <p
                  style={{
                    color: '#E74C3C',
                    marginTop: '-25px',
                    fontSize: '0.8rem',
                    marginLeft: '0.9rem',
                  }}
                >
                  {translate('general_settings.required')}
                </p>
              )}
            </div>{' '}
            <div style={{ marginLeft: '4rem' }}>
              <Typography variant='h6' sx={{ fontSize: '1rem', mb: 3 }}>
                {translate('common.cover_position')}
              </Typography>
              <SelectInput
                source='coverPosition'
                icon={<KeyboardArrowDownIcon />}
                sx={{ height: '40px', mt: '-6', width: '100%' }}
                choices={[
                  { name: 'top' },
                  { name: 'center' },
                  { name: 'bottom' },
                ]}
                optionText='name'
                optionValue='name'
              />
            </div>
          </div>
        </SimpleForm>
      </Create>
      <CustomerUrlModal
        modalOpen={dialogOpen}
        setModalOpen={setDialogOpen}
        data={userData}
        user={userObject}
      />
      <AddEmailModal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default CompanyInformation;
