import { SaveButton, useTranslate, Toolbar } from 'react-admin';
import CustomButton from './CustomButton';

export default function CustomToolbar({ handleClick, isEdit = false, sx }) {
  const translate = useTranslate();

  return (
    <Toolbar
      style={{ padding: 0, backgroundColor: 'transparent', ml: '16px' }}
      sx={{ ...sx }}
    >
      <SaveButton
        style={{ margin: 0, marginRight: 10, width: 'fit-content' }}
        sx={{ '& .MuiSvgIcon-root': { display: 'none' } }}
        label={translate('buttons.save_changes')}
        alwaysEnable={isEdit}
      />

      <CustomButton
        variant='primary'
        title={translate('buttons.discard')}
        handleClick={handleClick}
        sx={{ marginLeft: '30px' }}
      />
    </Toolbar>
  );
}
