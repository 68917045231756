// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  required,
  useTranslate,
  useRefresh,
  useUpdate,
  AutocompleteArrayInput,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import axios from '../../plugins/axios';
import { ToastContext } from '../../context/toastContext';
import { SpinnerContext } from '../../context/spinnerContext';
import { theme } from '../layout/themes';

// utils
import uploadThumb from '../../utils/uploadFileItemThumb';

// components
import { Typography } from '@mui/material';
import UploadIcon from '../shared/UploadIcon';
import CustomButton from '../shared/CustomButton';
import CustomToolbar from '../shared/CustomToolbar';
import { RichTextInput } from 'ra-input-rich-text';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MediaItemEdit = ({ langId, id }) => {
  // state
  const [selectTypeOfFileOptions, setSelectTypeOfFileOptions] = useState([]);
  const [selectCategoryOptions, setSelectCategoryOptions] = useState([]);
  const [coverFile, setCoverfile] = useState();
  const [previewCoverFile, setPreviewCoverFile] = useState();
  const [animalsIncluded, setAnimalsIncluded] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const [fileItemThumbnailFile, setFileItemThumbnailFile] = useState();
  const [fileThumb, setFileThumb] = useState();
  const [previewFileItemThumbnailFile, setPreviewFileItemThumbnailFile] =
    useState();
  const [fileItemId, setFileItemId] = useState();

  // hooks
  const translate = useTranslate();
  const navigate = useNavigate();
  const [update] = useUpdate();
  const { toast } = useContext(ToastContext);
  const { isLoading, setIsLoading } = useContext(SpinnerContext);

  // refs
  const hiddenFileInput = useRef(null);
  const hiddenMediaFileThumbnailInput = useRef(null);

  const { data } = useGetOne(
    'media-item',
    { id },
    {
      onSuccess: (data) => {
        data.animalsIncluded = data.animalsIncluded.map((item) => {
          return item.id.toString();
        });

        data.categories = data.categories.map((item) => {
          return item.id.toString();
        });

        data.fileName = data.fileItems[0].name;
        data.url = data.fileItems[0].url;
        data.mediaTypeId = data.fileItems[0].mediaTypeId.toString();
        setFileItemId(data.fileItems[0].id);
        setFileThumb(data.fileItems[0].thumbnail);
        setIsEnabled(false);
      },
      enabled: isEnabled,
    }
  );

  useEffect(() => {
    async function fetchCategoryData() {
      const response = await axios('/category/get-filtered-list', {
        method: 'POST',
        data: {
          maxResultCount: 1000,
          sorting: 'name ASC',
          filterRule: {
            condition: 'and',
            filterRules: [
              {
                field: 'IsFactory',
                operator: 'equals',
                value: 'false',
              },
              {
                field: 'LanguageId',
                operator: 'equals',
                value: langId?.toString(),
              },
            ],
          },
        },
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      });
      return response;
    }

    fetchCategoryData().then((response) => {
      const numbers = response.data.items.filter((c) =>
        /^(?![a-z])/i.test(c.name)
      );
      setSelectCategoryOptions(
        response.data.items.slice(numbers.length).concat(numbers)
      );
    });

    async function fetchTypeOfFileData() {
      const response = await axios('/file-item/media-types', { method: 'GET' });
      return response;
    }

    fetchTypeOfFileData().then((response) =>
      setSelectTypeOfFileOptions(response.data.items)
    );

    async function fetchAnimalsIncludedData() {
      const response = await axios('/media-item/animals-included-list', {
        method: 'GET',
        data: {
          maxResultCount: 1000,
          sorting: 'name ASC',
        },
      });
      return response;
    }

    fetchAnimalsIncludedData().then((response) => {
      setAnimalsIncluded(response.data);
    });
  }, [langId]);

  useEffect(() => {
    if (!coverFile) {
      setPreviewCoverFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(coverFile);
    setPreviewCoverFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [coverFile]);

  useEffect(() => {
    if (!fileItemThumbnailFile) {
      setPreviewFileItemThumbnailFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileItemThumbnailFile);
    setPreviewFileItemThumbnailFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileItemThumbnailFile]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleMediaFileThumbnailClick = (event) => {
    hiddenMediaFileThumbnailInput.current.click();
  };

  const handleUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCoverfile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setCoverfile(fileUploaded);
  };

  const handleFileItemThumbnailUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setFileItemThumbnailFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setFileItemThumbnailFile(fileUploaded);
  };

  const saveMediaItem = async (data) => {
    setIsLoading(true);
    let coverImageResponse = '';

    try {
      if (coverFile) {
        const coverFormData = new FormData();
        coverFormData.append('file', coverFile);

        coverImageResponse = await axios.post(
          '/media-item/upload-cover-image',
          coverFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      }

      let thumbnail = '';
      if (fileItemThumbnailFile)
        thumbnail = await uploadThumb(fileItemThumbnailFile);

      data.animalsIncluded = data.animalsIncluded.map((item) => {
        return parseInt(item);
      });

      data.categoryIds = data.categories.map((item) => {
        return parseInt(item);
      });

      data.downloadVersion = true;
      data.fullVersion = true;
      data.coverImage = coverFile ? coverImageResponse.data : data.coverImage;
      data.coverImageOriginal = coverFile
        ? coverImageResponse.data
        : data.coverImageOriginal;
      data.mediaItemType = 1;
      data.languageId = langId;
      data.id = parseInt(id);
      data.mediaTypeId = parseInt(data.mediaTypeId);

      data.fileItems = [
        {
          id: fileItemId,
          name: data.name,
          url: data.url,
          mediaTypeId: data.mediaTypeId,
          thumbnail: thumbnail ? thumbnail : fileThumb,
          isPublic: true,
          isDefault: true,
        },
      ];

      delete data.languagesIncluded;
      delete data.categories;
      delete data.fileName;
      delete data.typeOfFile;
      delete data.mediaType;

      update('media-item', { data }, { onError, onSuccess });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);
      console.log(err);
    }
  };

  const onError = (data) => {
    setIsLoading(false);
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    setIsLoading(false);
    toast.success(
      translate('notifications.success'),
      `${translate('resources.content.media_item')} ${data.name} ${translate(
        'notifications.edited'
      )}`
    );
  };

  function MyButton({ buttonId, sx }) {
    const record = useRecordContext();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const translate = useTranslate();

    useEffect(() => {
      // Check if the button was previously disabled
      const isDisabled = localStorage.getItem(`${buttonId}_disabled`);
      const disabledTimestamp = localStorage.getItem(
        `${buttonId}_disabled_timestamp`
      );

      if (isDisabled && disabledTimestamp) {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - parseInt(disabledTimestamp);

        if (timeDifference >= 21600000) {
          // One day has passed, remove the disabled state
          localStorage.removeItem(`${buttonId}_disabled`);
          localStorage.removeItem(`${buttonId}_disabled_timestamp`);
          setIsButtonDisabled(false);
        } else {
          setIsButtonDisabled(true);
        }
      }
    }, [buttonId]);

    async function reEncode() {
      try {
        const response = await axios(
          `/file-item/${data?.fileItems[0]?.id}/re-encode-video`,
          {
            method: 'GET',
          }
        );
        toast.success(
          translate('notifications.success'),
          `${translate('common.encoding_started')}`
        );
        return response;
      } catch (err) {
        toast.error(
          translate('notifications.error'),
          err.response.data.error.message
        );
        console.log(err);
      }
    }

    const handleClick = async () => {
      setIsButtonDisabled(true);
      localStorage.setItem(`${buttonId}_disabled`, true);
      localStorage.setItem(
        `${buttonId}_disabled_timestamp`,
        new Date().getTime()
      );

      reEncode();
    };

    return (
      <CustomButton
        disabled={isButtonDisabled}
        title={translate('common.re_encode_video')}
        variant='secondary'
        style={{ marginLeft: 4, marginRight: 4 }}
        handleClick={handleClick}
        sx={{ ...sx }}
      />
    );
  }

  return (
    <>
      <SimpleForm
        record={data}
        toolbar={
          <CustomToolbar
            handleClick={() => navigate('/content/media-catalog')}
            isEdit={true}
            sx={{ marginRight: '-50 !important' }}
          />
        }
        onSubmit={saveMediaItem}
        sx={{ p: '0px !important' }}
      >
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 2 }}>
          {translate('resources.content.description')}
        </Typography>
        <TextInput
          source='name'
          label={translate('resources.content.title')}
          validate={required()}
          sx={{ width: '60%', mb: 1 }}
        />
        <div className='media-item-description' style={{ width: '60%' }}>
          <RichTextInput
            source='description'
            multiline
            maxRows={8}
            label={translate('resources.content.description')}
            validate={required()}
            className='custom-rich-text-input'
          />
        </div>
        <AutocompleteArrayInput
          source='animalsIncluded'
          label={translate('resources.content.showcased_animals')}
          icon={<KeyboardArrowDownIcon />}
          sx={{ width: '60%' }}
          choices={animalsIncluded}
          optionText='name'
          optionValue='id'
          size='small'
        />
        <Typography variant='h6' sx={{ fontSize: '1rem', my: 3 }}>
          {translate('common.cover_image')}
        </Typography>
        <div style={{ display: 'flex', marginBottom: 38 }}>
          <UploadIcon />
          <CustomButton
            title={translate('common.click_to_upload')}
            variant='ghost'
            style={{ marginLeft: 4, marginRight: 4 }}
            handleClick={handleClick}
          />
          <input
            type='file'
            accept='image/*'
            ref={hiddenFileInput}
            onChange={handleUploadChange}
            style={{ display: 'none' }}
          />
          {coverFile ? (
            <img
              src={previewCoverFile}
              alt={coverFile.name}
              style={{ height: '6.3rem' }}
            />
          ) : (
            <img
              src={data?.coverImage}
              alt='cover'
              style={{ height: '6.3rem' }}
            />
          )}
        </div>
        <div
          style={{
            margin: '1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <div style={{ display: 'flex' }}>
          <BooleanInput
            label={translate('resources.content.publish_to_media_catalogue')}
            source='isPublic'
            sx={{
              mr: 2,
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
          <BooleanInput
            label={translate('resources.content.mark_as_new')}
            source='isNew'
            sx={{
              mr: 2,
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
          <BooleanInput
            label={translate('resources.content.add_to_case_studies')}
            source='isCaseStudy'
            sx={{
              '& .MuiFormControlLabel-label': {
                color: theme.palette.primary.main,
              },
            }}
          />
        </div>

        <div
          style={{
            margin: '1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 2 }}>
          {translate('resources.content.categories')}
        </Typography>
        <AutocompleteArrayInput
          source='categories'
          label={translate('resources.content.add_categories')}
          icon={<KeyboardArrowDownIcon />}
          sx={{ width: '60%' }}
          choices={selectCategoryOptions}
          validate={required()}
          optionText='name'
          optionValue='id'
          size='small'
        />
        <div
          style={{
            margin: '1.5rem 0',
            borderTop: '1px solid #D7D7D7',
            height: '1px',
            width: '100%',
          }}
        ></div>
        <Typography variant='h6' sx={{ fontSize: '1rem', mb: 2 }}>
          {translate('resources.content.file_item')}
        </Typography>
        <Typography variant='h7' sx={{ fontSize: '0.9rem', my: 3 }}>
          {translate('resources.content.file_item_thumbnail')}
        </Typography>
        <div style={{ display: 'flex', marginBottom: 38 }}>
          <UploadIcon />
          <CustomButton
            title={translate('common.click_to_upload')}
            variant='ghost'
            style={{ marginLeft: 4, marginRight: 4 }}
            handleClick={handleMediaFileThumbnailClick}
          />
          <input
            type='file'
            ref={hiddenMediaFileThumbnailInput}
            onChange={handleFileItemThumbnailUploadChange}
            style={{ display: 'none' }}
          />
          {fileItemThumbnailFile && (
            <img
              src={previewFileItemThumbnailFile}
              alt={fileItemThumbnailFile.name}
              style={{ height: '6.3rem' }}
            />
          )}
          {!fileItemThumbnailFile && fileThumb && (
            <img src={fileThumb} alt='thumbnail' style={{ height: '6.3rem' }} />
          )}
        </div>
        <TextInput
          source='name'
          label={translate('resources.content.file_name')}
          validate={required()}
          sx={{ width: '60%', mb: 1 }}
          disabled
        />
        <TextInput
          source='url'
          label={translate('resources.content.url')}
          validate={required()}
          sx={{ width: '60%', mb: 1 }}
        />
        {selectTypeOfFileOptions.length > 0 && (
          <div stayle={{ display: 'flex' }}>
            <SelectInput
              source='mediaTypeId'
              label={translate('resources.content.media_type')}
              icon={<KeyboardArrowDownIcon />}
              sx={{ width: '30%', height: '40px', mb: 3 }}
              choices={selectTypeOfFileOptions}
              validate={required()}
              optionText='name'
              optionValue='id'
            />
            {data?.fileItems[0]?.videoGuid && (
              <MyButton
                buttonId={`button_${data?.id}_${data?.fileItems[0]?.id}`}
                sx={{ margin: '8px 0 0 2.7rem' }}
              />
            )}
          </div>
        )}
      </SimpleForm>
    </>
  );
};

export default MediaItemEdit;
