// library modules
import { useState, useEffect, useContext } from 'react';
import axios from '../../plugins/axios';
import { InitialDataContext } from '../../context/initialDataContext';
import { useTranslate } from 'react-admin';
// components
import { Typography, TextField, Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import CustomButton from '../shared/CustomButton';
import TreeTable from '../shared/TreeTable';

// icons
import AddCategoryModal from './AddCategoryModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function CategoriesList({ isFactory = false }) {
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [languageId, setLanguageId] = useState('1');
  const initialData = useContext(InitialDataContext);
  const translate = useTranslate();

  async function getCategories() {
    const response = await axios(
      'category/tree-view-items',
      { params: { isFactory: isFactory, language: languageId } },
      {
        method: 'GET',
      }
    );

    return response;
  }

  useEffect(() => {
    setIsLoading(true);
    getCategories().then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }, [languageId]);

  return (
    <>
      <AddCategoryModal
        modalOpen={addCategoryModalOpen}
        setModalOpen={setAddCategoryModalOpen}
        isFactory={isFactory}
        languageId={languageId}
        refresh={() => {
          setIsLoading(true);
          getCategories().then((response) => {
            setData(response.data);
            setIsLoading(false);
          });
        }}
      />

      <Box sx={{ padding: '0' }}>
        <Typography variant='h5' sx={{ mb: '1.5rem' }}>
          List of categories
        </Typography>
        <Select
          value={languageId}
          onChange={(e) => {
            setLanguageId(e.target.value);
          }}
          icon={<KeyboardArrowDownIcon />}
          sx={{ height: '40px', mt: 0, width: '100px', mb: 5 }}
          choices={initialData.languageList}
        >
          {initialData.languageList.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'space-between',
            paddingBottom: '1rem',
            borderBottom: '1px solid lightgray',
            marginBottom: '0.5rem',
          }}
        >
          <Typography variant='sm' color='neutral.light'>
            Name of category
          </Typography>
          {languageId === '1' && (
            <CustomButton
              title='+ Add Category'
              variant='secondary'
              handleClick={() => setAddCategoryModalOpen(true)}
            />
          )}
        </Box>
        {isLoading ? (
          'Loading...'
        ) : (
          <TreeTable
            data={data}
            isFactory={isFactory}
            languageId={languageId}
            refresh={() => {
              setIsLoading(true);
              getCategories().then((response) => {
                setData(response.data);
                setIsLoading(false);
              });
            }}
          />
        )}
      </Box>
    </>
  );
}
