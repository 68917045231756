// library modules
import { useState, useEffect, useContext } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import {
  SimpleForm,
  TextInput,
  useCreate,
  useTranslate,
  useRedirect,
  useRefresh,
  localStorageStore,
  SelectInput,
  required,
} from 'react-admin';
import axios from '../../../plugins/axios';
import { ToastContext } from '../../../context/toastContext';

// components
import CustomButton from '../../shared/CustomButton';
import { Breadcrumbs, Typography, Box } from '@mui/material';
import CustomToolbar from '../../shared/CustomToolbar';
import TabRouter from '../../shared/TabRouter';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function TemplateCreate({ name }) {
  // hooks
  const [create] = useCreate();
  const navigate = useNavigate();
  const translate = useTranslate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const store = localStorageStore();

  // state
  const [selectOptions, setSelectOptions] = useState([]);
  const [nameErrorMsg, setNameErrorMsg] = useState(false);
  const [customerTypeIdErrorMsg, setCustomerTypeIdErrorMsg] = useState(false);
  const [buttonState, setButtonState] = useState(
    store.getItem('template.our.products') ||
      store.getItem('template.testimonials') ||
      store.getItem('template.case.studies')
      ? true
      : false
  );

  // routes
  const routes = [
    {
      name: translate('common.discover_wild_immersion'),
      url: '/our-products',
    },
    {
      name: translate('common.case_studies'),
      url: '/case-studies',
    },
    {
      name: translate('common.testimonials'),
      url: '/testimonials',
    },
  ];

  useEffect(() => {
    async function fetchCustomerTypes() {
      const response = await axios('/customer/customer-types', {
        method: 'GET',
      });
      return response;
    }

    fetchCustomerTypes().then((response) => {
      setSelectOptions(response.data);
    });
  }, []);

  function resetStore() {
    store.removeItem('template.case.studies');
    store.removeItem('template.customerTypeId');
    store.removeItem('template.testimonials');
    store.removeItem('template.our.products');
    store.removeItem('template.name');
    store.removeItem('template.customerTypeId');
    store.removeItem('template.template.studiesNew');
    store.removeItem('template.template.ourProductsNew');
    store.removeItem('template.template.testimonialsNew');
    store.removeItem('template.template.isEdit');
  }

  const getName = () => {
    return store.getItem('template.name');
  };

  const getId = () => {
    const id = store.getItem('template.customerTypeId');
    return id ? id : '';
  };

  const handleSubmit = async (data) => {
    const ourProducts = store.getItem('template.our.products');
    const testimonials = store.getItem('template.testimonials');
    const caseStudies = store.getItem('template.case.studies');

    try {
      const mediaList = [];
      if (ourProducts) {
        ourProducts.forEach((element) => {
          mediaList.push({
            mediaItemId: element.id,
            section: 1,
          });
        });
      }
      if (testimonials) {
        testimonials.forEach((element) => {
          mediaList.push({
            mediaItemId: element.id,
            section: 2,
          });
        });
      }
      if (caseStudies) {
        caseStudies.forEach((element) => {
          mediaList.push({
            mediaItemId: element.id,
            section: 3,
          });
        });
      }
      data.customerTypeId = getId();
      data.name = getName();
      data.selectedMediaItems = mediaList;

      if (!data.name || !data.customerTypeId) {
        if (!data.name) setNameErrorMsg(true);
        if (!data.customerTypeId) setCustomerTypeIdErrorMsg(true);
        return;
      }

      create('template', { data }, { onError, onSuccess });
    } catch (err) {
      toast.error(translate('notifications.error'), err.message);
      console.log(err);
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    resetStore();
    toast.success(
      translate('notifications.success'),
      `${translate('resources.template.template')} ${translate(
        'notifications.created'
      )}`
    );

    redirect('/customers/template');
    refresh();
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.template.template')}
          handleClick={() => {
            resetStore();
            navigate('/customers/template/');
          }}
        />
        <CustomButton
          variant='link'
          title={translate('resources.template.add_template')}
        />
      </Breadcrumbs>
      <Typography variant='h5' sx={{ mb: '1.7rem', ml: '1.5rem' }}>
        {translate('resources.template.add_template')}
      </Typography>
      <SimpleForm
        toolbar={
          <CustomToolbar
            isEdit={buttonState}
            handleClick={() => {
              resetStore();
              navigate('/customers/template/');
            }}
            sx={{ ml: 3 }}
          />
        }
        onSubmit={handleSubmit}
        sx={{ px: 3 }}
      >
        <TextInput
          source='name'
          defaultValue={getName}
          validate={required()}
          sx={{ width: '60%' }}
          onChange={(e) => {
            store.setItem('template.name', e.target.value);
          }}
        />
        {nameErrorMsg && (
          <p
            style={{
              color: '#E74C3C',
              marginTop: '-25px',
              fontSize: '0.8rem',
              marginLeft: '0.9rem',
            }}
          >
            {translate('general_settings.required')}
          </p>
        )}
        <SelectInput
          defaultValue={getId || ''}
          source='customerTypeId'
          validate={required()}
          onChange={(e) => {
            store.setItem('template.customerTypeId', e.target.value);
            setCustomerTypeIdErrorMsg(false);
          }}
          icon={<KeyboardArrowDownIcon />}
          sx={{
            // '& .MuiOutlinedInput-notchedOutline': {
            //   borderColor: customerTypeIdErrorMsg
            //     ? '#E74C3C'
            //     : 'rgba(0, 0, 0, 0.2)',
            // },
            // '& .MuiInputLabel-root': {
            //   color: customerTypeIdErrorMsg ? '#E74C3C' : 'rgba(0, 0, 0, 0.6)',
            // },
            height: '40px',
            mt: 0,
            width: '250px',
            mb: 5,
          }}
          choices={selectOptions}
          optionText='name'
          optionValue='id'
        />
        {customerTypeIdErrorMsg && (
          <p
            style={{
              color: '#E74C3C',
              marginTop: '-35px',
              fontSize: '0.8rem',
              marginLeft: '0.9rem',
            }}
          >
            {translate('general_settings.required')}
          </p>
        )}
      </SimpleForm>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, mx: 3 }}>
        <TabRouter basePath='/template/create' routes={routes} />
      </Box>
      <Outlet />
    </>
  );
}
