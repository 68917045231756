import { useContext, useEffect, useState } from 'react';
import { useDelete, useTranslate } from 'react-admin';
import { ToastContext } from '../../context/toastContext';
import axios from '../../plugins/axios';
import PromptDialog from '../shared/PromptDialog';

export default function DeleteCategoryModal({
  record,
  modalOpen,
  setModalOpen,
  refresh,
}) {
  const type = record.parentId === 0 ? 'category' : 'subcategory';
  const [deleteOne] = useDelete('categories');
  const [hasItems, setHasItems] = useState(null);
  const { toast } = useContext(ToastContext);
  const translate = useTranslate();

  useEffect(() => {
    if (modalOpen) {
      axios('/category/media-item-checker', {
        method: 'POST',
        data: [record.id],
      }).then((res) => setHasItems(res.data));
    }
  }, [modalOpen, record]);

  const deleteCategory = async () => {
    await deleteOne(
      'categories',
      {
        id: record.id,
        previousData: record,
      },
      {
        onSuccess: () => {
          toast.success(
            translate('notifications.success'),
            `${translate('resources.content.' + type)} ${
              record.name
            } ${translate('notifications.deleted')}`
          );
          setModalOpen(false);
          refresh();
        },
        onError: (data) => {
          toast.error(
            translate('notifications.error'),
            data.response.data.error.message
          );
        },
      }
    );
  };

  if (hasItems === null) return;

  return (
    <PromptDialog
      isOpen={modalOpen}
      title={`${
        hasItems === true ? 'This category has items bound to it.' : ''
      } Are you sure you want to delete this ${type}?`}
      handleClose={() => setModalOpen(false)}
      handleConfirm={deleteCategory}
      closeButtonLabel='Cancel'
      confirmButtonLabel={`Delete ${type}`}
      content={`If you press delete you will erase the ${record.name} ${type} from Categories.`}
    />
  );
}
