import axios from '../plugins/axios';

export const cleanResource = (resource) => {
  switch (resource) {
    case 'admins':
      return 'admin';

    case 'customers':
      return 'customer';

    case 'testimonials':
      return 'testimonial';

    case 'purchased-file-item':
      return 'purchased-file-item';
    case 'customer-document-invoice':
    case 'customer-document-contract':
    case 'customer-document-contract-list':
    case 'customer-document-invoice-list':
    case 'customer-document-types':
      return 'customer-document';
    case 'selected-file-item-testimonials':
    case 'selected-file-item-case-studies':
    case 'selected-file-item-case-studies-list':
    case 'selected-file-item-our-products-list':
    case 'selected-file-item-testimonials-list':
      return 'selected-file-item';
    case 'categories':
      return 'category';

    case 'roles':
      return 'roles';

    // case 'customer-document-types':
    //   return 'customer-document';

    default:
      return resource;
  }
};

export const createQueryParams = (params) => {
  const { field, order } = params.sort;
  const { page, perPage } = params.pagination;
  const filter = Object.assign({}, params.filter);

  const queryParams = {
    sorting: `${field} ${order}`,
    skipCount: (page - 1) * perPage,
    maxResultCount: perPage,
    filterRule: {
      condition: 'and',
      filterRules: params.filterRules || [],
    },
  };

  if (!params.filterRules) {
    Object.keys(filter).forEach((item) => {
      const value = filter[item] === 'null' ? null : filter[item];

      if (value === 'skipFilter') {
        return;
      }

      // set search filter for multiple fields
      if (isSearchFilter(item)) {
        const searchValues =
          item === 'adminsSearch' || item === 'customersSearch'
            ? value.split(' ')
            : [value];

        // search for multiple values
        searchValues.forEach((value) => {
          if (value === '') return;

          queryParams.filterRule.filterRules.push({
            condition: 'or',
            filterRules: [],
          });

          const searchFields = getSearchFields(item);
          const lastItemIndex = queryParams.filterRule.filterRules.length - 1;

          searchFields.forEach((field) => {
            queryParams.filterRule.filterRules[lastItemIndex].filterRules.push({
              field,
              operator: 'contains',
              value,
            });
          });
        });
      } else {
        queryParams.filterRule.filterRules.push({
          field: capitalizeFirstLetter(item),
          operator: 'equals',
          value,
        });
      }
    });
  }

  return queryParams;
};

export const uploadFile = async (rawFile) => {
  const formData = new FormData();
  formData.append('file', rawFile);

  try {
    const response = await axios.post('/file/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    const { downloadUrl, name } = response.data?.result;

    return { downloadUrl, name };
  } catch (err) {
    console.log(err, 'error');
  }
};

export const uploadMultipleFiles = async (rawFiles) => {
  const formData = new FormData();
  rawFiles.forEach((file) => {
    formData.append('files', file);
  });

  try {
    const response = await axios.post('/file/batch-upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data?.result;
  } catch (err) {
    console.log(err, 'error');
  }
};

const getSearchFields = (filter) => {
  switch (filter) {
    case 'customersSearch':
      return ['CompanyName', 'ContactPerson', 'SalesPerson'];

    case 'customerChosenProductsSearch':
    case 'customerCaseStudiesSearch':
    case 'customerApplicationPackagesSearch':
    case 'customerContractsSearch':
    case 'customerTestimonialsSearch':
    case 'customerInvoicesSearch':
    case 'purchasedFileItemSearch':
    case 'mediaItemDeletedSearch':
    case 'mediaItemSearch':
    case 'testimonials-list-search':
    case 'case-studies-search':
    case 'templateSearch':
    case 'customerOurProductsSearch':
      return ['Name'];
    case 'mediaItemListSearch':
    case 'rawSearch':
      return ['Name', 'Category'];
    case 'adminUserSearch':
      return ['Name', 'RoleName', 'Email'];
    default:
      return ['Name'];
  }
};

const isSearchFilter = (filter) => {
  const searchFilters = [
    'customersSearch',
    'templateSearch',
    'purchasedFileItemSearch',
    'customerInvoicesSearch',
    'customerTestimonialsSearch',
    'customerContractsSearch',
    'customerCaseStudiesSearch',
    'customerApplicationPackagesSearch',
    'customerChosenProductsSearch',
    'customerOurProductsSearch',
    'mediaItemSearch',
    'mediaItemListSearch',
    'rawSearch',
    'mediaItemDeletedSearch',
    'adminUserSearch',
    'testimonials-list-search',
    'case-studies-search',
  ];

  return searchFilters.includes(filter);
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
