// library modules
import { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  required,
  useTranslate,
  ResourceContextProvider,
  useRefresh,
  useCreate,
  AutocompleteArrayInput,
} from 'react-admin';
import axios from '../../plugins/axios';
import { ToastContext } from '../../context/toastContext';
import { theme } from '../layout/themes';
import { InitialDataContext } from '../../context/initialDataContext';

// utils
import uploadThumb from '../../utils/uploadFileItemThumb';
import check from '../../utils/checkIfItemExists';

// components
import { Typography, Breadcrumbs } from '@mui/material';
import UploadIcon from '../shared/UploadIcon';
import CustomButton from '../shared/CustomButton';
import CustomToolbar from '../shared/CustomToolbar';
import { RichTextInput } from 'ra-input-rich-text';

// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MediaItemCreate = () => {
  // state
  const [selectTypeOfFileOptions, setSelectTypeOfFileOptions] = useState([]);
  const [selectCategoryOptions, setSelectCategoryOptions] = useState([]);
  const [coverFile, setCoverfile] = useState();
  const [previewCoverFile, setPreviewCoverFile] = useState();
  const [animalsIncluded, setAnimalsIncluded] = useState([]);
  const [fileItemThumbnailFile, setFileItemThumbnailFile] = useState();
  const [previewFileItemThumbnailFile, setPreviewFileItemThumbnailFile] =
    useState();
  const [doesExist, setDoesExist] = useState(false);
  const [imageErrorMsg, setImageErrorMsg] = useState(false);

  // hooks
  const translate = useTranslate();
  const navigate = useNavigate();
  const [create] = useCreate();
  const refresh = useRefresh();
  const { toast } = useContext(ToastContext);
  const initialData = useContext(InitialDataContext);

  // refs
  const hiddenFileInput = useRef(null);
  const hiddenMediaFileThumbnailInput = useRef(null);

  useEffect(() => {
    async function fetchTypeOfFileData() {
      const response = await axios('/file-item/media-types', { method: 'GET' });
      return response;
    }

    fetchTypeOfFileData().then((response) =>
      setSelectTypeOfFileOptions(response.data.items)
    );

    async function fetchCategoryData() {
      const response = await axios('/category/get-filtered-list', {
        method: 'POST',
        data: {
          maxResultCount: 1000,
          sorting: 'name ASC',
          filterRule: {
            condition: 'and',
            filterRules: [
              {
                field: 'IsFactory',
                operator: 'equals',
                value: 'false',
              },
              {
                field: 'LanguageId',
                operator: 'equals',
                value: initialData.defaultLanguage,
              },
            ],
          },
        },
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
        },
      });
      return response;
    }

    fetchCategoryData().then((response) => {
      const numbers = response.data.items.filter((c) =>
        /^(?![a-z])/i.test(c.name)
      );
      setSelectCategoryOptions(
        response.data.items.slice(numbers.length).concat(numbers)
      );
    });

    async function fetchAnimalsIncludedData() {
      const response = await axios('/media-item/animals-included-list', {
        method: 'GET',
        data: {
          maxResultCount: 1000,
          sorting: 'name ASC',
        },
      });
      return response;
    }

    fetchAnimalsIncludedData().then((response) => {
      setAnimalsIncluded(response.data);
    });
  }, [initialData.defaultLanguage]);

  useEffect(() => {
    if (!coverFile) {
      setPreviewCoverFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(coverFile);
    setPreviewCoverFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [coverFile]);

  useEffect(() => {
    if (!fileItemThumbnailFile) {
      setPreviewFileItemThumbnailFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileItemThumbnailFile);
    setPreviewFileItemThumbnailFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileItemThumbnailFile]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleMediaFileThumbnailClick = (event) => {
    hiddenMediaFileThumbnailInput.current.click();
  };

  const handleUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCoverfile(undefined);
      return;
    }
    setImageErrorMsg(false);
    const fileUploaded = event.target.files[0];
    setCoverfile(fileUploaded);
  };

  const handleFileItemThumbnailUploadChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setFileItemThumbnailFile(undefined);

      return;
    } else {
      const fileUploaded = event.target.files[0];
      setFileItemThumbnailFile(fileUploaded);
    }
  };

  const saveMediaItem = async (data) => {
    const error = await check(data.name);

    setDoesExist(error.data);

    if (!coverFile || error.data) {
      if (!coverFile) setImageErrorMsg(true);
      return;
    }

    if (coverFile) {
      try {
        let coverImageResponse = '';

        const coverFormData = new FormData();
        coverFormData.append('file', coverFile);

        coverImageResponse = await axios.post(
          '/media-item/upload-cover-image',
          coverFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        const thumbnail = fileItemThumbnailFile
          ? await uploadThumb(fileItemThumbnailFile)
          : coverImageResponse.data;
        data.downloadVersion = true;
        data.fullVersion = true;
        data.coverImage = coverFile ? coverImageResponse.data : '';
        data.coverImageOriginal = coverFile ? coverImageResponse.data : '';
        data.mediaItemType = 1;
        data.languageId = initialData.defaultLanguage;

        data.fileItems = [
          {
            name: data.name,
            url: data.url,
            mediaTypeId: data.mediaType,
            thumbnail: thumbnail,
            isPublic: true,
            isDefault: true,
          },
        ];

        delete data.fileName;
        delete data.typeOfFile;
        delete data.mediaType;

        if (coverImageResponse) {
          create('media-item', { data }, { onError, onSuccess });
        } else {
          toast.error(
            translate('notifications.error'),
            translate('notifications.image_is_required')
          );
        }
      } catch (err) {
        toast.error(translate('notifications.error'), err.message);
        console.log(err);
      }
    }
  };

  const onError = (data) => {
    toast.error(translate('notifications.error'), data.message);
  };

  const onSuccess = (data) => {
    URL.revokeObjectURL(fileItemThumbnailFile);
    toast.success(
      translate('notifications.success'),
      `${translate('resources.content.media_item')} ${data.name} ${translate(
        'notifications.created'
      )}`
    );

    navigate('/content/media-catalog');
    refresh();
  };

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.content.content')}
          handleClick={() => navigate('/content/media-catalog')}
        />
        <CustomButton
          variant='link'
          title={translate('resources.content.add_media_item')}
        />
      </Breadcrumbs>
      <Typography variant='h5' sx={{ paddingLeft: 3 }}>
        {translate('resources.content.add_media_item')}
      </Typography>
      <ResourceContextProvider resource='media-item'>
        <Create
          resource='media-item,'
          className='media-item-create'
          sx={{
            '& .MuiCard-root': { boxShadow: 'none', pb: 3, px: 1 },
          }}
        >
          <SimpleForm
            toolbar={
              <CustomToolbar
                handleClick={() => navigate('/content/media-catalog')}
                sx={{ marginLeft: '16px !important' }}
              />
            }
            onSubmit={saveMediaItem}
          >
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
              {translate('resources.content.description')}
            </Typography>
            <TextInput
              source='name'
              label={translate('resources.content.title')}
              validate={required()}
              sx={{ width: '60%', mb: 1 }}
              onBlur={(e) =>
                check(e.target.value).then((response) => {
                  setDoesExist(response.data);
                })
              }
            />
            {doesExist && (
              <div
                style={{
                  color: '#E74C3C',
                  marginTop: '-30px',
                  fontSize: '0.8rem',
                  padding: '0 0 8px 14px',
                }}
              >
                {translate('notifications.check_media_item_name_error_msg')}
              </div>
            )}
            <div className='media-item-description' style={{ width: '60%' }}>
              <RichTextInput
                source='description'
                multiline
                maxRows={8}
                label={translate('resources.content.description')}
                validate={required()}
                className='custom-rich-text-input'
              />
            </div>
            <AutocompleteArrayInput
              source='animalsIncluded'
              label={translate('resources.content.showcased_animals')}
              icon={<KeyboardArrowDownIcon />}
              sx={{ width: '60%' }}
              choices={animalsIncluded}
              optionText='name'
              optionValue='id'
              size='small'
            />
            <Typography variant='h6' sx={{ fontSize: '1rem', my: 3 }}>
              {translate('common.cover_image')}
            </Typography>
            <div style={{ display: 'flex', marginBottom: 38 }}>
              <UploadIcon />
              <CustomButton
                title={translate('common.click_to_upload')}
                variant='ghost'
                style={{ marginLeft: 4, marginRight: 4 }}
                handleClick={handleClick}
              />
              <input
                type='file'
                accept='image/*'
                ref={hiddenFileInput}
                onChange={handleUploadChange}
                style={{ display: 'none' }}
              />
              {coverFile && (
                <img
                  src={previewCoverFile}
                  alt={coverFile.name}
                  style={{ height: '6.3rem' }}
                />
              )}
            </div>
            {imageErrorMsg && (
              <p
                style={{
                  color: '#E74C3C',
                  marginTop: '-25px',
                  fontSize: '0.8rem',
                  marginLeft: '0.9rem',
                }}
              >
                {translate('general_settings.required')}
              </p>
            )}
            <div
              style={{
                margin: '1.5rem 0',
                borderTop: '1px solid #D7D7D7',
                height: '1px',
                width: '100%',
              }}
            ></div>
            <div style={{ display: 'flex' }}>
              <BooleanInput
                label={translate(
                  'resources.content.publish_to_media_catalogue'
                )}
                source='isPublic'
                sx={{
                  mr: 2,
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
              <BooleanInput
                label={translate('resources.content.mark_as_new')}
                source='isNew'
                sx={{
                  mr: 2,
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
              <BooleanInput
                label={translate('resources.content.add_to_case_studies')}
                source='isCaseStudy'
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            </div>

            <div
              style={{
                marginBottom: '1.5rem',
                borderTop: '1px solid #D7D7D7',
                height: '1px',
                width: '100%',
              }}
            ></div>
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
              {translate('resources.content.categories')}
            </Typography>
            <AutocompleteArrayInput
              source='categoryIds'
              label={translate('resources.content.add_categories')}
              icon={<KeyboardArrowDownIcon />}
              sx={{ width: '60%' }}
              choices={selectCategoryOptions}
              validate={required()}
              optionText='name'
              optionValue='id'
              size='small'
            />
            <div
              style={{
                margin: '1.5rem 0',
                borderTop: '1px solid #D7D7D7',
                height: '1px',
                width: '100%',
              }}
            ></div>
            <Typography variant='h6' sx={{ fontSize: '1rem', mb: 1 }}>
              {translate('resources.content.file_item')}
            </Typography>
            <Typography variant='h7' sx={{ fontSize: '0.9rem', my: 3 }}>
              {translate('resources.content.file_item_thumbnail')}
            </Typography>
            <div style={{ display: 'flex', marginBottom: 38 }}>
              <UploadIcon />
              <CustomButton
                title={translate('common.click_to_upload')}
                variant='ghost'
                style={{ marginLeft: 4, marginRight: 4 }}
                handleClick={handleMediaFileThumbnailClick}
              />
              <input
                type='file'
                ref={hiddenMediaFileThumbnailInput}
                onChange={handleFileItemThumbnailUploadChange}
                style={{ display: 'none' }}
              />

              {fileItemThumbnailFile && (
                <img
                  src={previewFileItemThumbnailFile}
                  alt={fileItemThumbnailFile.name}
                  style={{ height: '6.3rem' }}
                />
              )}
            </div>

            <TextInput
              source='name'
              label={translate('resources.content.file_name')}
              validate={required()}
              sx={{ width: '60%', my: 1 }}
              disabled
            />
            <TextInput
              source='url'
              label={translate('resources.content.url')}
              validate={required()}
              sx={{ width: '60%', mb: 1 }}
            />
            <SelectInput
              source='mediaType'
              label={translate('resources.content.media_type')}
              icon={<KeyboardArrowDownIcon />}
              sx={{ width: '30%', height: '40px', mb: 3 }}
              choices={selectTypeOfFileOptions}
              validate={required()}
              optionText='name'
              optionValue='id'
            />
          </SimpleForm>
        </Create>
      </ResourceContextProvider>
    </>
  );
};

export default MediaItemCreate;
