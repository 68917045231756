import { TextField, ImageField } from 'react-admin';

const UsernameWithImage = ({ imageSrc = 'coverImage', name = 'name' }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ImageField
        source={imageSrc}
        title='cover'
        sx={{
          '& .RaImageField-image': {
            width: '35px',
            height: '35px',
            objectFit: 'cover',
            mr: 2,
            borderRadius: '100%',
          },
        }}
      />
      <TextField source={name} />
    </div>
  );
};

export default UsernameWithImage;
