import englishMessages from 'ra-language-english';

export const en = {
  ...englishMessages,

  // resources and fields
  common: {
    table: {
      yes: 'Yes',
      no: 'No',
      added_to: 'Added to',
      see: 'See',
      more: 'More...',
      name: 'Name',
      length: 'Length',
      download_url: 'Download url',
      stream_url: 'Stream url',
      url: 'Url',
      public: 'Public',
      default: 'Default',
      name_of_item: 'Name of item',
      date_added: 'Date added',
      private_public: 'Private/Public',
      media_item_type: 'Media item type',
      user_name: 'User name',
      role: 'Role',
      password: 'Password',
      email: 'Email',
      category: 'Category',
      file_version: 'File Version',
      actions: 'Actions',
      action: 'Action',
      alternative_name: 'Alternative name',
      type: 'Type',
    },
    password_empty: 'Empty password',
    token_added: 'Token saved!',
    token: 'Token',
    testimonials: 'testimonials',
    copy_from: 'Copy from MS',
    items_added: 'Items added',
    cover_position: 'Cover position',
    encoding_started: 'Encoding started',
    enable_download: 'Enable download',
    disable_download: 'Disable download',
    re_encode_video: 'Re-Encod video',
    video_length: 'Video length',
    name_of_categories: 'Name of categories',
    list_of_categories: 'List of categories',
    wild_factory: 'Wild factory',
    category_image: 'Category image',
    password_validation:
      'Password must contain at least 1 number, special character, lowercase and upercase letter, minimum length 6 characters',
    enter_old_password: 'Enter old password',
    enter_new_password: 'Enter new password',
    repeat_new_password: 'Repeat new password',
    password_mismatch: 'Password do not match',
    change_password: 'Change password',
    email_is_not_valid: 'Email is not valid',
    singnature_date: 'Signature date',
    end_date: 'End date',
    updated: 'updated',
    question: 'Question',
    answer: 'Answer',
    edit: 'Edit',
    add_language: 'Add language',
    no_item_found: 'No item found',
    link: 'Link',
    add_raw: 'Add raw',
    add: 'ADD',
    add_lovercase: 'Add',
    country: 'Country',
    language: 'Language',
    choose_country: 'Choose country',
    profile_image: 'Profile image',
    customer_logo: 'Customer logo',
    cover_image: 'Cover image',
    click_to_upload: 'Click to upload',
    status: 'Status',
    purchased: 'Purchased',
    invoices: 'Invoices',
    contracts: 'Contracts',
    our_products: 'Our products',
    discover_wild_immersion: 'Movie selection',
    my_content: 'My content',
    case_studies: 'Presentations',
    testimonials: 'Testimonials',
    chosen_products: 'Chosen products',
    contact_person: 'Contact Person',
    sales_person: 'Sales person',
    choose_from_catalogue: 'Choose from Catalogue',
    add_files_from_bunny: 'Add files from Bunny',
    customer_space_url: 'CS url',
    wild_factory_url: `WF url`,
    add_item_to: 'Add item to',
    choose_items_to_add: 'Choose items to add',
    customer_name: 'Customer name',
    upload_file: 'Upload file',
    login: 'Login',
    logout: 'Logout',
    unauthorized: 'Unauthorized',
    application_packages: 'Application packages',
  },
  notifications: {
    file_is_required: 'File is required',
    image_is_required: 'Image is required',
    error: 'Error',
    success: 'Success',
    created: 'created',
    edited: 'edited',
    deleted: 'deleted',
    document_created: 'Document created',
    document_edited: 'Document edited',
    document_deleted: 'Document deleted',
    check_media_item_name_error_msg:
      'Media item already exists! Please change title',
    items_deleted: 'Items deleted',
    change_customer_email: 'Customer email already exists! Please change email',
  },
  buttons: {
    save: 'Save',
    reset_password: 'Reset password',
    delete: 'Delete',
    delete_file: 'Delete file',
    copy_link: 'Copy link',
    discard: 'Discard',
    save_changes: 'Save changes',
    auto_generate_password: 'Auto generate password',
    copy_url_address: 'Copy Url address',
    add_product: 'Add product',
    add_testimonial: 'Add testimonial',
    add_to_customer_space: 'Add to customer space',
    add_file_item: 'Add file item',
  },
  general_settings: {
    user: 'User',
    add_user: 'Add user',
    edit_user: 'Edit user',
    list_of_users: 'List of users',
    main_settings: 'Main settings',
    add_user_name: 'Add user name',
    add_user_email: 'Add user email',
    user_name: 'User name',
    user_email: 'User email',
    user_role: 'User role',
    user_password: 'User password',
    type_of_admin: 'Type of admin',
    role: 'Role',
    delete_user: 'Delete user',
    type_password_or_aut_generate: 'Type password or auto generate',
    required: 'Required',
  },
  resources: {
    testimonials: {
      list_of_testimonials: 'List of testimonials',
    },
    content: {
      win_store_link: 'Windows Store link',
      play_store_link: 'Google Play link',
      app_store_link: 'Apple Store link',
      internet_connection_required: 'Internet connection required',
      user_age: 'User age',
      app_size: 'App size',
      hardware_specs: 'Hardware specifications',
      preferred_browser: 'Preferred browser',
      os_to_run_on: 'OS to run on',
      devices_to_run_on: 'App runs on devices',
      application_specifications: 'Application specifications',
      delete_raw_footage: 'Delete RAW footage',
      file_item_thumbnail: 'File item thumbnail',
      customer_feedback: 'Customer feedback',
      hardware_status: 'Hardware status',
      no_feedback_found: 'No feedback found',
      no_hardware_found: 'There is no hardware problems',
      edit_application_package: 'Edit application package',
      edit_movie_package: 'Edit movie package',
      edit_media_item: 'Edit media item',
      create_raw_footage: 'Create raw footage',
      edit_raw_footage: 'Edit raw footage',
      list_of_deleted_files: 'List of deleted files',
      list_of_rawfootage: 'List of RAW footage',
      media_catalogue: 'Media catalogue',
      raw_footage: 'Raw footage',
      deleted_files: 'Deleted files',
      media_item: 'Media item',
      add_to_testimonials: 'Add to testimonials',
      add_to_case_studies: 'Add to presentations',
      public_or_private: 'Public or private',
      delete_media_item: 'Delete media item',
      list_of_media_items: 'List of media items',
      available_languages: 'Available languages',
      add_movie_package: 'Add movie package',
      movie_package: 'Movie package',
      add_media_item: 'Add media item',
      content: 'Content',
      description: 'Description',
      title: 'Title',
      alternative_title: 'Alternative title',
      type_of_file: 'Type of file',
      animals_included: 'Animals included',
      showcased_animals: 'Showcased animals',
      publish_to_media_catalogue: 'Publish to media catalogue',
      mark_as_new: 'Mark as new',
      file_version: 'File version',
      label_of_file: 'Label of file',
      full_view: 'Full view',
      downloadable: 'Downloadable',
      new: 'New',
      category: 'Category',
      categories: 'Categories',
      subcategory: 'Subcategory',
      add_categories: 'Add categories',
      file_item: 'File item',
      file_name: 'File name',
      url: 'Url',
      media_type_id: 'Media type id',
      media_type: 'Media type',
      media_item_cover_image: 'Media item cover image',
      summary: 'Summary',
      add_application_package: 'Add application package',
      application_package: 'Application package',
      application_packages: 'Application packages',
      name_of_item: 'Name of item',
      file_size: 'File size',
      media_type_name: 'Media type name',
      media_item_must: 'Media item must have at least 1 media file',
    },
    customer: {
      active_user: 'Active customer',
      wild_factory_address: 'Wild Factory local IP address',
      local_ip_address: 'Local IP address',
      categories_saved: 'Categories saved',
      activate_wild_factory: 'Activate Wild Factory',
      sales_person_updated: 'Sales person updated',
      change_sales_person: 'Change sales person',
      issue: 'Issue',
      creation_date: 'Creation date',
      contact_email: 'Contact email',
      items_added_to: 'items added to',
      email_exists: 'Email already exists',
      list_of_customers: 'List of customers',
      url_adress: 'Url address',
      save_customer: 'Save customer',
      customers_informations: 'Customer information',
      add_company_name: 'Add company name',
      add_company_email: 'Add company email',
      create_user_log_in_credentials: 'Create user log in credentials',
      edit_user_log_in_credentials: 'Edit user log in credentials',
      type_of_customer: 'Type of customer',
      customer_space: 'Customer space',
      customers: 'Customers',
      edit_customer: 'Edit customer',
      add_customer: 'Add customer',
      add_new_customer: 'Add new customer',
      company_informations: 'Company information',
      add_case_study: 'Add presentation',
      company_name: 'Company name',
      delete_customer: 'Delete customer',
      customer: 'Customer',
      showtime_licence: 'Showtime licence',
      additional_email: 'Additional email',
      enable_notification: 'Enable notification',
      notification_enabled: 'Notification enabled',
      status: 'User status',
    },
    template: {
      template_list_is_required: 'Add 1 item into at least 1 section',
      list_of_templates: 'List of templates',
      apply_template: 'Apply template',
      templates: 'Templates',
      template: 'Template',
      add_template: 'Add template',
      delete_template: 'Delete template',
      edit_template: 'Edit template',
      template_updated: 'Template updated',
      template_deleted: 'template deleted',
    },
    case_studies: {
      list_of_case_studies: 'List of presentations',
    },
    contracts: {
      delete_contract: 'Delete contract',
      list_of_contracts: 'List of contracts',
      list_of_invoices: 'List of invoices',
      delete_invoice: 'Delete invoice',
      invoice: 'Invoice',
      contract: 'Contract',
      add_invoice: 'Add invoice',
      add_contract: 'Add contract',
      emission_date: 'Emission date',
      start_date: 'Start date',
      signature_date: 'Signature date',
      end_date: 'End date',
    },
  },
};
