// library modules
import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  FilterForm,
  SearchInput,
  localStorageStore,
  AutocompleteInput,
  useTranslate,
} from 'react-admin';
import { InitialDataContext } from '../../../context/initialDataContext';

//components
import { Box } from '@mui/material';
import CustomButton from '../../shared/CustomButton';

// icons
import AddIcon from '@mui/icons-material/Add';

export const ListFilter = ({
  createPage,
  source,
  title,
  handleCopy,
  ...props
}) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  let { pathname } = useLocation();
  const store = localStorageStore();
  const initialData = useContext(InitialDataContext);

  const handleClick = () => {
    store.setItem('redirectUrl', pathname);
    navigate(createPage);
  };

  const postFilters = [
    <SearchInput
      source={source}
      variant='outlined'
      alwaysOn
      resettable={true}
      sx={{ width: 350 }}
    />,
    <AutocompleteInput
      source='languageId'
      optionValue='id'
      variant='outlined'
      label={translate('common.language')}
      resettable='true'
      choices={initialData.languageList}
      sx={{ width: 250 }}
      alwaysOn
    />,
  ];

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '3rem',
      }}
    >
      <FilterForm filters={postFilters} {...props} variant='outlined' />
      {handleCopy && (
        <CustomButton
          variant='primary'
          title={translate('common.copy_from')}
          handleClick={handleCopy}
          sx={{ mr: 2 }}
        />
      )}
      <CustomButton
        variant='secondary'
        title={title}
        handleClick={handleClick}
        startIcon={<AddIcon />}
        sx={{ padding: 0 }}
      />
    </Box>
  );
};
