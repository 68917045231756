// library modules
import { useState, useRef, useEffect } from 'react';
import { useUpdate, useRefresh, useTranslate, ImageField } from 'react-admin';
import axios from '../../plugins/axios';

// components
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import FormDialog from '../shared/FormDialog';
import CustomButton from '../shared/CustomButton';
import UploadIcon from '../shared/UploadIcon';

export default function EditCategoryModal({
  record,
  modalOpen,
  setModalOpen,
  isFactory = false,
  refresh,
  languageId,
}) {
  const type = record.parentId === 0 ? 'category' : 'subcategory';
  const typeFirstUpper = type[0].toUpperCase() + type.slice(1);
  const [editCategoryName, setEditCategoryName] = useState(record.name);
  const [categoryFile, setCategoryFile] = useState();
  const [previewCategoryFile, setPreviewCategoryFile] = useState();
  const [update] = useUpdate();
  // const refresh = useRefresh();
  const translate = useTranslate();

  const hiddenCategoryFileInput = useRef(null);

  useEffect(() => {
    if (!categoryFile) {
      setPreviewCategoryFile(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(categoryFile);
    setPreviewCategoryFile(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [categoryFile]);

  const editCategory = async () => {
    let categoryImageResponse = '';

    if (categoryFile) {
      try {
        const imageFormData = new FormData();
        imageFormData.append('file', categoryFile);

        categoryImageResponse = await axios.post(
          '/category/upload-image',
          imageFormData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );
      } catch (err) {
        console.log(err);
      }
    }

    await update(
      'categories',
      {
        id: record.id,
        data: {
          ...record,
          name: editCategoryName,
          iconName: categoryImageResponse.data,
          isFactory: isFactory,
          languageId: languageId,
        },
        previousData: record,
      },
      {
        onSuccess: () => {
          refresh();
          setModalOpen(false);
        },
      }
    );
  };

  const handleProfileImageClick = (event) => {
    hiddenCategoryFileInput.current.click();
  };

  const handleUploadProfileImageChange = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setCategoryFile(undefined);
      return;
    }
    const fileUploaded = event.target.files[0];
    setCategoryFile(fileUploaded);
  };

  return (
    <FormDialog
      title={`Edit ${type}`}
      isOpen={modalOpen}
      handleClose={() => setModalOpen(false)}
      handleConfirm={() => editCategory()}
    >
      <Box sx={{ mb: '2rem', color: 'neutral.light' }}>
        Choose the name for the {type}
      </Box>
      <TextField
        value={editCategoryName}
        onChange={(e) => setEditCategoryName(e.target.value)}
        id={type}
        label={typeFirstUpper}
        sx={{ mt: '0.5rem', width: '100%' }}
      />
      <Typography variant='h6' sx={{ fontSize: '1rem', my: 3 }}>
        {translate('common.category_image')}
      </Typography>
      <div style={{ display: 'flex', marginBottom: 38 }}>
        <UploadIcon />
        <CustomButton
          title={translate('common.click_to_upload')}
          variant='ghost'
          style={{ marginLeft: 4, marginRight: 4 }}
          handleClick={handleProfileImageClick}
        />
        <input
          type='file'
          accept='image/*'
          ref={hiddenCategoryFileInput}
          onChange={handleUploadProfileImageChange}
          style={{ display: 'none' }}
        />

        {!categoryFile ? (
          <>
            {record?.iconName && (
              <img
                src={record?.iconName}
                alt='avatar'
                style={{ height: '6.3rem' }}
              />
            )}
          </>
        ) : (
          <img
            src={previewCategoryFile}
            alt='avatar'
            style={{ height: '6.3rem' }}
          />
        )}
      </div>
    </FormDialog>
  );
}
