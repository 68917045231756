import { Typography } from '@mui/material';
import { userManager } from '../../auth/authProvider';
import Box from '@mui/material/Box';
import { useTranslate } from 'react-admin';
import CustomButton from '../shared/CustomButton';

const Unauthorized = () => {
  const translate = useTranslate();

  function login() {
    userManager.signinRedirect();
    userManager.removeUser();
  }
  function logout() {
    userManager.removeUser();
    userManager.signoutRedirect();
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <Typography
          variant='h1'
          component='h1'
          sx={{ fontSize: '2rem', lineHeight: 1, mb: 2 }}
        >
          {translate('common.unauthorized')}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <CustomButton
            variant='secondary'
            title={translate('common.logout')}
            handleClick={logout}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Unauthorized;
