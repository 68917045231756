export function debounce(fn, wait) {
  let timeout;

  return function executedFunction(fn, wait) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(fn, wait);
  };
}
