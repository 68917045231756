// library modules
import { useEffect, useState, useContext } from 'react';
import {
  useTranslate,
  localStorageStore,
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  useRecordContext,
  DateField,
  useRefresh,
} from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../plugins/axios';
import { InitialDataContext } from '../../../context/initialDataContext';

// components
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from '../../shared/CustomButton';
import UsernameWithImage from '../../shared/UsernameWithImage';
import CategoryField from '../../shared/CategoryField';

// utils
import { removeFileFromArray } from '../../../utils/removeFileFromArray';

//css
import { theme } from '../../layout/themes';

const TemplateTestimonialsList = () => {
  // hooks
  const translate = useTranslate();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const store = localStorageStore();
  const { id } = useParams();
  const initialData = useContext(InitialDataContext);

  // state
  const [testimonials, setTestimonials] = useState();
  const [deletedIds, setDeletedIds] = useState([]);
  const [isEdit] = useState(store.getItem('template.isEdit') || false);

  const listContext = useList({ data: testimonials });

  async function fetchTestimonialsList() {
    const response = await axios('/selected-file-item/get-filtered-list', {
      method: 'POST',
      data: {
        maxResultCount: 1000,
        filterRule: {
          condition: 'and',
          filterRules: [
            {
              field: 'TemplateId',
              operator: 'equals',
              value: id.toString(),
            },
            {
              field: 'SectionId',
              operator: 'equals',
              value: '2',
            },
            {
              field: 'LanguageId',
              operator: 'equals',
              value: initialData.defaultLanguage,
            },
          ],
        },
      },
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  useEffect(() => {
    const canFetch = store.getItem('template.testimonials.canFetch');

    if (isEdit && canFetch === undefined) {
      fetchTestimonialsList().then((response) => {
        setTestimonials(response.data.items);
        store.setItem('template.testimonials', response.data.items);
        store.setItem('template.testimonials.canFetch', false);
      });
    } else {
      setTestimonials(store.getItem('template.testimonials'));
    }
  }, []);

  const DeleteButton = () => {
    const record = useRecordContext();

    return (
      <CustomButton
        variant='link'
        title={translate('buttons.delete')}
        handleClick={() => deleteItem(record.id)}
        sx={{ color: `${theme.palette.error.main}` }}
      />
    );
  };

  const deleteItem = (id) => {
    const storeDeletedIds = store.getItem('template.deletedIds') || [];

    const transformedList = removeFileFromArray(testimonials, id);
    store.setItem('template.testimonials', transformedList);

    const deletedItems = deletedIds;
    deletedItems.push(id);
    setDeletedIds(deletedItems);

    if (isEdit) store.setItem('template.deletedIds', [id, ...storeDeletedIds]);

    refresh();
  };

  return (
    <Box sx={{ mx: 3 }}>
      <CustomButton
        variant='link'
        title={translate('resources.content.add_media_item')}
        handleClick={() => navigate('/template/add-testimonials')}
        startIcon={<AddIcon />}
        sx={{ mb: 3, color: 'primary.main' }}
      />
      <ListContextProvider value={listContext}>
        <Datagrid bulkActionButtons={false}>
          <UsernameWithImage label={translate('common.table.name')} />
          <DateField
            source='dateAdded'
            locales='de-DE'
            label={translate('common.table.date_added')}
          />
          <CategoryField label={translate('common.table.category')} />
          <TextField source='publicOrPrivate' />
          <TextField source='mediaItemType' />
          <DeleteButton
            variant='link'
            title={translate('buttons.delete')}
            handleClick={() => deleteItem()}
            sx={{ color: `${theme.palette.error.main}` }}
          />
        </Datagrid>
      </ListContextProvider>
    </Box>
  );
};

export default TemplateTestimonialsList;
