// library modules
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';

// components
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import UrlField from '../shared/UrlField';

const CustomFilter = (props) => {
  return (
    <>
      <Filter {...props} variant='outlined'>
        <SearchInput
          source='mediaItemDeletedSearch'
          variant='outlined'
          alwaysOn
          resettable={true}
          sx={{ width: 350 }}
        />
      </Filter>
    </>
  );
};

export default function FileArchiveList() {
  const translate = useTranslate();

  return (
    <Box sx={{ p: '0' }}>
      <Typography variant='h5' sx={{ mb: '2.2rem' }}>
        {translate('resources.content.list_of_deleted_files')}
      </Typography>
      <List
        filters={<CustomFilter />}
        resource='media-item-deleted'
        actions={null}
        empty={false}
        sx={{
          '& .MuiToolbar-root': { marginBottom: '30px' },
          '& .MuiTablePagination-root': {
            position: 'absolute',
            bottom: '-100px',
            right: 0,
            mr: '1rem',
          },
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source='name' label={translate('common.table.name')} />
          <UrlField label={translate('common.table.url')} />
        </Datagrid>
      </List>
    </Box>
  );
}
