import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from './CustomButton';
import { useRef } from 'react';

export default function FormDialog({
  isOpen,
  title = '',
  handleClose,
  handleConfirm,
  closeButtonLabel = 'Discard',
  confirmButtonLabel = 'Save Changes',
  children,
  isDisabled = false,
  hideClose = false,
}) {
  const nodeRef = useRef(null);
  return (
    <Dialog
      open={isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      ref={nodeRef}
      onClose={handleClose}
      maxWidth={false}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle
        id='alert-dialog-title'
        sx={{ padding: 0, marginBottom: '2.5rem' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {title}
          {!hideClose && (
            <button
              style={{
                backgroundColor: 'transparent',
                padding: '0',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </DialogTitle>
      <DialogContent sx={{ padding: 0, marginBottom: '2.5rem' }}>
        {children}
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        {!hideClose && (
          <CustomButton
            title={closeButtonLabel}
            variant='primary'
            handleClick={handleClose}
          />
        )}
        <CustomButton
          title={confirmButtonLabel}
          variant='secondary'
          sx={{ ml: 4 }}
          handleClick={handleConfirm}
          disabled={isDisabled}
        />
      </DialogActions>
    </Dialog>
  );
}
