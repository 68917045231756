import {
  Datagrid,
  ListContextProvider,
  TextField,
  useList,
  useRecordContext,
  useTranslate,
} from 'react-admin';

export default function ExpandedItem() {
  const record = useRecordContext();
  const translate = useTranslate();
  const listContext = useList({ data: record.fileItems });

  if (!record) return null;

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        isRowSelectable={false}
        bulkActionButtons={false}
        sx={{ pl: 4 }}
      >
        <TextField source='name' label={translate('common.table.name')} />
        <TextField source='isPublic' label={translate('common.table.public')} />
        <TextField
          source='version'
          label={translate('common.table.file_version')}
        />
      </Datagrid>
    </ListContextProvider>
  );
}
